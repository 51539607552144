import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, getPersonalReferralDetailsById } from "../../../redux/actions/data.action";
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PERSONAL_REFERRAL_DETAILS, SET_PERSONAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from "../../../redux/actions/types";
import { Button, Input, Label } from "reactstrap";
import { examinationAppearanceLetterData } from "../../../Utils/commonData";
import { manipulateSentence } from "../../../Utils/helpers";






const ColposcopicFindings = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { patientDetails,  personalReferralDetails } = useSelector(({ data }) => data)
    const { examinationDetails, examination } = useSelector(({ examination }) => examination);
    const { viewOption, edited } = useSelector(({ ui }) => ui)




    let handleReferralDetails = (id) => {
        dispatch(getPersonalReferralDetailsById(id))
            .then((res) => {
                let { tbl_PersonalReferralReason, tbl_PersonalAssociatedSymptom, tbl_personalrelevantdetail } = res.data;
                dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: res.data })
                dispatch({ type: SET_REFERRAL_REASONS, payload: tbl_PersonalReferralReason })
                dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: tbl_PersonalAssociatedSymptom })
                dispatch({ type: SET_PERSONAL_RELEVANT_DETAILS, payload: tbl_personalrelevantdetail })

            })
            .catch(err => {
                console.log(err)
            })
    }



    const handleGetExamination = (id) => {
        dispatch(getCervixExaminationDetailsById(id))
            .then(res => {
                console.log(res, 'examination details')
                dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examinationDetails } })
            })
    }




    const handleSubmit = (e) => {
        e.preventDefault()

        if (examinationDetails && !examinationDetails.TZType) {
            return;
        }

        /*   props.lastStep();
          props.completeCallback();
          Swal.fire(
            'Good job!',
            'You clicked the button!',
            'success'
          ) */
        if (examinationDetails.Pregnant) {
            navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/Pregnant' : '/dashboard/viewPatient/examination/Pregnant'}`)
        } else {
            navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/details' : '/dashboard/viewPatient/examination/details'}`)
        }
    };

    const setExamDetails = (prop) => {
        if (viewOption === 'review') {
            return console.log('REVIEW MODE')
        }



        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};

        dispatch({ type: SET_EXAMINATION_DETAILS, payload: prop })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: prop }))
        dispatch({ type: SET_EDITED, payload: 'examination' })
    }


    const handleChanges = prop => event => {
        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};

        if (viewOption === 'review') {
            return console.log('REVIEW MODE')
        }







        let targetValue = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value

        let newVal = { ...examinationDetails, [prop]: targetValue }

        let withSummary = examinationAppearanceLetterData.find(a => a.key === prop);
        if (withSummary) {
            let oldDetailsletterSummary = examinationDetails.DetailsLetterSummary;


            let DetailsLetterSummary = manipulateSentence(oldDetailsletterSummary, withSummary.value, targetValue ? false : true)





            newVal = { ...newVal, DetailsLetterSummary }


        }






        dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
        dispatch({ type: SET_EDITED, payload: 'examination' })

    }


    const handleBack = () => {
        navigate(-1)
    };



    const handleExit = (id) => {
        if (edited.find(a => a === 'examination')) {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
            // return

        } else {
            dispatch({ type: CLEAR_EXAMINATION })
            dispatch({ type: CLEAR_EXAMINATION_DETAILS })
        }


        navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }







    useEffect(() => {
        if (patientDetails && (patientDetails.NHSnumber || patientDetails.HospitalNumber)) {
            //   setPatient(patientDetails)
        /*     if (personalReferralDetails && personalReferralDetails.ReferralID) {
                handleReferralDetails(personalReferralDetails.ReferralID)
            }
 */

            if (examination && examination.ExaminationID) {
                handleGetExamination(examination.ExaminationID)
            }
        } else {
            navigate('/dashboard/home')
        }
    }, [patientDetails, examination])




    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Examination Details</h4>
            </div>
            <div className="card-body">
                <div>
                    <Form noValidate
                        onSubmit={handleSubmit} onReset={handleExit}>
                        <div className="bordered pl-2 pr-2">
                            <h3 className="card-title text-center bordered-title">Colposcopic Findings</h3>
                            <div className="row pl-2 pr-2">
                                <div className='col-md-6'>
                                    <div className="bordered pl-2 pr-2">
                                        <h3 className="card-title txtLeft bordered-title">Squamo-Columnar Junction</h3>
                                        <div className="bordered pr-2 pl-2">
                                            <h3 className="card-title txtLeft bordered-title text-15 ml-3">Visible</h3>
                                            <div className="d-flex pb-3">
                                                <div className="col-md-4 text-left">
                                                    <div className='pl-3'>
                                                        <input
                                                            type="radio" id="Ectocervix"
                                                            value="Ectocervix" checked={examinationDetails.SquamoColumnarJunctionVisible === 'Ectocervix'} name="squamocolumnarjunction_radio" className="" onChange={(e) => {
                                                                setExamDetails({ ...examinationDetails, SquamoColumnarJunctionVisible: 'Ectocervix', TZType: "1" })
                                                            }}

                                                        // value={examinationDetails.VulvaAbnormal}
                                                        // id="VulvaAbnormal"
                                                        // checked={examinationDetails.VulvaAbnormal}
                                                        // onClick={(e) => {
                                                        //  setExamDetails({...examinationDetails, VulvaAbnormal:  e.target.value === 'true' ? false : true})  
                                                        // }}
                                                        />
                                                        <label className="c text-blue" htmlFor="Ectocervix" >Ectocervix</label>
                                                        <div className='clearfix'></div><br />
                                                    </div>
                                                    <div className='pl-3'>
                                                        <input
                                                            type="radio" id="Canal"
                                                            value="Canal" checked={examinationDetails.SquamoColumnarJunctionVisible === 'Canal'} name="squamocolumnarjunction_radio" className=""
                                                            onChange={(e) => {
                                                                setExamDetails({ ...examinationDetails, SquamoColumnarJunctionVisible: 'Canal', TZType: "2" })
                                                            }}
                                                        />
                                                        <label className="c text-blue" htmlFor="Canal" >Canal</label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                </div>
                                                <div className='bordered col-md-8 mt-3'>
                                                    <h3 className="card-title bordered-title text-15 ml-3" htmlFor="VulvaAbnormal" >Tz Type</h3>
                                                    <div style={{ alignItems: 'space-around', justifyContent: 'space-around', display: 'flex', flexDirection: 'row' }}>
                                                        <div className=''>
                                                            <input
                                                                type="radio" id="Ectocervix1"
                                                                value="1" checked={examinationDetails.TZType === '1'} name="tztype_radio" className="" onChange={(e) => {
                                                                    setExamDetails({ ...examinationDetails, SquamoColumnarJunctionVisible: 'Ectocervix', TZType: "1" })
                                                                }}
                                                            />
                                                            <label className="c text-blue" htmlFor="Ectocervix1" >1</label>
                                                        </div>
                                                        <div className=''>
                                                            <input
                                                                type="radio" id="Canal2"
                                                                value="2" checked={examinationDetails.TZType === '2'} name="tztype_radio" className="" onChange={(e) => {
                                                                    setExamDetails({ ...examinationDetails, SquamoColumnarJunctionVisible: 'Canal', TZType: "2" })
                                                                }}
                                                            />
                                                            <label className="c text-blue" htmlFor="Canal2">2</label>
                                                        </div>
                                                        <div className=''>
                                                            <input
                                                                type="radio" id="NotVisible3"
                                                                value="3" checked={examinationDetails.TZType === '3'} name="tztype_radio" className="" onChange={(e) => {
                                                                    setExamDetails({ ...examinationDetails, SquamoColumnarJunctionVisible: 'Not Visible', TZType: "3" })
                                                                }}
                                                            />
                                                            <label className="c text-blue" htmlFor="NotVisible3" >3</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-left pl-3 mt-2 mb-2">
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="NotVisible"
                                                    value="Not Visible" checked={examinationDetails.SquamoColumnarJunctionVisible === 'Not Visible'} name="squamocolumnarjunction_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, SquamoColumnarJunctionVisible: 'Not Visible', TZType: "3" })
                                                    }}
                                                />
                                                <label className="c text-blue" htmlFor="NotVisible" >Not Visible</label>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <br />

                                </div>


                                <div className='col-md-6 txtLeft'>
                                    <div className="bordered pl-2 pr-2">
                                        <h3 className="card-title bordered-title">Extent Of Lesion</h3>
                                        <div className="bordered">
                                            <h3 className="card-title ml-3 bordered-title text-15">Extent</h3>
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="Endocervix"
                                                    value="Endocervix" checked={examinationDetails.ExtentOfLesion === 'Not Visible'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, ExtentOfLesion: 'Not Visible' })
                                                    }}
                                                />
                                                <label className="c text-blue" htmlFor="Endocervix" >Endocervix</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='pl-3 center'>
                                                <input
                                                    type="radio" id="intoupper"
                                                    value="Into endocervical canal (upper limit seen)" checked={examinationDetails.ExtentOfLesion === 'Into endocervical canal (upper limit seen)'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, ExtentOfLesion: 'Into endocervical canal (upper limit seen)' })
                                                    }}
                                                />
                                                <label className="w-75 pt-2 text-blue" htmlFor="intoupper">Into endocervical canal (upper limit seen)</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="intonotseen"
                                                    value="Into endocervical canal (upper limit not seen)" checked={examinationDetails.ExtentOfLesion === 'Into endocervical canal (upper limit not seen)'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, ExtentOfLesion: 'Into endocervical canal (upper limit not seen)' })
                                                    }}
                                                />
                                                <label className="w-75 pt-2 text-blue" htmlFor="intonotseen" >Into endocervical canal (upper limit not seen)</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="ontovagina"
                                                    value="Onto vagina" checked={examinationDetails.ExtentOfLesion === 'Onto vagina'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, ExtentOfLesion: 'Onto vagina' })
                                                    }}
                                                />
                                                <label className="w-75 pt-2 text-blue" htmlFor="ontovagina" >Onto vagina</label>
                                                <div className='clearfix'></div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 pl-3 mt-3 mb-2'>
                                            <input
                                                type="radio" id="nolesion"
                                                value="No Lesion" checked={examinationDetails.ExtentOfLesion === 'No Lesion'} name="extentoflesion_radio" className=""
                                                onChange={(e) => {
                                                    setExamDetails({ ...examinationDetails, ExtentOfLesion: 'No Lesion' })
                                                }}
                                            />
                                            <label className="c text-blue" htmlFor="nolesion" >No Lesion</label>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                        <div className="bordered pl-2 pr-2">
                            <h3 className="card-title text-center bordered-title">Appearance</h3>
                            <div className="d-flex">
                                <div className='col-md-8 txtLeft'>
                                    <div className="bordered pl-2 pr-2">
                                        <h3 className="card-title bordered-title text-14">Aceto White</h3>
                                        <div className='row pl-2 pr-2'>
                                            <div className='col-md-3'>
                                                <input
                                                    type="radio" id="acetowhitenone"
                                                    checked={(!examinationDetails.AcetoWhite) && (!examinationDetails.AcetoWhiteModerate && !examinationDetails.AcetoWhiteSevere)} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.DetailsLetterSummary;
                                                        let DetailsLetterSummary = manipulateSentence(olddetailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhite').value, true)
                                                        DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteSevere').value, true)
                                                        DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteModerate').value, true)

                                                        setExamDetails({ ...examinationDetails, AcetoWhite: false, AcetoWhiteModerate: false, AcetoWhiteSevere: false, DetailsLetterSummary })
                                                    }}
                                                />
                                                <label className="c text-blue" htmlFor="acetowhitenone" >None</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <input
                                                    type="radio" id="acetowhitemild"
                                                    value={examinationDetails.AcetoWhite} checked={examinationDetails.AcetoWhite} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.DetailsLetterSummary;
                                                        let withSummary = examinationAppearanceLetterData.find(a => a.key === 'AcetoWhite');
                                                        let isTrue = e.target.value == 'true' ? false : true;
                                                        let DetailsLetterSummary = manipulateSentence(olddetailslettersummary, withSummary.value, isTrue ? false : true)

                                                        if (isTrue) {
                                                            DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteSevere').value, true)
                                                            DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteModerate').value, true)
                                                            setExamDetails({ ...examinationDetails, AcetoWhite: isTrue, AcetoWhiteModerate: false, AcetoWhiteSevere: false, DetailsLetterSummary })
                                                        } else {
                                                            setExamDetails({ ...examinationDetails, AcetoWhite: isTrue, DetailsLetterSummary })
                                                        }

                                                    }}
                                                />
                                                <label className="c text-blue" htmlFor="acetowhitemild">Mild</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <input
                                                    type="radio" id="AcetoWhiteModerate"
                                                    value={examinationDetails.AcetoWhiteModerate} checked={(!examinationDetails.AcetoWhite && examinationDetails.AcetoWhiteModerate)} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.DetailsLetterSummary;
                                                        let withSummary = examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteModerate');
                                                        let isTrue = e.target.value == 'true' ? false : true;
                                                        let DetailsLetterSummary = manipulateSentence(olddetailslettersummary, withSummary.value, isTrue ? false : true)



                                                        if (isTrue) {
                                                            DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteSevere').value, true)
                                                            DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhite').value, true)
                                                            setExamDetails({ ...examinationDetails, AcetoWhite: false, AcetoWhiteModerate: isTrue, AcetoWhiteSevere: false, DetailsLetterSummary })
                                                        } else {
                                                            setExamDetails({ ...examinationDetails, AcetoWhiteModerate: isTrue, DetailsLetterSummary })
                                                        }

                                                    }}
                                                />
                                                <label className="c text-blue" htmlFor="AcetoWhiteModerate" >Moderate</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <input
                                                    type="radio" id="AcetoWhiteSevere"
                                                    value={examinationDetails.AcetoWhiteSevere} checked={(!examinationDetails.AcetoWhite && examinationDetails.AcetoWhiteSevere)} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.DetailsLetterSummary;
                                                        let withSummary = examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteSevere');
                                                        let isTrue = e.target.value == 'true' ? false : true;
                                                        let DetailsLetterSummary = manipulateSentence(olddetailslettersummary, withSummary.value, isTrue ? false : true)

                                                        if (isTrue) {
                                                            DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhiteModerate').value, true)
                                                            DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'AcetoWhite').value, true)

                                                            setExamDetails({ ...examinationDetails, AcetoWhite: false, AcetoWhiteModerate: false, AcetoWhiteSevere: isTrue, DetailsLetterSummary })
                                                        } else {
                                                            setExamDetails({ ...examinationDetails, AcetoWhiteSevere: isTrue, DetailsLetterSummary })
                                                        }



                                                    }}

                                                />
                                                <label className="c text-blue" htmlFor="AcetoWhiteSevere" >Severe</label>
                                                <div className='clearfix'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row mt-3 pl-3'>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="EndocervicalCanalUpperLimitSeen" className=""
                                                id="EndocervicalCanalUpperLimitSeen"
                                                value={examinationDetails.EndocervicalCanalUpperLimitSeen ? false : true}
                                                checked={examinationDetails.EndocervicalCanalUpperLimitSeen}
                                                onChange={handleChanges('EndocervicalCanalUpperLimitSeen')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="EndocervicalCanalUpperLimitSeen" >Upper Limit Seen</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="StenosedOs" className=""
                                                id="StenosedOs"
                                                value={examinationDetails.StenosedOs ? false : true}
                                                checked={examinationDetails.StenosedOs}
                                                onChange={handleChanges('StenosedOs')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="StenosedOs" >Stenosed os</Label>
                                            <div className='clearfix'></div>
                                        </div>

                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="WartyFeatures" className=""
                                                id="WartyFeatures"
                                                value={examinationDetails.WartyFeatures ? false : true}
                                                checked={examinationDetails.WartyFeatures}
                                                onChange={handleChanges('WartyFeatures')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="WartyFeatures" >Warty Features</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="SquamoMetaplasia" className=""
                                                id="SquamoMetaplasia"
                                                value={examinationDetails.SquamoMetaplasia ? false : true}
                                                checked={examinationDetails.SquamoMetaplasia}
                                                onChange={handleChanges('SquamoMetaplasia')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="SquamoMetaplasia" >Squamo metaplasia</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="SuspectInvasion" className=""
                                                id="SuspectInvasion"
                                                value={examinationDetails.SuspectInvasion ? false : true}
                                                checked={examinationDetails.SuspectInvasion}
                                                onChange={handleChanges('SuspectInvasion')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="SuspectInvasion" >Suspect Invasion</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="Atrophy" className=""
                                                id="Atrophy"
                                                value={examinationDetails.Atrophy ? false : true}
                                                checked={examinationDetails.Atrophy}
                                                onChange={handleChanges('Atrophy')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="Atrophy" >Atrophy</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="iodinenegative" className=""
                                                id="iodinenegative"
                                                value={examinationDetails.iodinenegative ? false : true}
                                                checked={examinationDetails.iodinenegative}
                                                onChange={handleChanges('iodinenegative')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="iodinenegative" >Iodine negative</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="CondylomaWart" className=""
                                                id="CondylomaWart"
                                                value={examinationDetails.CondylomaWart ? false : true}
                                                checked={examinationDetails.CondylomaWart}
                                                onChange={handleChanges('CondylomaWart')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="CondylomaWart" >Condyloma/Wart</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                            <div className="row col-md-6 " >
                                                <div className='col-md-6 pl-3'>
                                                    <Input
                                                        type="checkbox" name="Ectopy" className=""
                                                        id="Ectopy"
                                                        value={examinationDetails.Ectopy ? false : true}
                                                        checked={examinationDetails.Ectopy}
                                                        onChange={handleChanges('Ectopy')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="Ectopy" >Ectopy</Label>
                                                    <div className='clearfix'></div>

                                                </div>
                                                <div className='col-md-6 pl-4'>
                                                    <Input
                                                        type="checkbox" name="Polyp" className=""
                                                        id="Polyp"
                                                        value={examinationDetails.Polyp ? false : true}
                                                        checked={examinationDetails.Polyp}
                                                        onChange={handleChanges('Polyp')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="Polyp" >Polyp</Label>
                                                    <div className='clearfix'></div>

                                                </div>
                                            </div>
                                            <div className="row col-md-6">
                                                <div className='col-md-6 pl-4'>
                                                    <Input
                                                        type="checkbox" name="Cervicitis" className=""
                                                        id="Cervicitis"
                                                        value={examinationDetails.Cervicitis ? false : true}
                                                        checked={examinationDetails.Cervicitis}
                                                        onChange={handleChanges('Cervicitis')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="Cervicitis" >Cervicitis</Label>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='col-md-6 pl-4'>
                                                    <Input
                                                        type="checkbox" name="HPV" className=""
                                                        id="HPV"
                                                        value={examinationDetails.HPV ? false : true}
                                                        checked={examinationDetails.HPV}
                                                        onChange={handleChanges('HPV')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="HPV" >HPV</Label>
                                                    <div className='clearfix'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                </div>
                                <div className='col-md-4 txtLeft pl-2 pr-2'>
                                    <div className="bordered">
                                        <h3 className="card-title bordered-title">Vessels</h3>
                                        <div className="pl-2 pr-2">
                                            <div className="bordered pl-2 pr-2">
                                                <h3 className="card-title bordered-title ml-3 text-14">Mosaicism</h3>
                                                <div className="row pl-2">

                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="MosaicCourse" className=""
                                                            id="MosaicCourse"
                                                            value={examinationDetails.MosaicCourse ? false : true}
                                                            checked={examinationDetails.MosaicCourse}
                                                            onChange={(e) => {
                                                                let DetailsLetterSummary = examinationDetails.DetailsLetterSummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'MosaicCourse');


                                                                if (e.target.value == 'true') {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, false)
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'Mosaic').value, true)

                                                                    setExamDetails({ ...examinationDetails, MosaicCourse: true, Mosaic: false, DetailsLetterSummary })
                                                                } else {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, MosaicCourse: false, DetailsLetterSummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="MosaicCourse" >Coarse</Label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="Mosaic" className=""
                                                            id="Mosaic"
                                                            value={examinationDetails.Mosaic ? false : true}
                                                            checked={examinationDetails.Mosaic}
                                                            onChange={(e) => {
                                                                let DetailsLetterSummary = examinationDetails.DetailsLetterSummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'Mosaic');

                                                                if (e.target.value == 'true') {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, false)
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'MosaicCourse').value, true)

                                                                    setExamDetails({ ...examinationDetails, MosaicCourse: false, Mosaic: true, DetailsLetterSummary })
                                                                } else {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, Mosaic: false, DetailsLetterSummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="Mosaic" >Fine</Label>
                                                        <div className='clearfix'></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <div className="bordered pl-2 pr-2">
                                                <h3 className="card-title bordered-title ml-3 text-14">Punctation</h3>
                                                <div className="row pl-2">

                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="PunctationCourse" className=""
                                                            id="PunctationCourse"
                                                            value={examinationDetails.PunctationCourse ? false : true}
                                                            checked={examinationDetails.PunctationCourse}
                                                            onChange={(e) => {
                                                                let DetailsLetterSummary = examinationDetails.DetailsLetterSummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'PunctationCourse');
                                                                if (e.target.value == 'true') {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, false)
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'Punctation').value, true)

                                                                    setExamDetails({ ...examinationDetails, PunctationCourse: true, Punctation: false, DetailsLetterSummary })
                                                                } else {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, PunctationCourse: false, DetailsLetterSummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="PunctationCourse" >Coarse</Label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="Punctation" className=""
                                                            id="Punctation"
                                                            value={examinationDetails.Punctation ? false : true}
                                                            checked={examinationDetails.Punctation}
                                                            onChange={(e) => {
                                                                let DetailsLetterSummary = examinationDetails.DetailsLetterSummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'Punctation');

                                                                if (e.target.value == 'true') {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, false)
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, examinationAppearanceLetterData.find(a => a.key === 'PunctationCourse').value, true)
                                                                    setExamDetails({ ...examinationDetails, PunctationCourse: false, Punctation: true, DetailsLetterSummary })
                                                                } else {
                                                                    DetailsLetterSummary = manipulateSentence(DetailsLetterSummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, Punctation: false, DetailsLetterSummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="Punctation" >Fine</Label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-10 pl-5 ml-2 mt-3 mb-3'>
                                                <Input
                                                    type="checkbox" name="GrossAbnormality" className=""
                                                    id="GrossAbnormality"
                                                    value={examinationDetails.GrossAbnormality ? false : true}
                                                    checked={examinationDetails.GrossAbnormality}
                                                    onChange={handleChanges('GrossAbnormality')}

                                                />
                                                {' '}
                                                <Label className="" htmlFor="GrossAbnormality" >Gross abnormality</Label>
                                                <div className='clearfix'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <br />
                        <div className="bordered">
                            <h3 className="bordered-title card-title text-center">Details - Summary for letters</h3>
                            <div className='pl-3 pb-3 pr-3'>
                                {/* <label className="control-label">Specify Abnormality</label> */}
                                <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                                    value={examinationDetails.DetailsLetterSummary}
                                // onChange={handleChanges('SpecifyAbnormality')}
                                />
                            </div>
                        </div>
                        <br /> <br /> <br />

                        <div className="text-xs-center fR">
                            <Button className="btn btnIri marRight20" onClick={handleBack}>Previous</Button>
                            <Button type="submit" className="btn btnIri marRight20">Next</Button>
                            <Button type="reset" className="btn btnIri marRight20">Exit</Button>

                        </div>
                    </Form>
                </div>
            </div>
            <div className='clearfix'></div>
        </>
    )
}

export default ColposcopicFindings;
