import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import StatisticsTable from '../StatisticsTable';
import { useDispatch, useSelector } from 'react-redux';
import { generateLetterTemplate, getQarcStats, searchPatientDetails } from 'redux/actions/data.action';
import { Grid } from '@mui/material';
import { CLOSE_MODAL, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS, SET_SELECTED_ROW } from 'redux/actions/types';
import { utils, writeFile } from 'xlsx';
import moment from 'moment';  



function createData(code, name, target, met, total, ratio) {
    return { code, name, target, met, total, ratio };
  }
  
const defaultRows = [
    createData('Cx_RE15', 'New Abnormal Patients Seen By Each Colposcopist', '>=50', '0', '0', '0.00%'),
    createData('Cx_DI1', 'Biopsy Taken Moderate And Severe Referrals', '>=95%', '0', '0', '0.00%'),
    createData('Cx_DI14', 'Adequate Biopies Taken At Colposcopy', '>=90%', '0', '0', '0.00%'),
    createData('Cx_TR4', 'Women treated at colposcopy whose specimens were removed as single loop', '>=80%', '0', '0', '0.00%'),
    createData('Cx_TR1', 'A biopsy result available to colposcopy before destructive therapy', '>=100%', '0', '0', '0.00%'),
    createData('Cx_TR2', 'No Dyskaryosis 8 months after Treatment', '>=90%', '0', '0', '0.00%'),
    createData('Cx_TR3', 'CIN or Cancer 12 months after Treatment', '<=5%', '0', '0', '0.00%'),
    createData('Cx_TR5', 'Women treated at colposcopy with depth of excision > 7mm', '>=95%', '0', '0', '0.00%'),
    createData('Cx_TR6', 'Treatments undertaken at colposcopy with local anaesthesia', '>=80%', '0', '0', '0.00%'),
    createData('Cx_TR8', 'See & Treat High Grade Histology', '>=90%', '0', '0', '0.00%'),
    createData('Cx_TR9', 'Low grade referrals - See & Treat high grade histology', '>=80%', '0', '0', '0.00%'),
    createData('Cx_TR17', 'Treatment within 4 weeks of colposcopy receiving a diagnostic biopsy report', '>=90%', '0', '0', '0.00%'),
    
    // createData('Cx_TR17a', 'Treatment within 4 weeks of colposcopy receiving a diagnostic biopsy report', '>=90%', '0', '0', '0.00%'),
    // createData('Cx_TR17b', 'Treatment within 8 weeks of colposcopy receiving a diagnostic biopsy report', '>=100%', '0', '0', '0.00%'),
    // createData('Cx_DI15a', 'PPV colp opinion against high grade lesion or worse - High grade referrals', '>=75%', '0', '0', '0.00%'),
    // createData('Cx_DI15b', 'PPV colp opinion against high grade lesion or worse - Other referrals', '>=35%', '0', '0', '0.00%')
    createData('Cx_DI15', 'PPV Colp opinion against high grade lesion or worse', '>=65%', '0', '0', '0.00%'),
  
  ]


export default function QarcGridView() {
const dispatch = useDispatch();
const {user } = useSelector(({auth}) => auth)
const { selectedRow } = useSelector(({ui}) => ui)
const navigate = useNavigate();
const [rows, setRows] = useState([]);
const [headers, setHeaders] = useState([]);
const [headerTitle, setHeaderTitle] = useState('');
const [qarcData, setQarcData] = useState([]);
const [searchParams, setSearchParams] = useSearchParams();
const [loading, setLoading] = useState(false);
const params = useParams();
const title = searchParams.get('type');

const code = params.code
console.log(code, title, 'CODEs')
const handleExit = () => {
    localStorage.removeItem("formDetails")
    navigate('/dashboard/statistics/qarc')
}

const handleClick = (prop) => (event) => {
    let selected = rows[event]
    if(prop === 'click'){
        // setSelectedRow(event);
        dispatch({type: SET_SELECTED_ROW, payload: event == selectedRow ? null : event})
    } else {
        dispatch({type: SET_SELECTED_ROW, payload: event == selectedRow ? null : event})
        handleSubmit({HospitalNumber: selected['Hospital Number']})
    }
}

const handleReview = (val) => {
        let selected = rows[selectedRow]
        handleSubmit({HospitalNumber: selected['Hospital Number']})
}

const handlePrint = async () => {
    // setUrlContent(null);
    setLoading(true)
  
    let newHeaders = [];
    let newDatas = [];
    
    rows.forEach(row => {
        let rowData = [];
        headers.forEach(item => {
            rowData.push(row[item.field])
        });
        newDatas.push(rowData);
    })
      
   
  
    headers.forEach(item => {
        newHeaders.push(item.title)
    })
   
    let tableObject = {
        border: {
            type: "all",
            size: 5,
            color: "FFFFFF"
          },
          style: {
                  header: {
                      shade: "FFFFFF"
                  }
            },
        headers: newHeaders,
        data: newDatas,
        chunkSize: {
            type: "dynamic",
            size: {
              min: 9000,
              max: 9100
            }
          }
    }

   
   
   
    let response = await dispatch(generateLetterTemplate({
    letterTemplate: "Qarc-load-print.docx",
      extend: "pdf",
      data: {
       "table1": tableObject
      }
    }))
    .then((res) => {
      setLoading(false)
      return res
    })
      let { fileUrl} = response.data;
    
    window.open(fileUrl, '_blank')
  
  
  }
  


const handleQarcSave = useCallback(() => {
    /* generate worksheet from state */
    let newRows = rows.map(row => {
        let newObj = {};
        
        headers.forEach(item => {
            newObj[item.title] = String(item.title).toLowerCase().includes('date') ? moment(row[item.field]).format('DD/MM/YYYY')  : row[item.field];
        })
        return newObj;
    })
      
    // var ws =  utils.aoa_to_sheet([[]]);
  
  
    const ws = utils.json_to_sheet(newRows, { skipHeader: false, origin: "A2"});
   utils.sheet_add_aoa(ws, [["KC65-A Statistics"]],  {origin: "A1"});
      
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFile(wb, `Qarc_Load_Save_${moment().format('MMDDYYYY')}.xlsx`);
  }, [rows]);


const handleSubmit = (vals) => {
    dispatch(searchPatientDetails(vals))
        .then(res => {
            if (res && res.length !== 0) {
         
                if (res.length === 1) {
                    //Check if inuseby
                    dispatch({ type: SET_PATIENT_DETAILS, payload: res[0] })
                    if (res[0].tbl_PersonalReferralDetails) {
                        dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: res[0].tbl_PersonalReferralDetails[res[0].tbl_PersonalReferralDetails.length - 1] })
                    }
                    navigate('/dashboard/viewPatient')
                } 
                dispatch({ type: CLOSE_MODAL});
                // dispatch({type: SET_SEARCH_STRING, payload: searchStr})
            }
        })
  }
  


const handleQarcData = async (val) => {
    const colposcopist = searchParams.get('colposcopist')
    const startDate = searchParams.get('start')
    const endDate = searchParams.get('end')
    const iSiteID = searchParams.get('site');
     let qarcData = await dispatch(getQarcStats({colposcopist, dtStartDate: startDate, dtEndDate: endDate, iSiteID: iSiteID }));
     let titleHead = defaultRows.find(a => a.code === val).name;
     let tableData = qarcData.find(a => a.code === val);
    setHeaderTitle(titleHead)     
     if(tableData && tableData.tables[0]){
        let rowHeaders = [];
        let rowsData = [];
        if(tableData.tables[0].length !== 0){
            
            Object.entries(tableData.tables[0][0]).forEach(([key, value]) => {
                rowHeaders.push({
                    title: key,
                    align: "left",
                    style: {width: '100px'},
                    field: key
                  })
            })
            
            rowsData = tableData.tables[0]
        } else if(tableData.tables[1].length !== 0){
            Object.entries(tableData.tables[1][0]).forEach(([key, value]) => {
                rowHeaders.push({
                    title: key,
                    align: "left",
                    style: {width: '100px'},
                    field: key
                  })
            })
            
            rowsData = tableData.tables[1]
        
        } 
        
        setHeaders(rowHeaders);

        
        setRows(rowsData);
        
     
     }
    
  }
  

useEffect(() => {
//Set Headers
    
handleQarcData(code);

}, [code])


  return (  
  <>
    <div className="card-header bg-iri w-100">
    <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
        }}
    >
        <h4 className="text-white">{headerTitle}-({rows.length})-{title}</h4>
    </div>
</div>
<div className="card-body"
    style={{ backgroundColor: '#E0E0E0', display: 'flex', flexDirection: 'column' }}
>
<StatisticsTable rows={rows} headers={headers} selectedRow={selectedRow} onClick={handleClick('click')} onDblClick={handleClick('dblClick')}/>  
<br/>
<Grid container spacing={3}
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
>
    <Grid item lg={6} xs={12}>
    <Grid 
    container
    direction="row"
    justifyContent="flex-end"
    alignItems="center"
    spacing={2}
    
    >
    <Grid item lg={6} xs={4} 
        style={{textAlign: 'right'}}
    >
    </Grid>
    <Grid item lg={6} xs={8}>
    </Grid>
    </Grid>
    <br/>
    <Grid 
    container
    direction="row"
    justifyContent="flex-end"
    alignItems="center"
    spacing={2}
    
    >
    <Grid item lg={6} xs={4} 
        style={{textAlign: 'right'}}
    >

    </Grid>
    <Grid item lg={6} xs={8}>


    
    </Grid>
    </Grid>
    
    </Grid>
    <Grid item lg={6} xs={12}
    >
    <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="space-between"
        // spacing={2}
    >

    <br/>
    <Grid container
    direction="row"
    justifyContent="flex-end"
    alignItems="flex-end"
    >
        <Grid item lg={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        >
        <button className="btn btnIri w-20 ml-1"
        disabled={selectedRow == null || !(selectedRow >= 0)}
        onClick={() => handleReview(selectedRow)}
        >Review</button>
        </Grid>
        <Grid item lg={2}>
        <button className="btn btnIri w-20 ml-1"
        onClick={() => handleQarcSave()}
        >Save</button>
        </Grid>
        <Grid item lg={2}>
        <button className="btn btnIri w-20 ml-1"
        onClick={() => handlePrint()}
        >Print</button>
        </Grid>
        <Grid item lg={2}>
        <button className="btn btnIri w-20 ml-1"
        onClick={() => handleExit()}
        >Exit</button>
    </Grid>
    
  
  </Grid>
  </Grid> 
    </Grid>
</Grid>
</div>
</>
  )
}
