import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import OptionsFrameHOC from '../../component/Forms/OptionsFrameHOC';

const Appointments = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const navigate = useNavigate();

    const handleLetters = () => {
        alert('Letters Clicked');
    };

    const handleList = () => {
        alert('Lists Clicked');
    };

    const handleExit = () => {
        navigate('/dashboard/home');
    };

    const today = new Date();
    const months = [];

    for (let i = 0; i < 6; i++) {
        const newDate = new Date(today.getFullYear(), today.getMonth() + i, 1);
        months.push(newDate);
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const dummyAppointments = [
        { id: 1, date: new Date(2024, 4, 14), patientName: 'John Doe', colposcopist: 'Dr. Smith', managementPlan: 'Treatment', hospitalNumber: 'HN35' },
        { id: 2, date: new Date(2024, 4, 14), patientName: 'Jane Smith', colposcopist: 'Dr. Johnson', managementPlan: 'Treatment', hospitalNumber: 'HN35' },
        { id: 3, date: new Date(2024, 4, 14), patientName: 'Alice Brown', colposcopist: 'Dr. Smith', managementPlan: 'Treatment', hospitalNumber: 'HN35' },
        // Add more dummy appointments as needed
    ];

    const filterAppointments = (date) => {
        // Filter appointments for the selected date
        const appointmentsForSelectedDate = dummyAppointments.filter(
            (appointment) =>
                appointment.date.getDate() === date.getDate() &&
                appointment.date.getMonth() === date.getMonth() &&
                appointment.date.getFullYear() === date.getFullYear()
        );
        setFilteredAppointments(appointmentsForSelectedDate);
    };

    const getTileClassName = ({ date, view }) => {
        if (view === 'month') {
            const isAppointmentDate = dummyAppointments.some(
                (appointment) =>
                    appointment.date.getDate() === date.getDate() &&
                    appointment.date.getMonth() === date.getMonth() &&
                    appointment.date.getFullYear() === date.getFullYear()
            );
            return isAppointmentDate ? 'highlight' : null;
        }
    };

    useEffect(() => {
        filterAppointments(selectedDate);
    }, [selectedDate]);

    return (
        <OptionsFrameHOC title="Appointment Lists" titleSize="lg">
            <div className="calendar-grid">
                {months.map((month, index) => (
                    <div key={index} className="calendar-container">
                        <Calendar 
                            value={month} 
                            onClickDay={handleDateChange} 
                            tileClassName={getTileClassName} 
                        />
                    </div>
                ))}
                <span>Today: {" "}{formattedDate}</span>
            </div>
            <div className="calendar-lower" style={{ justifyContent: 'start' }}>
                {filteredAppointments.length > 0 ? (
                    filteredAppointments.map((appointment) => (
                        <div key={appointment.id}>
                            {`${appointment.patientName} - ${appointment.colposcopist} - ${appointment.date.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })} - ${appointment.managementPlan} (${appointment.hospitalNumber})`}
                        </div>
                    ))
                ) : (
                    <div>No appointments for the selected date.</div>
                )}
            </div>
            <div className="text-xs-center mt-3 fR mb-3">
                <Button className="btn btnIri" onClick={handleLetters}>Letters</Button>
                <Button className="btn btnIri marRight20" onClick={handleList}>List</Button>
                <Button type="submit" className="btn btnIri marRight20" onClick={handleExit}>Exit</Button>
            </div>
        </OptionsFrameHOC>
    );
};

export default Appointments;
