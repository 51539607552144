//Expected Actions to be used throughout the lifecycle of the app.
//AUTH
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_NETWORK = 'SET_NETWORK';
export const SET_STREAM = 'SET_STREAM';
export const SET_RND = 'SET_RND';


//UI
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_LOADING = 'SET_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SET_SORT = 'SET_SORT';
export const CLEAR_SORT = 'CLEAR_SORT';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_EDITED = 'SET_EDITED';
export const CLEAR_EDITED = 'CLEAR_EDITED';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SET_GRID_VIEW = 'SET_GRID_VIEW';
export const SET_SELECTED_ROW = 'SET_SELECTED_ROW';


//DATA
export const SET_DATA = 'SET_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';
export const SET_VIEW_OPTION = 'SET_VIEW_OPTION';
export const CLEAR_VIEW_OPTION = 'CLEAR_VIEW_OPTION';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const CLEAR_SEARCH_STRING = 'CLEAR_SEARCH_STRING';
export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const CLEAR_PATIENT_DETAILS = 'CLEAR_PATIENT_DETAILS';
export const SET_PERSONAL_REFERRAL_DETAILS = 'SET_PERSONAL_REFERRAL_DETAILS';
export const CLEAR_PERSONAL_REFERRAL_DETAILS = 'CLEAR_PERSONAL_REFERRAL_DETAILS';
export const SET_PERSONAL_REFERRAL_HISTOLOGY = 'SET_PERSONAL_REFERRAL_HISTOLOGY';
export const CLEAR_PERSONAL_REFERRAL_HISTOLOGY = 'CLEAR_PERSONAL_REFERRAL_HISTOLOGY';
export const SET_MANAGEMENT_HISTORY = 'SET_MANAGEMENT_HISTORY';
export const CLEAR_MANAGEMENT_HISTORY = 'CLEAR_MANAGEMENT_HISTORY';
export const SET_PERSONAL_RELEVANT_DETAILS = 'SET_PERSONAL_RELEVANT_DETAILS';
export const CLEAR_PERSONAL_RELEVANT_DETAILS = 'CLEAR_PERSONAL_RELEVANT_DETAILS';
export const SET_REFERRAL_REASONS = 'SET_REFERRAL_REASONS';
export const CLEAR_REFERRAL_REASONS = 'CLEAR_REFERRAL_REASONS';
export const SET_PATIENT_ASSOCIATED_SYMPTOMS = 'SET_PATIENT_ASSOCIATED_SYMPTOMS';
export const CLEAR_PATIENT_ASSOCIATED_SYMPTOMS = 'CLEAR_PATIENT_ASSOCIATED_SYMPTOMS';
export const SET_EXAMINATION_DETAILS = 'SET_EXAMINATION_DETAILS';
export const CLEAR_EXAMINATION_DETAILS = 'CLEAR_EXAMINATION_DETAILS';
export const SET_EXAMINATIONS = 'SET_EXAMINATIONS';
export const CLEAR_EXAMINATIONS = 'CLEAR_EXAMINATIONS';
export const SET_EXAMINATION = 'SET_EXAMINATION';
export const CLEAR_EXAMINATION = 'CLEAR_EXAMINATION';
export const SET_LAST_EXAMINATION = 'SET_LAST_EXAMINATION';
export const CLEAR_LAST_EXAMINATION = 'CLEAR_LAST_EXAMINATION';
export const SET_MANAGEMENT_DETAILS = 'SET_MANAGEMENT_DETAILS';
export const CLEAR_MANAGEMENT_DETAILS = 'CLEAR_MANAGEMENT_DETAILS';
export const SET_TREATMENT_DETAILS = 'SET_TREATMENT_DETAILS';
export const CLEAR_TREATMENT_DETAILS = 'CLEAR_TREATMENT_DETAILS';
export const SET_SMEAR_RESULTS = 'SET_SMEAR_RESULTS';
export const CLEAR_SMEAR_RESULTS = 'CLEAR_SMEAR_RESULTS';
export const SET_DATASHEET_PICTURES = 'SET_DATASHEET_PICTURES';
export const CLEAR_DATASHEET_PICTURES = 'CLEAR_DATASHEET_PICTURES';
export const SET_FILES = 'SET_FILES';
export const SET_FAILSAFE_DATA = 'SET_FAILSAFE_DATA';
export const FETCH_FAILSAFE_DATA_START = 'FETCH_FAILSAFE_DATA_START';
export const FETCH_FAILSAFE_DATA_SUCCESS = 'FETCH_FAILSAFE_DATA_SUCCESS';
export const FETCH_FAILSAFE_DATA_FAILURE = 'FETCH_FAILSAFE_DATA_FAILURE';
export const FETCH_PERSONAL_DETAILS_FAILURE = '  FETCH_PERSONAL_DETAILS_FAILURE';
export const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
export const SET_FAILSAFE_DATA_1 = 'SET_FAILSAFE_DATA_1';
export const SET_FAILSAFE_DATA_2 = 'SET_FAILSAFE_DATA_2';
export const SET_FAILSAFE_DATA_3 = 'SET_FAILSAFE_DATA_3';
export const SET_FAILSAFE_DATA_4 = 'SET_FAILSAFE_DATA_4';
export const SET_FAILSAFE_DATA_5 = 'SET_FAILSAFE_DATA_5';
export const SET_FAILSAFE_DATA_6 = 'SET_FAILSAFE_DATA_6';
 

