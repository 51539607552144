import { BSCCPHeaders } from './commonData';


export const countColposcipistsCases = async (cases, colps) => {
console.log(cases.length, 'ln')
    let CategoryName = colps.map(a => {return a.Name});
    let NumberOfCategories = colps.length;
    let colposcopistCases = [];
   
    // Initialize subcategory count arrays
    // let subCategoryCount1 = new Array(NumberOfCategories).fill(0).map(() => new Array().fill(0));
    // let subCategoryCount2 = new Array(); // Biopsied
    // let subCategoryCount3 = new Array(); // Adequate
    // let subCategoryCount4 = new Array(); // CIN/VIN
    // let subCategoryCountCIN1 = new Array(); // CIN1
    // let subCategoryCountCIN2 = new Array(); // CIN2
    // let subCategoryCountCIN3 = new Array(); // CIN3
    // let subCategoryCountInvasive = new Array(); // Invasive
    // let subCategoryCountHGCGIN = new Array(); // HG CGIN
    // let subCategoryCountNoEvidence = new Array(); // No Evidence
    // let subCategoryCountAbnormalSymptoms = new Array(); // Abnormal Symptoms
    // let subCategoryCountAbnormalAppearance = new Array(); // Abnormal Appearance
    // let subCategoryCountHRHPV = new Array(); // HR HPV
    // let subCategoryCountTertiary = new Array(); // Tertiary

    const NumberOfsubCategories = 12; // Adjust this value according to your requirements
    let SystemWaitingDate = new Date('1/1/1991');

    //       Initialize arrays to store counts for categories and subcategories
    let categoryCount1 = new Array(NumberOfCategories).fill(0);
    let categoryCount2 = new Array(NumberOfCategories).fill(0);
    let categoryCount3 = new Array(NumberOfCategories).fill(0);
    let categoryCount4 = new Array(NumberOfCategories).fill(0);
    
    let subCategoryCount1 = new Array(NumberOfCategories);
    let subCategoryCount2 = new Array(NumberOfCategories);
    let subCategoryCount3 = new Array(NumberOfCategories);
    let subCategoryCount4 = new Array(NumberOfCategories);
    let subCategoryCountCIN1 = new Array(NumberOfCategories);
    let subCategoryCountCIN2 = new Array(NumberOfCategories);
    let subCategoryCountCIN3 = new Array(NumberOfCategories);
    let subCategoryCountInvasive = new Array(NumberOfCategories);
    let subCategoryCountHGCGIN = new Array(NumberOfCategories);
    let subCategoryCountNoEvidence = new Array(NumberOfCategories);
    let subCategoryCountAbnormalSymptoms = new Array(NumberOfCategories);
    let subCategoryCountAbnormalAppearance = new Array(NumberOfCategories);
    let subCategoryCountHRHPV = new Array(NumberOfCategories);
    let subCategoryCountTertiary = new Array(NumberOfCategories);
    
    // Initialize subcategory count arrays for each category
    for (let category = 0; category < NumberOfCategories; category++) {
        subCategoryCount1[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCount2[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCount3[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCount4[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountCIN1[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountCIN2[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountCIN3[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountInvasive[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountHGCGIN[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountNoEvidence[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountAbnormalSymptoms[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountAbnormalAppearance[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountHRHPV[category] = new Array(NumberOfsubCategories).fill(0);
        subCategoryCountTertiary[category] = new Array(NumberOfsubCategories).fill(0);
    }


    cases.forEach((record, index) => {
        let category = 0;
       let subcategory = 0;
       let caseObject = {}
       BSCCPHeaders.forEach(a => {
           if(a.field.includes('Num') || a.field.includes('Count')){
               caseObject[a.field] = 0
           } else {
               caseObject[a.field] = "-"
           }
       })
       
       
        // Set default values for variables
       let sReferringCentre = "-";
       let sReferralIndicationCervix = "-";
       let sReferralSmearCervix = "-";
       let sExaminerName = "-";
       let sTrainer = "-";
       let sExaminationDate = "-";
       let sEpisodeID = "-";
       let sExamBiopsyDate = "-";
       let sExamNonNeoplasticResults = "-";
       let sTreatmentDate = "";
       let ExamCINcount = 0;
       let sTreatementName = "-";
       let sTreatmentBiopsyDate = "-";
       let sTreatmentNonNeoplasticResults = "-";
       let TreatCINcount  = 0
        
         let currentSystemNumber = record.SystemID

         let DisplayNHSNumber = record.NHSnumber;
         let DisplayHospitalNumber = record.HospitalNumber;
        

        // Find category number for this colposcopist
        for (let Index = 0; Index < NumberOfCategories; Index++) {
            if (record.Name == CategoryName[Index]) {
                category = Index + 1;
                break;
            }
        }

        // Set values for sTrainer and update CategoryCount4 if not empty
        sTrainer = record.Trainer ? record.Trainer : "-";
        if (record.Trainer) {
            categoryCount4[CategoryName.indexOf(record.Trainer)]++;
        }

        // Set values for other variables
        sReferringCentre = record.ReferringCentre ? record.ReferringCentre : "-";
        sReferralIndicationCervix = record.ReferralIndicationCervix ? record.ReferralIndicationCervix : "-";
        sReferralSmearCervix = record.ReferralSmearCervix ? record.ReferralSmearCervix : "-";
        sExaminationDate = record.ExaminationDate ? formatDate(record.ExaminationDate) : "-";
        sEpisodeID = record.EpisodeID ? record.EpisodeID : "-";
        sExaminerName = record.Name;
        
        if (category > 0) {        
        if (category > 0 && record.EpisodeID === 1) {

            if (record.EpisodeID === 1 && record.ReferringCentre !== "Follow-up") {
                // Put it into the new category
                categoryCount1[category - 1]++;
            } else {
                // Put it under follow up
                categoryCount2[category - 1]++;
            }
    
            // BSCCP only counts first examinations and see and treats on the first exams as new cases (visit visits)
            if (record.EpisodeID === 1 && record.ReferringCentre !== "Follow-up") {
                if (record.ReferralIndicationCervix === "Abnormal smear de novo") {
                    switch (record.ReferralSmearCervix) {
                        case "Unsatisfactory":
                            subcategory = 1;
                            break;
                        case "Borderline Nuc. Abn.":
                        case "Borderline Endo Cervical":
                        case "Persistent Borderline":
                            subcategory = 2;
                            break;
                        case "Mild Dyskaryosis":
                        case "Mild Dyskaryosis (Low grade)":
                            subcategory = 3;
                            break;
                        case "Moderate Dyskaryosis":
                        case "Abnormal Unclassifiable":
                            subcategory = 4;
                            break;
                        case "Severe Dyskaryosis":
                            subcategory = 5;
                            break;
                        case "Possible Invasion":
                            subcategory = 6;
                            break;
                        case "Glandular Neoplasia":
                            subcategory = 7;
                            break;
                        default:
                            subcategory = 8; // Other
                            if (record.ReferralSmearHPV) {
                                subcategory = 11;
                            }
                            break;
                    }
                } else if (record.ReferralSmearCervix === "Negative (Normal)" && record.ReferralSmearHPV) {
                    subcategory = 11;
                } else {
                    subcategory = 8; // Other
                    if (["Suspicious symptoms", "PCB (age > 35) persists > 4 weeks", "PCB", "Inflammation", "Polyps", "contact bleeding", "Suspicious symptoms other"].includes(record.ReferralIndicationCervix.toLowerCase())) {
                        subcategory = 9; // Abnormal symptoms
                    } else if (record.ReferralIndicationCervix === "Clinically suspicious cervix") {
                        subcategory = 10; // Abnormal appearance
                    } else if (record.ReferralIndicationCervix === "Colp/Treatment elsewhere") {
                        subcategory = 12; // Tertiary
                    }
                }
            }
        
        subCategoryCount1[category - 1][subcategory]++
        
        for (let i = 0; i < record.BiopsyData.length; i++) {
            const rs = record.BiopsyData[i];
          
            // Check if BiopsyResultsDate1 is not null
            if (rs.BiopsyResultsDate1) {
              subCategoryCount2[category - 1][subcategory]++; // Increment subCategoryCount2
          
              // Check if BiopsyResultsDate1 is not equal to SystemWaitingDate
              if (rs.BiopsyResultsDate1 !== SystemWaitingDate) {
                 sExamBiopsyDate = rs.BiopsyResultsDate1 !== null ? new Date(rs.BiopsyResultsDate1).toLocaleDateString("en-GB") : "-";
          
                if (!rs.NonNeoplastic1Unsatisfactory1) {
                  subCategoryCount3[category - 1][subcategory]++; // Increment subCategoryCount3
          
                  // Calculate ExamCINcount
                   ExamCINcount = -(rs.Neoplastic1CIN1 + rs.Neoplastic1CIN2 + rs.Neoplastic1CIN3 + rs.Neoplastic1UngradedCIN + rs.Neoplastic1SquamousCellCarcinoma + rs.Neoplastic1CGINPossible + rs.Neoplastic1CGINLowGrade + rs.Neoplastic1CGINHighGrade + rs.Neoplastic1Adenocarcinoma + rs.Neoplastic1Other1);
                  
                  if (ExamCINcount > 0) {
                    subCategoryCount4[category - 1][subcategory]++; // Increment subCategoryCount4
                  }
          
                  // Split the CIN into different categories for new BSCCP form
                  if (rs.Neoplastic1CGINPossible || rs.Neoplastic1CGINHighGrade || rs.Neoplastic1CGINLowGrade) {
                    subCategoryCountHGCGIN[category - 1][subcategory]++; // Increment subCategoryCountHGCGIN
                  } else if (rs.Neoplastic1SquamousCellCarcinoma || rs.Neoplastic1Adenocarcinoma) {
                    subCategoryCountInvasive[category - 1][subcategory]++; // Increment subCategoryCountInvasive
                  } else if (rs.Neoplastic1CIN3) {
                    subCategoryCountCIN3[category - 1][subcategory]++; // Increment subCategoryCountCIN3
                  } else if (rs.Neoplastic1CIN2 || rs.Neoplastic1UngradedCIN) {
                    subCategoryCountCIN2[category - 1][subcategory]++; // Increment subCategoryCountCIN2
                  } else if (rs.Neoplastic1CIN1) {
                    subCategoryCountCIN1[category - 1][subcategory]++; // Increment subCategoryCountCIN1
                  } else if (rs.Neoplastic1Other1) {
                    subCategoryCountCIN2[category - 1][subcategory]++; // Increment subCategoryCountCIN2
                  }
          
                  if (ExamCINcount === 0) {
                    subCategoryCountNoEvidence[category - 1][subcategory]++; // Increment subCategoryCountNoEvidence
                  }
                }
              }
            }
            sExamNonNeoplasticResults = rs.NonNeoplastic1Unsatisfactory1 ? rs.NonNeoplastic1Unsatisfactory1 : "-";
          }    
        }
        }   
          
          // Convert TreatmentDate and ExaminationDate to Date objects
            var treatmentDate = new Date(record.TreatmentDate);
            var examinationDate = new Date(record.ExaminationDate);
            
            // Calculate the difference in milliseconds
            var dateDiff = Math.abs(treatmentDate.getTime() - examinationDate.getTime());
            
            
            
            // Convert milliseconds to days
            var daysDiff = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

            
            
            console.log( daysDiff, dateDiff, 'daysdiff', DisplayHospitalNumber, record.TreatmentDate, record.ExaminationDate )
            console.log( record, 'rec' )
            // Check if TreatmentDate and ExaminationDate are on the same day
            if (daysDiff === 0) {
              categoryCount3[category - 1]++; // Increment CategoryCount3
          
              // Check if BiopsyResultsDate2 is not null
              if (record.BiopsyResultsDate2) {
                 sTreatmentDate = new Date(record.TreatmentDate).toLocaleDateString("en-GB"); // Get the treatment date
                sTreatementName = record.TreatmentName ? record.TreatmentName : "-"; // Get the treatment name
          
                subCategoryCount2[category - 1][subcategory]++; // Increment subCategoryCount2
          
                // Check if BiopsyResultsDate2 is not equal to SystemWaitingDate
                if (new Date(record.BiopsyResultsDate2).getTime() !== SystemWaitingDate.getTime()) {
                   sTreatmentBiopsyDate = new Date(record.BiopsyResultsDate2).toLocaleDateString("en-GB"); // Get the biopsy date
                  sTreatmentNonNeoplasticResults = record.NonNeoplastic2Unsatisfactory1 ? "-" : record.NonNeoplastic2Unsatisfactory1;
          
                  subCategoryCount3[category - 1][subcategory]++; // Increment subCategoryCount3
          
                  // Calculate TreatCINcount
                  let TreatCINcount = -(record.Neoplastic2CIN1 + record.Neoplastic2CIN2 + record.Neoplastic2CIN3 + record.NeoPlastic2UngradedCIN + record.Neoplastic2SquamousCellCarcinoma + record.Neoplastic2Invasive + record.Neoplastic2Invasive1a1 + record.Neoplastic2Invasive1a2 + record.Neoplastic2Invasive1a + record.Neoplastic2CGINPossible + record.Neoplastic2CGINLowGrade + record.Neoplastic2CGINHighGrade + record.Neoplastic2Adenocarcinoma + record.Neoplastic2Other1);
                  
                  if (TreatCINcount > 0) {
                    subCategoryCount4[category - 1][subcategory]++; // Increment subCategoryCount4
                  }
          
                  // Split the CIN into different categories for new BSCCP form
                  if (record.Neoplastic2CGINPossible || record.Neoplastic2CGINHighGrade || record.Neoplastic2CGINLowGrade) {
                    subCategoryCountHGCGIN[category - 1][subcategory]++; // Increment subCategoryCountHGCGIN
                  } else if (record.Neoplastic2Invasive || record.Neoplastic2Invasive1a1 || record.Neoplastic2Invasive1a2 || record.Neoplastic2Invasive1a || record.Neoplastic2SquamousCellCarcinoma || record.Neoplastic2Adenocarcinoma) {
                    subCategoryCountInvasive[category - 1][subcategory]++; // Increment subCategoryCountInvasive
                  } else if (record.Neoplastic2CIN3) {
                    subCategoryCountCIN3[category - 1][subcategory]++; // Increment subCategoryCountCIN3
                  } else if (record.Neoplastic2CIN2 || record.NeoPlastic2UngradedCIN) {
                    subCategoryCountCIN2[category - 1][subcategory]++; // Increment subCategoryCountCIN2
                  } else if (record.Neoplastic2CIN1) {
                    subCategoryCountCIN1[category - 1][subcategory]++; // Increment subCategoryCountCIN1
                  } else if (record.Neoplastic2Other1) {
                    subCategoryCountCIN2[category - 1][subcategory]++; // Increment subCategoryCountCIN2
                  }
          
                  if (TreatCINcount === 0) {
                    subCategoryCountNoEvidence[category - 1][subcategory]++; // Increment subCategoryCountNoEvidence
                  }
                }
              }
          }  
          
          if (category > 0 && record.EpisodeID === 1) {
        caseObject['index'] =  index;
        caseObject['ExaminationID'] = record.ExaminationID;
        caseObject['SystemID'] = currentSystemNumber;
        caseObject['HospitalNumber'] = DisplayHospitalNumber;
        caseObject['NHSNumber'] = DisplayNHSNumber;
        caseObject['ExaminationDate'] = `${sExaminationDate}`;
        caseObject['ExamNum'] = sEpisodeID;
        caseObject['TreatmentName'] = sTreatementName;
        caseObject['Examiner'] = sExaminerName;
        caseObject['Trainer'] = sTrainer;
        caseObject['ReferralCentre'] = sReferringCentre;
        caseObject['ReferralIndication'] = sReferralIndicationCervix;
        caseObject['ReferralSmear'] = sReferralSmearCervix;
        caseObject['ExamBiopsyResultsDate'] = sExamBiopsyDate;
        caseObject['ExamNon-NeoplasticUnsatisfactory'] = sExamNonNeoplasticResults; // Note: This key has non-standard characters; you may need to adjust it accordingly
        caseObject['ExamCINCount'] = ExamCINcount;
        caseObject['TreatmentBiopsyResultsDate'] = sTreatmentBiopsyDate;
        caseObject['TreatmentNonNeoPlasticUnsatisfactory'] = sTreatmentNonNeoplasticResults; // Note: This key has non-standard characters; you may need to adjust it accordingly
        caseObject['TreatmentCINCount'] = TreatCINcount;
        colposcopistCases.push(caseObject)  
          
          }

    })
    
    return {colposcopistCases, categoryCount1, categoryCount2, categoryCount3, categoryCount4, subCategoryCount1, subCategoryCount2, subCategoryCount3, subCategoryCount4, subCategoryCountAbnormalAppearance, subCategoryCountAbnormalSymptoms, subCategoryCountCIN1, subCategoryCountCIN2, subCategoryCountCIN3, subCategoryCountHGCGIN, subCategoryCountHRHPV, subCategoryCountInvasive, subCategoryCountNoEvidence, subCategoryCountTertiary}
}



function formatDate(date) {
    let d = new Date(date);
    let day = d.getDate().toString().padStart(2, '0');
    let month = (d.getMonth() + 1).toString().padStart(2, '0');
    let year = d.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
}