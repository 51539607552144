// import {fetchError, fetchStart, fetchSuccess} from "../actions";

import { SET_FILES } from "./types";
import { env_vars } from 'Utils/config';
import axios from 'axios';

import { authHeader } from '../auth-header';


export const getAllRecords = (params) => async dispatch => {
  return await axios
    .get(env_vars.api_url + `/files/getAll/${params}`, { headers: authHeader() })
    .then((response) => {
      dispatch({ type: SET_FILES, payload: response.data })
      return response.data;
    });
};


export const fileUpload = (data, filePath) => async dispatch => {
  return await axios
    .post(env_vars.api_url + `/files/upload?filePath=${filePath}`, data, { headers: authHeader() })
    .then((response) => {
      dispatch(getAllRecords());
      return response.data;
    }).catch(err => {
      return null
    });
};

export const fileUploadData = (data, filePath) => async dispatch => {
  return await axios
    .post(env_vars.api_url + `/files/upload-data?filePath=${filePath}`, data, { headers: authHeader() })
    .then((response) => {
      dispatch(getAllRecords());
      return response.data;
    }).catch(err => {
      return null
    });
};


export const fileUploadTmp = (data, filePath) => async dispatch => {
  return await axios
    .post(env_vars.api_url + `/files/upload?tmpFile=true&filePath=${filePath}`, data, { headers: authHeader() })
    .then((response) => {
      // dispatch(getAllRecords());
      return response.data;
    }).catch(err => {
      return null
    });
};


export const createRecord = (data, parent) => async dispatch => {
  return await axios
    .post(env_vars.api_url + `/files/create`, { ...data, parent }, { headers: authHeader() })
    .then((response) => {
      dispatch(getAllRecords());
      return response.data;
    }).catch(err => {
      console.log(err)
      return []
    });
};

export const getFilePath = (data) => async dispatch => {
  return await axios
    .post(env_vars.api_url + `/files/create`, { ...data }, { headers: authHeader() }).catch(err => {
      console.log(err)
      return err
    });
};


export const updateRecord = (data) => async dispatch => {
  return await axios
    .put(env_vars.api_url + `/files/updateById/${data._id}`, data)
    .then((response) => {
      dispatch(getAllRecords());
      return response.data;
    });
};




export const deleteRecord = (data) => async dispatch => {
  return await axios
    .delete(env_vars.api_url + `/files/deleteById/${data}`)
    .then((response) => {
      dispatch(getAllRecords());
      return response.data;
    }).catch(err => {
      console.log(err)
      return err
    });
};





export const getFile = (id, filePath) => async dispatch => {
  return await axios
    .get(env_vars.api_url + `/files/download/${id}?filePath=${filePath}`)
    .catch(err => {
      console.log(err)
      return false
    })
};




