import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const PatientDataModal = ({ isOpen, toggle, patientData }) => {
  // Function to handle printing
  const handlePrint = () => {
    console.log("Printing");
  };

  // Function to handle font settings
  const handleFonts = () => {
    console.log("Setting fonts");
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      {/* Modal header */}
      <div className="card-header bg-iri w-100">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <h4 className="text-white">FailSafe List</h4>
        </div>
      </div>
      {/* Modal body */}
      <ModalBody>
        {/* Render patient data */}
        {/* Iterate over patient data */}
        {Object.entries(patientData).map(([item, data]) => (
          <div key={item}>
            <p><strong>{item}</strong></p>
            {/* Check if data is available */}
            {data && data.length > 0 ? (
              <table className="table table-stripped">

                <tbody>
                  {/* Iterate over patient details */}
                  {data.map((patient, index) => (
                    <tr key={index}>
                      {/* Display patient details in table rows */}
                      <td>{patient.TreatmentDate}</td>
                      <td>{patient.Forename} {patient.Surname}</td>
                      <td>{patient.ManagementPlan}</td>
                      <td>{patient.Failsafe}</td>
                      <td>{patient.HospitalNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No data available for {item}</p>
            )}
          </div>
        ))}
      </ModalBody>
      {/* Modal footer */}
      <ModalFooter className="d-flex justify-content-end">
        {/* Button to handle font settings */}
        <Button className="btn btnIri" onClick={handleFonts}>
          Fonts
        </Button>
        {/* Button to handle printing */}
        <Button className="btn btnIri" onClick={handlePrint}>
          Print
        </Button>
        {/* Button to close the modal */}
        <Button className="btn btnIri marRight20" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PatientDataModal;
