import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StatisticsTable from '../StatisticsTable';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { createQuarterDateRanges,  getDateRange } from 'Utils/helpers';
import { getQarcStats,  getStatisticsColposcopists } from 'redux/actions/data.action';


function createData(code, name, target, met, total, ratio) {
  return { code, name, target, met, total, ratio };
}

  
  
const headers = [
  {
    title: "Code",
    style: { width: '50px'},
    // component: "th",
    field: "code",
    scope: 'row',
    align: "left"
  },
  {
    title: "Name",
    field: "name",
    align: "left"
  },
  {
    title: "Target",
    field: "target",
    align: "center"
  },
  {
    title: "Met",
    field: "met",
    align: "center"
  },
  {
    title: "Total",
    field: "total",
    align: "center"
  },
  {
    title: "Ratio",
    field: "ratio",
    align: "center"
  }
] 
  


const rowsData = [
  createData('Cx_RE15', 'New Abnormal Patients Seen By Each Colposcopist', '>=50', '0', '0', '0.00%'),
  createData('Cx_DI1', 'Biopsy Taken Moderate And Severe Referrals', '>=95%', '0', '0', '0.00%'),
  createData('Cx_DI14', 'Adequate Biopies Taken At Colposcopy', '>=90%', '0', '0', '0.00%'),
  createData('Cx_TR4', 'Women treated at colposcopy whose specimens were removed as single loop', '>=80%', '0', '0', '0.00%'),
  createData('Cx_TR1', 'A biopsy result available to colposcopy before destructive therapy', '>=100%', '0', '0', '0.00%'),
  createData('Cx_TR2', 'No Dyskaryosis 8 months after Treatment', '>=90%', '0', '0', '0.00%'),
  createData('Cx_TR3', 'CIN or Cancer 12 months after Treatment', '<=5%', '0', '0', '0.00%'),
  createData('Cx_TR5', 'Women treated at colposcopy with depth of excision > 7mm', '>=95%', '0', '0', '0.00%'),
  createData('Cx_TR6', 'Treatments undertaken at colposcopy with local anaesthesia', '>=80%', '0', '0', '0.00%'),
  createData('Cx_TR8', 'See & Treat High Grade Histology', '>=90%', '0', '0', '0.00%'),
  createData('Cx_TR9', 'Low grade referrals - See & Treat high grade histology', '>=80%', '0', '0', '0.00%'),
  createData('Cx_TR17', 'Treatment within 4 weeks of colposcopy receiving a diagnostic biopsy report', '>=90%', '0', '0', '0.00%'),
  
  // createData('Cx_TR17a', 'Treatment within 4 weeks of colposcopy receiving a diagnostic biopsy report', '>=90%', '0', '0', '0.00%'),
  // createData('Cx_TR17b', 'Treatment within 8 weeks of colposcopy receiving a diagnostic biopsy report', '>=100%', '0', '0', '0.00%'),
  // createData('Cx_DI15a', 'PPV colp opinion against high grade lesion or worse - High grade referrals', '>=75%', '0', '0', '0.00%'),
  // createData('Cx_DI15b', 'PPV colp opinion against high grade lesion or worse - Other referrals', '>=35%', '0', '0', '0.00%')
  createData('Cx_DI15', 'PPV Colp opinion against high grade lesion or worse', '>=65%', '0', '0', '0.00%'),

]
;
  
  
const QarcAudit = () => {
  const {user } = useSelector(({auth}) => auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedColp, setSelectedColp] = useState(null);
    const [qarcType, setQarcType] = useState('Met');
    const [range, setRange] = useState({
      start: new Date('2020-01-01'),
      end: new Date('2022-01-01'),
      period: null
      
    })

    const [colposcopists, setColposcopists] = useState([]);
    
    
    
    const handleExit = () => {
        localStorage.removeItem("formDetails")
        navigate('/dashboard/statistics')
    }
    
    
    const handleClick = prop => event => {
      if(prop === 'click'){
        setSelectedRow(selectedRow == event ? null : event);
      } else {
        handleLoad(event)      
      }
    }
    

const handleGetColposcopists = () => {
  dispatch(getStatisticsColposcopists(user.SiteID, 'Colposcopy'))
  .then(res => {
    if(res){
        setColposcopists(res)
        setSelectedColp(res[0].Name)
    }
  })
}


const handleSelectPeriod = (e) => {
  let ind = createQuarterDateRanges().indexOf(e);
  let startDate = range.start;
  let endDate = range.end;
  if(!e) {
    startDate = "01/01/2020"
    endDate = "01/01/2022"
    setRange({...range, start: startDate, end: endDate, period: e})  

  } else { 
  if(ind !== -1){
    let ranges = getDateRange(ind);
    setRange({start: ranges[0], end: ranges[1], period: e});
    startDate = ranges[0];
    endDate = ranges[1];
  } else {
    if(e.field == 'start'){
      startDate = e.value;
    }
    if(e.field == 'end'){
      endDate = e.value
    }
    
    setRange({...range, [e.field]: e.value, period: e})  
  }
  }
}

const handleQarcData = async () => {
  let qarcData = await dispatch(getQarcStats({colposcopist: selectedColp, dtStartDate: range.start, dtEndDate: range.end, iSiteID: user.SiteID }));
  console.log(qarcData);
  let newRowData = [];
  if(qarcData && qarcData.length !== 0){
  
  rowsData.map(a =>{
    if(qarcData){
    
    let qarcRow = qarcData.find(ab => ab.code === a.code);
    if(a.code === "Cx_RE15") {
      newRowData.push({...a,met: 'No Data', ratio: 'No Data', total: qarcRow.tables[0] ? qarcRow.tables[0].length : 0});
    } else {
      newRowData.push({...a, total: qarcRow.tables[0] ? qarcRow.tables[0].length : 0});
    }
  }
  
  })
  setRows(newRowData)
} else {
  setRows(rowsData)
}
  
  
}


const handleLoad = (code) => {
  let row = rows[code];
  navigate(`/dashboard/statistics/qarc/${row.code}?site=${user.SiteID}&start=${range.start}&end=${range.end}&colposcopist=${selectedColp}&type=${qarcType}`)
  
}


useEffect(() => {
  handleQarcData();
  
}, [selectedColp, qarcType, range])


useEffect(() => {
  if(user.SiteID){
    handleSelectPeriod(createQuarterDateRanges()[0])
    handleGetColposcopists();
  }
}, [user.SiteID])


    return (
        <>
            <div className="card-header bg-iri w-100">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                    }}
                >
                    <h4 className="text-white">Qarc Statistics</h4>
                </div>
            </div>
            <div className="card-body"
            style={{ backgroundColor: '#E0E0E0', display: 'flex', flexDirection: 'column' }}
            >
                <StatisticsTable rows={rows} headers={headers} selectedRow={selectedRow} onClick={handleClick('click')} onDblClick={handleClick('dblClick')}/>  
                    <br/>
                <Grid container spacing={3}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                    <Grid item lg={6} xs={12}>
                    <Grid 
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    
                    >
                    <Grid item lg={6} xs={4} 
                        style={{textAlign: 'right'}}
                    >
                    <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>

                    </Grid>
                    <Grid item lg={6} xs={8}>
        

                <select name="marital-Status" id="marital-Status" className="form-control"
                  value={range.period}
                  onChange={(e) => handleSelectPeriod(e.target.value)}
                >
                  <option value={null}>Select</option>
                  {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map(a => {
                    return (
                      <option key={a} value={a}>{a}</option>
                    )
                  })}
                </select>
                    </Grid>
                    </Grid>
                    <br/>
                    <Grid 
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    
                    >
                    <Grid item lg={6} xs={4} 
                        style={{textAlign: 'right'}}
                    >
                    <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Colposcopist</label>

                    </Grid>
                    <Grid item lg={6} xs={8}>
        

                <select name="marital-Status" id="marital-Status" className="form-control"
                  value={selectedColp}
                  onChange={(e) => setSelectedColp(e.target.value)}
                >
                  {colposcopists.length !== 0 && colposcopists.map(a => {
                    return (
                      <option value={a.Name}>{a.Name}</option>
                    )
                  })}
                </select>
                    </Grid>
                    </Grid>
                    
                    </Grid>
                    <Grid item lg={6} xs={12}
                    >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="space-between"
                        // spacing={2}
                    >
                    <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={qarcType}
                    onChange={(e) => setQarcType(e.target.value)}
                  >
                    <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
             
                        <Grid item lg={3}>
                        <FormControlLabel className='text-blue' style={{fontWeight: 'bold'}} value="Met" control={<Radio />} label="Met" />
                        </Grid>
                        <Grid item lg={3}>
                        <FormControlLabel  className='text-blue' value="Not Met" control={<Radio />} label="Not Met" />
                        </Grid>
                        <Grid item lg={3}>
                        <FormControlLabel  className='text-blue' value="Total" control={<Radio />} label="Total" />
                        </Grid>
                        <Grid item lg={3}>
                        </Grid>
                    </Grid>
                    </RadioGroup>
                    
                    <br/>
                    <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    >
                        <Grid item lg={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        >
                        <button className="btn btnIri w-20 ml-1"
                        disabled={selectedRow == null || !(selectedRow >= 0)}
                        onClick={() => handleLoad(selectedRow)}
                        >Load</button>
                        </Grid>
                        <Grid item lg={2}>
                        <button className="btn btnIri w-20 ml-1"
                        >Save</button>
                        </Grid>
                        <Grid item lg={2}>
                        <button className="btn btnIri w-20 ml-1"
                        >Print</button>
                        </Grid>
                        <Grid item lg={2}>
                        <button className="btn btnIri w-20 ml-1"
                        onClick={() => handleExit()}
                        >Exit</button>
                    </Grid>
                    
                  
                  </Grid>
                  </Grid> 
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default QarcAudit;
