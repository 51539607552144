import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_PATIENT_DETAILS,  OPEN_MODAL, SET_GRID_VIEW, SET_VIEW_OPTION } from '../../redux/actions/types';
import { env_vars } from '../../Utils/config';
import NewPatientModal from '../../component/Modals/NewPatientModal';
import { logoutUser } from 'redux/actions/auth.action';
let { azure_storage_url} = env_vars;

const Sidebar = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch()
    const { user, rnd } = useSelector(({ auth }) => auth);
    const { patientDetails } = useSelector(({ data }) => data);
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const LOCAL_STORAGE_KEY_PATIENT_MENU = 'isPatientExpanded';
    const LOCAL_STORAGE_KEY_STAT_MENU = 'isStatsExpanded';
    const [expanded, setExpanded] = useState(null);
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState('/static/default.png')

    const handleNavigate = (val) => {
        // navigate(val)
        navigate(val)
        setIsOpen(false)
    }



    const handleLogout = () => {

        dispatch(logoutUser())
            .then(() => {

                navigate('/login');
            })

    }


    const setViewOption = (e) => {
        if (edited.length !== 0 && viewOption !== e && e !== 'enter') {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
        }
        dispatch({ type: SET_VIEW_OPTION, payload: e })

    }

    const setActiveTab = (e) => {
        clearLocalStorage();
        // if(e !== 'patient' && edited){
        //     dispatch({type: OPEN_MODAL, payload: 'save_details'})
        // }
        setExpanded(e == expanded ? null : e)

    }

    const clearLocalStorage = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY_PATIENT_MENU, false);
        localStorage.setItem(LOCAL_STORAGE_KEY_STAT_MENU, false);
    }

    const handleEnter = (val) => {
    
    
    
    
        if(val === 'enter' || val === 'review'){
        dispatch({ type: OPEN_MODAL, payload: (edited.length !== 0 && val === 'enter') ? 'save_details' : 'HospitalNumber' });
        setViewOption(val);
        setActiveTab(val === 'enter' ? 'patient' : val);
        } else {
            if(edited.length !== 0){
                dispatch({ type: OPEN_MODAL, payload:  'save_details'});
            }
            setActiveTab(val); 
            setViewOption(val);
        
        }
        
        
        
        if (edited.length === 0) {
            dispatch({ type: CLEAR_PATIENT_DETAILS })
        }
        setIsOpen(false)

    }




    useEffect(() => {
        if (user.imgUrl) {
            setImageUrl(user.imgUrl)
        }
    }, [rnd, user])

    return (
        <>
            <NewPatientModal
                navigate={(e) => navigate(e)}
            />
            <aside className={`left-sidebar ${isOpen ? 'open' : ''}`}>
                <div className='logoProfile'>
                    <img src={azure_storage_url + imageUrl} alt={azure_storage_url + imageUrl} className="img-circle"
                        width={150}
                        height={150}
                    />

                    <span className=" waves-effect waves-dark" onClick={(e) => e.preventDefault()} aria-expanded="false">
                        {/* <span className="hide-menu">{user.Name}</span> */}
                    </span>
                </div>
                <div className='clearfix'></div>
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            {/* //PARENT MENU 1 */}
                            {viewOption === 'enter' && patientDetails && (patientDetails.HospitalNumber || patientDetails.NHSnumber) ?
                                <>
                                    <li onClick={() => setViewOption('enter')}
                                        onMouseEnter={() => setActiveTab('patient')}
                                        onMouseLeave={() => setActiveTab(null)}
                                    >
                                        <Link className={"has-arrow waves-effect waves-dark " + (expanded !== 'patient' ? 'has-arrow-custom no' : ' has-arrow-custom active')} onClick={() => setActiveTab('patient')}>
                                            <i className="ti-user"></i>
                                            <span className="hide-menu" >Enter
                                                {/* <span className="badge badge-pill badge-cyan ml-auto">3</span> */}
                                            </span>
                                        </Link>
                                        <ul aria-expanded="false" className={expanded == 'patient' ? '' : 'collapse'}>
                                            <li
                                                onClick={() => handleNavigate(`/dashboard/patient/enterPatient`)}
                                            ><NavLink
                                                className={`waves-effect waves-dark`} aria-expanded="false"
                                                // onClick={() => handleNavigate(`/dashboard/patient/enterPatient`)}
                                            //  to={`/dashboard/patient/enterPatient`}
                                            > <span className="hide-menu">Personal Details</span></NavLink></li>
                                            <li
                                                onClick={() => patientDetails.SystemID && handleNavigate(`/dashboard/patient/referralDetails`)}
                                            >
                                                <NavLink
                                                    className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                    //  aria-expanded="false"
                                                    //  to={`/dashboard/patient/referralDetails`}
                                                    // onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/referralDetails`))}

                                                > <span className="hide-menu">Referral Details</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/examination`))}
                                            >

                                                <NavLink
                                                    className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                    aria-expanded="false"
                                                    
                                                    onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/examination`))}
                                                // to={`/dashboard/patient/examination`}
                                                > <span className="hide-menu">Examination</span></NavLink></li>
                                            <li 
                                                
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/management`))}
                                            ><NavLink
                                                // onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/management`))}
                                                className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                aria-expanded="false" > <span className="hide-menu">Management</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/treatment`))}
                                            ><NavLink
                                                className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                // onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/treatment`))}
                                                aria-expanded="false"

                                            > <span className="hide-menu">Treatment</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/results`))}
                                            ><NavLink
                                                className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                // onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/patient/results`))}
                                                aria-expanded="false"
                                            // to={`/dashboard/${viewOption === 'enter' ? 'patient' : 'viewPatient'}/results`}
                                            > <span className="hide-menu">Results</span></NavLink></li>
                                            {/* <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to={`/dashboard/${viewOption === 'enter' ? 'patient' : 'viewPatient'}/appointment`}> <span className="hide-menu">Appointment</span></NavLink></li> */}

                                        </ul>
                                    </li></> : <li onClick={() => setViewOption('enter')}
                                    >
                                    <Link className="waves-effect waves-dark" onClick={() => handleEnter('enter')} aria-expanded="false" > <i className="ti-user"></i><span className="hide-menu">Enter</span></Link>
                                </li>}
                            {viewOption === 'review' && patientDetails && (patientDetails.HospitalNumber || patientDetails.NHSnumber) ?
                                <>
                                    <li onClick={() => setViewOption('review')}
                                        onMouseEnter={() => setActiveTab('review')}
                                        onMouseLeave={() => setActiveTab(null)}
                                    >
                                        <Link className={"has-arrow waves-effect waves-dark " + (expanded !== 'review' ? 'has-arrow-custom no' : ' has-arrow-custom active')}
                                            onClick={() => { setViewOption('review'); setActiveTab('review'); }}
                                            to="/dashboard/viewPatient"
                                        >
                                            <i className="ti-folder"></i>
                                            <span className="hide-menu" >Review
                                                {/* <span className="badge badge-pill badge-cyan ml-auto">3</span> */}
                                            </span>
                                        </Link>
                                        <ul aria-expanded="false" className={expanded == 'review' ? '' : 'collapse'}>
                                            <li
                                                onClick={() => handleNavigate(`/dashboard/viewPatient/enterPatient`)}
                                            ><NavLink
                                                className={`waves-effect waves-dark`}
                                                aria-expanded="false"
                                            //  onClick={() =>   handleNavigate(`/dashboard/viewPatient/enterPatient`)}
                                            //  to={`/dashboard/patient/enterPatient`}
                                            > <span className="hide-menu">Personal Details</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/viewPatient/examination`))}
                                            >

                                                <NavLink
                                                    className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                    aria-expanded="false"
                                                    onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/viewPatient/examination`))}
                                                // to={`/dashboard/patient/examination`}
                                                > <span className="hide-menu">Examination</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/viewPatient/management`))}
                                            ><NavLink
                                                onClick={() => (patientDetails.SystemID && handleNavigate(`/dashboard/viewPatient/management`))}
                                                className={`${patientDetails.SystemID ? 'waves-effect waves-dark' : 'disabled'}`}
                                                aria-expanded="false" > <span className="hide-menu">Management</span></NavLink></li>

                                            {/* <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to={`/dashboard/${viewOption === 'enter' ? 'patient' : 'viewPatient'}/appointment`}> <span className="hide-menu">Appointment</span></NavLink></li> */}

                                        </ul>
                                    </li>
                                </>
                                :
                                <>
                                    <li><Link className="waves-effect waves-dark" onClick={() => handleEnter('review')} aria-expanded="false" ><i className="ti-folder"></i><span className="hide-menu">Review</span></Link></li>
                                </>
                            }

                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setViewOption('trace'); setActiveTab('trace') }} aria-expanded="false" to="/dashboard/trace"><i className="ti-search"></i><span className="hide-menu">Trace</span></NavLink></li>
                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setActiveTab('letters'); setViewOption('letters'); }} aria-expanded="false" to={`/dashboard/letter-reports/${patientDetails.SystemID ? 'generate' : 'update'}`}><i className="ti-email"></i><span className="hide-menu">Letter</span></NavLink></li>
                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setActiveTab('appointments'); setViewOption('appointments'); }} aria-expanded="false" to="/dashboard/appointments"><i className="ti-calendar"></i> <span className="hide-menu">Appointments</span></NavLink></li>
                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setActiveTab('failsafe'); setViewOption('failsafe'); }} aria-expanded="false" to="/dashboard/failsafe"><i className="ti-shield"></i><span className="hide-menu">Failsafe</span></NavLink></li>
                            {/* //PARENT MENU 2 */}
                            <li
                                // onMouseEnter={() => setActiveTab('statistic')}
                                // onMouseLeave={() => setActiveTab(null)}
                                // to="/dashboard/statistics"
                            >
                       
                                <Link
                                onClick={() => handleEnter('statistic') }
                                // className={"waves-effect waves-dark"}
                                className={"has-arrow waves-effect waves-dark" + (expanded !== 'statistic' ? 'has-arrow-custom  no' : ' has-arrow-custom active')} 
                                aria-expanded="false" to="/dashboard/statistics" data-toggle="tooltip" title="Default tooltip">    
                                <i className="ti-stats-up"></i>
                                <span className="hide-menu">Statistics</span></Link>
                                <ul aria-expanded="false" className={expanded == 'statistic' ? '' : 'collapse'}
                                onClick={() => {
                                dispatch({type: SET_GRID_VIEW, payload: false})
                                setIsOpen(false)
                                
                                }}
                                
                                >
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/qarc" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">QARC Audit</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/bsccp" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">BSCCP</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/automatic" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Automatic</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/manual" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Manual</span></NavLink></li>
                               </ul>
                            </li>
                            {/* <li>
                                <Link className={"has-arrow waves-effect waves-dark " + ( !patientExpand ?'has-arrow-custom no':' has-arrow-custom active' )} onClick={setActiveTabPatient}>
                                    <i className="ti-user"></i>
                                    <span className="hide-menu" >Test Screens 
                                    </span>
                                    </Link>
                                <ul aria-expanded="false" className={patientExpand ? '' : 'collapse'}>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/patient/referralDetails"> <span className="hide-menu">Referral Details</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/patient/casereview"> <span className="hide-menu">Case Review</span></NavLink></li>
                                </ul>
                            </li> */}
                            <li> <Link className="waves-effect waves-dark" aria-expanded="false" to={`/dashboard/testPage`}><i className="fa fa-circle-o text-success"></i><span className="hide-menu">Test Page</span></Link></li>
                            <li> <a className="waves-effect waves-dark" aria-expanded="false" onClick={handleLogout}><i className="fa fa-circle-o text-success"></i><span className="hide-menu">Log Out</span></a></li>
                            {/* <li> <a className="waves-effect waves-dark" onClick={(e) => e.preventDefault()} aria-expanded="false"><i className="fa fa-circle-o text-info"></i><span className="hide-menu">FAQs</span></a></li> */}
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}



export default Sidebar;