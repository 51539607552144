import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "reactstrap";
import { CLEAR_SMEAR_RESULTS, OPEN_MODAL, SET_EDITED,  SET_SMEAR_RESULTS } from "../../../redux/actions/types";
import { getRefHpvSmearTest, getSmearResultDetailsById } from "../../../redux/actions/data.action";
import { groupByField } from "../../../Utils/helpers";
import moment from "moment/moment";


const Results = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { viewOption, edited } = useSelector(({ ui }) => ui)
  const { patientDetails, smearResults } = useSelector(({ data }) => data);
  const {  examination, examinationDetails } = useSelector(({ examination }) => examination);

  
  const [hpvProps, setHpvProps] = useState({});
  const [grpResultsCode, setGrpResultsCode] = useState([]);
  const [grpTestName, setGrpTestName] = useState([]);
  const [grpTestRange, setGrpTestRange] = useState([]);
  const [laboratories, setLaboratories] = useState([]);

  const handleChanges = prop => event => {
    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let val = event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value;
    let newVal = { ...smearResults, [prop]: val }

    if (val) {
      newVal.SmearResultsDate1 = moment().format('YYYY-MM-DD');
    }


    dispatch({ type: SET_SMEAR_RESULTS, payload: newVal })
    dispatch({ type: SET_EDITED, payload: 'results' })
  }

  const handleSelectHpv = prop => event => {

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let newHpvProps = { ...hpvProps, [prop]: event.target.value }



    if (prop === 'HPVResultCode1') {
      newHpvProps = { ...newHpvProps, ResultsCode: event.target.value, TestName: '', TestRange: '', SubTypes: '' }
    }

    if (prop === 'HPVTestName1') {
      newHpvProps = { ...newHpvProps, TestName: event.target.value, TestRange: '', SubTypes: '' }
    }

    if (prop === 'HPVTestRange1') {
      let refhpv = grpTestRange.find(a => a.title === event.target.value);
      if (refhpv) {
        newHpvProps = { ...newHpvProps, TestRange: refhpv.TestRange, SubTypes: refhpv.SubTypes }
      } else {
        newHpvProps = { ...newHpvProps, TestRange: "", SubTypes: "" }
      }
    }

    handleRefHpvSmearTests(newHpvProps)
    dispatch({ type: SET_SMEAR_RESULTS, payload: { ...smearResults, HPVResultCode1: newHpvProps.ResultsCode, HPVTestName1: newHpvProps.TestName, HPVTestRange1: newHpvProps.TestRange, HPVSubTypesTested1: newHpvProps.SubTypes } })
    dispatch({ type: SET_EDITED, payload: 'results' })
  }


  const handleRefHpvSmearTests = async (prop) => {
    return await dispatch(getRefHpvSmearTest(prop))
      .then(res => {
        let grpResultCode = groupByField(res.data, 'ResultsCode')
        let grpTestName = groupByField(res.data, 'TestName')
        let grpTestRange = groupByField(res.data, 'TestRange')
        
        if (!prop.ResultsCode) {
          setGrpResultsCode(grpResultCode)
        }
        if (!prop.TestName) {
          setGrpTestName(grpTestName)
        }
        if (!prop.TestRange) {
          setGrpTestRange(grpTestRange)
        }


        setHpvProps(prop)

        return res.data
      })
      .catch(err => {
        console.log(err, 'hpverr')
        return []
      })


  }


  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${viewOption === "enter" ? "/dashboard/patient/treatment" : "/dashboard/viewPatient/treatment"}`)
  }

  const handleExit = (e) => {
    if (edited.length !== 0) {
      dispatch({ type: OPEN_MODAL, payload: "save_details" })
      // return

    } else {
      dispatch({ type: CLEAR_SMEAR_RESULTS })
    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)

  }


  const handleBack = () => {
    navigate(-1)
  }


  const handleGetSmearResult = (id) => {
    dispatch(getSmearResultDetailsById(id))
      .then(res => {
        if (res.data) {
          dispatch({ type: SET_SMEAR_RESULTS, payload: res.data })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  useEffect(() => {
    if (patientDetails && (patientDetails.NHSnumber || patientDetails.HospitalNumber)) {
      //   setPatient(patientDetails)
      if (examination && examination.ExaminationID) {
        handleGetSmearResult(examination.ExaminationID)
      }
      let newHpv = handleRefHpvSmearTests({})
    } else {
      navigate('/dashboard/home')
    }


    return () => {
      setHpvProps({ ResultsCode: '', TestName: '', TestRange: '', SubTypes: '' })
      setGrpResultsCode([])
      setGrpTestName([])
      setGrpTestRange([])
    }
  }, [])

console.log(grpResultsCode, grpTestRange, grpTestName, 'GRPP')

  return (
    <>
      <div className="card-header bg-iri txtCenter mt-0">
        <h4 className="m-b-0 text-white">Smear Results</h4>
      </div>
      <div className="card-body pt-1">
        <Form
          id="formValid"
          noValidate
          onSubmit={handleSubmit}

        >
          <div className="pl-2 pr-2 mt-0">
            <div className="d-flex mt-2 mb-2">
              <div className='col-md-5 text-left mb-3 pl-2 pr-2' >
              </div>
              <div className='col-md-7 text-right' >
                {/* <label className="col-md-5 text-right disabled" >Treatment Date</label> */}
                <label className="col-md-5 text-right text-13 text-blue"  >Patient Notification Date</label>
                <input
                  type="date" id="MDMDate" name="MDMDate" className="form-control col-md-3" placeholder="MDM Date"
                  value={smearResults.Histology1ReferralNotifyDate}
                  onChange={handleChanges('Histology1ReferralNotifyDate')}
                // onChange={handleChanges('MDMDate')}
                />
                <input
                  // disabled
                  type={smearResults.SmearResultsDate1 ? "date" : "text"} id="MDMDate" name="MDMDate" className="form-control col-md-3 ml-2" placeholder="MDM Date"
                  onChange={handleChanges('SmearResultsDate1')}
                  value={smearResults.SmearResultsDate1 ? moment(smearResults.SmearResultsDate1).format('YYYY-MM-DD') : "Waiting"}
                  style={{ backgroundColor: '#FFFFCC', fontWeight: 500 }}
                />
              </div>
            </div>
            <div className='row bordered mt-1'>
              <h3 className='card-title text-center bordered-title'>Cytological Pattern</h3>
              <div className="d-flex pl-1 mt-1 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Negative (Normal)"
                    id="Negative (Normal)"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1Negative}
                    checked={smearResults.CytologicalPattern1Negative}
                    onChange={handleChanges('CytologicalPattern1Negative')}
                  // className="" onChange={handleCha nges('TreatmentVerbalConsent')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Negative (Normal)">Negative (Normal)</label>
                </div>
                <div className="col-md-3 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Mild Dyskaryosis (Low Grade)"
                    id="Mild Dyskaryosis (Low Grade)"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1MildDyskaryosis}
                    checked={smearResults.CytologicalPattern1MildDyskaryosis}
                    onChange={handleChanges('CytologicalPattern1MildDyskaryosis')}
                  // className="" onChange={handleCha nges('TreatmentVerbalConsent')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Mild Dyskaryosis (Low Grade)">Mild Dyskaryosis (Low Grade)</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Possible Invasion"
                    id="Possible Invasion"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1PossibleInvasion}
                    checked={smearResults.CytologicalPattern1PossibleInvasion}
                    onChange={handleChanges('CytologicalPattern1PossibleInvasion')}

                  // value={Boolean(treatmentDetails.TreatmentVerbalConsent) == true ? false : true}
                  // checked={Boolean(treatmentDetails.TreatmentVerbalConsent) == true}
                  // className="" onChange={handleCha nges('TreatmentVerbalConsent')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Possible Invasion">Possible Invasion</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >

                </div>
              </div>
              <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Unsatisfactory"
                    id="Unsatisfactory"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1Unsatisfactory}
                    checked={smearResults.CytologicalPattern1Unsatisfactory}
                    onChange={handleChanges('CytologicalPattern1Unsatisfactory')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Unsatisfactory">Unsatisfactory</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Moderate Dyskaryosis"
                    id="Moderate Dyskaryosis"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1ModerateDyskaryosis}
                    checked={smearResults.CytologicalPattern1ModerateDyskaryosis}
                    onChange={handleChanges('CytologicalPattern1ModerateDyskaryosis')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Moderate Dyskaryosis">Moderate Dyskaryosis</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Glandular Neoplasia"
                    id="Glandular Neoplasia"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1GlandularNeoplasia}
                    checked={smearResults.CytologicalPattern1GlandularNeoplasia}
                    onChange={handleChanges('CytologicalPattern1GlandularNeoplasia')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Glandular Neoplasia">Glandular Neoplasia</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Borderline Endo Cervical"
                    id="Borderline Endo Cervical"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1BorderlineAbnormalities}
                    checked={smearResults.CytologicalPattern1BorderlineAbnormalities}
                    onChange={handleChanges('CytologicalPattern1BorderlineAbnormalities')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Borderline Endo Cervical">Borderline Endo Cervical</label>
                </div>
              </div>
              <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Unclear Changes"
                    id="Unclear Changes"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1AbnormalUnclassifiable}
                    checked={smearResults.CytologicalPattern1AbnormalUnclassifiable}
                    onChange={handleChanges('CytologicalPattern1AbnormalUnclassifiable')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Unclear Changes">Unclear Changes</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Severe Dyskaryosis"
                    id="Severe Dyskaryosis"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1SevereDyskaryosis}
                    checked={smearResults.CytologicalPattern1SevereDyskaryosis}
                    onChange={handleChanges('CytologicalPattern1SevereDyskaryosis')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Severe Dyskaryosis">Severe Dyskaryosis</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Borderline Endometrial"
                    id="Borderline Endometrial"
                    className="mr-1"
                    value={!smearResults.CytologicalPattern1BorderlineEndometrial}
                    checked={smearResults.CytologicalPattern1BorderlineEndometrial}
                    onChange={handleChanges('CytologicalPattern1BorderlineEndometrial')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Borderline Endometrial">Borderline Endometrial</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >

                </div>
              </div>
            </div>
            <div className='row bordered pt-1 mt-2'>
              <h3 className='card-title text-center bordered-title mb-1'>HPV</h3>
              <div className="d-flex pl-1 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-4 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <div className="bordered pl-1 pr-1 pb-1">
                    <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0"   >Result Code</h3>
                    <select name="ResultsCode" id="ResultsCode" className="form-control m-0 "
                      value={smearResults.HPVResultCode1}
                      onChange={handleSelectHpv('HPVResultCode1')}
                    >
                      <option value="">Select</option>
                      {grpResultsCode.length !== 0 && grpResultsCode.map(a => {
                        return (
                          <option value={a.ResultsCode}>{a.ResultsCode}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                  <div className="bordered pl-1 pr-1 pb-1">
                    <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0" >Test Name</h3>
                    <select name="TestName" id="TestName" className="form-control m-0"
                      value={smearResults.HPVTestName1}
                      onChange={handleSelectHpv('HPVTestName1')}

                    >
                      <option value="">Select</option>
                      {grpTestName.length !== 0 && grpTestName.map(a => {
                        return (
                          <option value={a.TestName}>{a.TestName}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <div className="bordered pl-1 pr-1 pb-1">
                    <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0" >Test Range</h3>
                    <select name="TestRange" id="TestRange" className="form-control m-0"
                      value={smearResults.HPVTestRange1}
                      onChange={handleSelectHpv('HPVTestRange1')}

                    >
                      <option value="">Select</option>
                      {grpTestRange.length !== 0 && grpTestRange.map(a => {
                        return (
                          <option value={a.TestRange}>{a.TestRange}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>

              </div>
              <div className="d-flex pl-5 pr-3 mb-1 mt-2" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>


                <div className="bordered pl-1 pr-1 pb-1 w-100">
                  <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0" >Sub Type Tested</h3>
                  <input name="SubTypes" id="SubTypes" className="form-control m-0"
                    value={smearResults.HPVSubTypesTested1 ? smearResults.HPVSubTypesTested1 : ''}
                    onChange={handleChanges('HPVSubTypesTested1')}
                  />

                </div>

              </div>

            </div>
            <div className='row bordered mt-2 pt-1'>
              <h3 className='card-title text-center bordered-title mb-0'>Specific Infection</h3>
              <div className="d-flex pl-1 mt-2 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Trichomonas"
                    id="Trichomonas"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1Trichomonas}
                    checked={smearResults.SpecificInfection1Trichomonas}
                    onChange={handleChanges('SpecificInfection1Trichomonas')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Trichomonas">Trichomonas</label>
                </div>
                <div className="col-md-3 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Actinomyces"
                    id="Actinomyces"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1Actinomyces}
                    checked={smearResults.SpecificInfection1Actinomyces}
                    onChange={handleChanges('SpecificInfection1Actinomyces')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Actinomyces">Actinomyces</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Herpes"
                    id="Herpes"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1Herpes}
                    checked={smearResults.SpecificInfection1Herpes}
                    onChange={handleChanges('SpecificInfection1Herpes')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Herpes">Herpes</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="None"
                    id="None"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1None}
                    checked={smearResults.SpecificInfection1None}
                    onChange={handleChanges('SpecificInfection1None')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="None">None</label>
                </div>
              </div>
              <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Candida"
                    id="Candida"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1Candida}
                    checked={smearResults.SpecificInfection1Candida}
                    onChange={handleChanges('SpecificInfection1Candida')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Candida">Candida</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Wart virus"
                    id="Wart virus"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1WartVirus}
                    checked={smearResults.SpecificInfection1WartVirus}
                    onChange={handleChanges('SpecificInfection1WartVirus')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="Wart virus">Wart virus</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="BV (Clue cells)"
                    id="BV (Clue cells)"
                    className="mr-1"
                    value={!smearResults.SpecificInfection1BacterialVaginosis}
                    checked={smearResults.SpecificInfection1BacterialVaginosis}
                    onChange={handleChanges('SpecificInfection1BacterialVaginosis')}
                  />&nbsp;
                  <label className="text-blue" htmlFor="BV (Clue cells)">BV (Clue cells)</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >

                </div>
              </div>

            </div>

            <div className="d-flex pl-1 mt-2 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
              <div className="col-md-6 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                <label className="col-md-3 text-right font-500 text-blue"  >Laboratory</label>
                <select name="HVSLaboratoryName" id="HVSLaboratoryName" className="form-control col-md-7"
                  value={smearResults.HVSLaboratoryName}
                  onChange={handleSelectHpv('HVSLaboratoryName')}

                >
                  <option value="">Select</option>
                  {laboratories.length !== 0 && laboratories.map(a => {
                    return (
                      <option value={a.title}>{a.title}</option>
                    )
                  })}
                </select>
              </div>
              <div className="col-md-6 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                <label className="col-md-3 text-right font-500 text-blue"  >Prescription</label>
                <input
                  name="SmearPrescription"
                  id="SmearPrescription"
                  className="form-control col-md-7"
                  value={smearResults.SmearPrescription}
                  onChange={handleChanges('SmearPrescription')}
                // value={Boolean(treatmentDetails.TreatmentVerbalConsent) == true ? false : true}
                // checked={Boolean(treatmentDetails.TreatmentVerbalConsent) == true}
                // className="" onChange={handleCha nges('TreatmentVerbalConsent')}
                />
              </div>

            </div>


            <br />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
              <Button className="btn marRight20"
                disabled
                onClick={() => handleBack()}
              // disabled={attendance === 'scheduled' || !referral.AppointmentCancellationReason || viewOption === 'review'}
              >Previous</Button>

              <Button type="submit" className="btn btnIri marRight20"
              // disabled={referral.AppointmentCancellationReason}
              >Next</Button>
              <Button className="btn btnIri"
                onClick={() => handleExit()}
              // type="reset"
              >Exit</Button>
            </div>

          </div>
        </Form >
      </div>
    </>
  )
}

export default Results;
