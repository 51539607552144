import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_DATA, CLEAR_EXAMINATION, CLEAR_EXAMINATIONS, CLEAR_EXAMINATION_DETAILS, CLEAR_PATIENT_DETAILS, CLEAR_SEARCH_STRING, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS, SET_SORT } from '../redux/actions/types';
import { searchPatientDetails } from '../redux/actions/data.action';
import '../css/dev.css';
import { Button } from 'reactstrap';
import { traceResultHeader } from '../Utils/commonData';

const ResultList = ({ list, values }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sort, viewOption } = useSelector(({ ui }) => ui)
    const { patientDetails } = useSelector(({ data }) => data);
    const [selected, setSelected] = useState(null)

    const handleSort = (sort, sortby) => {
        dispatch(searchPatientDetails({ searchString: values.Surname + ' ' + values.DateOfBirth, ...values, sortby, sort: sort === 'ASC' ? 'DESC' : 'ASC' }))
            .then(res => {
                dispatch({ type: SET_SORT, payload: { [sortby]: sort === 'ASC' ? 'DESC' : 'ASC' } })
            })
    }

    const handleClick = (item) => {
        dispatch({ type: SET_PATIENT_DETAILS, payload: item })
        if (item.tbl_personalreferraldetails) {
            dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: item.tbl_personalreferraldetails[item.tbl_personalreferraldetails - 1] })
        }
        dispatch({ type: CLEAR_SEARCH_STRING })
        navigate('/dashboard/viewPatient')
    }

    const handleCancel = (item) => {
        dispatch({ type: CLEAR_DATA })
        dispatch({ type: CLEAR_EXAMINATION })
        dispatch({ type: CLEAR_EXAMINATIONS })
        dispatch({ type: CLEAR_PATIENT_DETAILS })
        dispatch({ type: CLEAR_EXAMINATION_DETAILS })
        navigate(`${viewOption === 'trace' ? '/dashboard/trace' : '/dashboard/patient'}`)
    }

    useEffect(() => {
        if (patientDetails && patientDetails.SystemID) {
            setSelected(patientDetails)
        }
        return () => {
            setSelected(null)
        }
    }, [patientDetails.SystemID])




    return (
        <>
            <div className="card-body trace-table p-0">
                {list.length !== 0 ?
                    <Table responsive  >
                        <thead>
                            <tr className='table-headers'>
                                {traceResultHeader.map(({ title, value }, index) => (
                                    <th key={index}
                                        onClick={() => handleSort(sort[value], value)}
                                    ><div className='text-left table-header-title'>{title} {(list.length > 1) ? sort[value] === 'ASC' ? <i className="fa fa-sort-amount-asc sort-icon"></i> : <i className="fa fa-sort-amount-desc sort-icon"></i> : <></>}</div> </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='trace-table-body'>
                            {list.map((a, index) => {
                                return (

                                    <tr key={index}
                                        className={`trace-table-item ${selected && selected.SystemID === a.SystemID ? 'trace-selected-item' : ''}`}
                                        onClick={() => setSelected(selected && a.SystemID === selected.SystemID ? null : a)}
                                    >
                                        {traceResultHeader.map(({ value }, index) => {

                                            return value === 'DateOfBirth' ?
                                                <td >{moment(a.DateOfBirth).format('L')}</td>
                                                :
                                                <td key={index}>{a[value]}</td>

                                        }
                                        )
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    :
                    <div className='d-flex centered' style={{ height: '50vh' }}>
                        <h3 className=''>No Record Found</h3>
                    </div>
                }
            </div>
            <div className="trace-buttons mt-3">
                <Button className="btn btnIri m-3 fR" style={{ width: '100px' }} disabled={!selected}
                    onClick={() => handleClick(selected)}
                >Ok</Button>
                <Button className="btn m-3 fR"
                    style={{ width: '100px' }}
                    onClick={() => handleCancel()}
                >Cancel</Button>

            </div>
        </>
    )
}

export default ResultList;
