import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_EDITED, CLEAR_EXAMINATIONS, CLEAR_PATIENT_DETAILS,  OPEN_MODAL, SET_DATA,  SET_LOADING, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS, SET_PERSONAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS, SET_SEARCH_STRING, STOP_LOADING } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { createExaminationDetails, createPatientDetails, createSmearResultDetails, createTreatmentDetails} from '../../redux/actions/data.action';



export default function SavePatientModal({ }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { modal, edited, loading  } = useSelector(({ ui }) => ui);
    const { datasheetpictures, smearResults, patientDetails, personalReferralDetails, referralReasons, treatmentDetails, patientAssociatedSymptoms, relevantDetails,  managementDetails,personalReferralHistology } = useSelector(({ data }) => data)
    const { examinationDetails, examination} = useSelector(({ examination }) => examination)



    const handleCreateNewPatient = () => {
        dispatch({type: SET_LOADING})
        
        let systemID = patientDetails.SystemID || examination.SystemID;
        let referralID = personalReferralDetails.ReferralID || examination.ReferralID;
        let examinationID = examinationDetails.ExaminationID || examination.ExaminationID;
        if (edited.find(a => a === 'personal')) {
            dispatch(createPatientDetails({
                reasons: referralReasons, patient: patientDetails, referral: personalReferralDetails, associatedSymptoms: patientAssociatedSymptoms, relevantDetails, referralHistology: personalReferralHistology
                , SystemID: patientDetails.SystemID
            }))
                .then((res) => {

                    const { patient, referral, reasons, associatedSymptoms, relevantDetails } = res.data
                    dispatch({ type: SET_PATIENT_DETAILS, payload: patient })
                    dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: referral })
                    dispatch({ type: SET_REFERRAL_REASONS, payload: reasons })
                    dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: associatedSymptoms })
                    dispatch({ type: SET_PERSONAL_RELEVANT_DETAILS, payload: relevantDetails })
                    dispatch({type: STOP_LOADING})
                })
                .then(() => {
                    clearState()
                    dispatch({type: STOP_LOADING})
                    return
                })
                .catch(err => {
                    console.log(err, "CREATE ERROR")
                    dispatch({type: STOP_LOADING})
                    return;
                })
        }

        if (edited.find(a => a === 'examination')) {
            dispatch(createExaminationDetails({ examinationDetails: { ...examinationDetails, SystemID: systemID, ReferralID: referralID }, managementDetails, treatmentDetails, datasheetpictures }))
                .then(() => {
                    clearState()
                    dispatch({type: STOP_LOADING})
                    return
                })
                .catch(err => {
                    console.log(err, "examination")
                    dispatch({type: STOP_LOADING})
                    return;

                })
        }

        if (examinationID && edited.find(a => a === 'Treatment')) {
            dispatch(createTreatmentDetails({ ...treatmentDetails, ExaminationID: examinationID }))
                .then(() => {
                    clearState()
                    dispatch({type: STOP_LOADING})
                    return
                })
                .catch(err => {
                    console.log(err, "Treatment")
                    dispatch({type: STOP_LOADING})
                    return;
                })
        }

        if (examinationDetails.ExaminationID && edited.find(a => a === 'results')) {
            dispatch(createSmearResultDetails({ ...smearResults, ExaminationID: examinationDetails.ExaminationID }))
                .then(() => {
                    clearState()
                    dispatch({type: STOP_LOADING})
                    return
                })
                .catch(err => {
                    console.log(err, "Results")
                    dispatch({type: STOP_LOADING})
                    return
                })
        }



    }

    const clearState = () => {
        dispatch({ type: SET_DATA, payload: [] })
        dispatch({ type: SET_SEARCH_STRING, payload: '' })
        dispatch({ type: CLEAR_EXAMINATIONS })
        dispatch({ type: SET_SEARCH_STRING })
        
        dispatch({ type: OPEN_MODAL, payload: null });
        dispatch({ type: CLEAR_EDITED })

        localStorage.removeItem('formDetails')
        localStorage.removeItem('edited')
    }

    const toggleNo = () => {
        if (modal === 'save_details') {
            dispatch({ type: CLEAR_PATIENT_DETAILS })
            dispatch({ type: CLEAR_EDITED })
        }
        dispatch({ type: OPEN_MODAL, payload: modal === 'save_details' ? null : 'save_details' });
        // dispatch({ type: CLEAR_VIEW_OPTION })

        localStorage.removeItem('formDetails')
        localStorage.removeItem('edited')

    }

    const handleClose = () => {
        dispatch({ type: OPEN_MODAL, payload: modal === 'save_details' ? null : 'save_details' });
        navigate(-1)
    }


    return (
        <Modal
            isOpen={modal === 'save_details'}
        // toggle={toggle}
        // className={this.props.className}
        >
            <ModalHeader className='btnIri' closeButton toggle={handleClose}>Entering Personal Details</ModalHeader>
            <ModalBody>
                Do you want to save the details?
            </ModalBody>
            <ModalFooter className='center' style={{ justifyContent: 'space-around' }}>
                <Button color="primary" className="btnIri" disabled={loading} onClick={handleCreateNewPatient}>Yes</Button>{' '}
                <Button color="secondary" onClick={toggleNo}>No</Button>
            </ModalFooter>
        </Modal>
    )
}
