import React from 'react'
import { Button, ButtonGroup } from 'reactstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { BlockPicker } from 'react-color';


export default function AnnotationTools({styleSelected, handleStyle}) {


  return (
    <div className='p-1 pt-2' style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
    <div className='w-100 text-left'>
    <label className='text-left text-primary'>Text Styles</label>
    <ButtonGroup 
    className='d-flex'
    >
    <Button 
    block
    outline
    active={styleSelected.fontWeight === 'bold'}
    onClick={() => handleStyle('style', 'bold')}
    color={styleSelected.fontWeight === 'bold' ? "primary" : ""}
    ><i className="fa fa-bold" aria-hidden="true"></i></Button>
    <Button
    block
    outline
    active={styleSelected.fontStyle === 'italic'}
    onClick={() => handleStyle('style','italic')}
    color={styleSelected.fontStyle === 'italic' ? "primary" : ""}
    >
    <i className="fa fa-italic" aria-hidden="true"></i>
    </Button>
    <Button
    block
    outline
    active={styleSelected.textDecoration === 'underline'}
    color={styleSelected.textDecoration === 'underline' ? "primary" : ""}
    onClick={() => handleStyle('style','underline')}
    >
    <i className="fa fa-underline" aria-hidden="true"></i>
    </Button>
  </ButtonGroup>
  </div>
  <br/>
  <div className='w-100 text-left'>
  <label className='text-left text-primary'>Font Size</label>
  <div
  className='w-100 pl-2 pr-2'
  >
  <RangeSlider
className='w-100'
  value={styleSelected.fontSize}
  onChange={changeEvent => handleStyle('size', changeEvent.target.value)}
  tooltip="off"
  min={10}
  max={100}
  step={10}
/>
</div>
</div>
<br/>
<div className='w-100 text-left justify-center align-items-center'>
<label className='text-left text-primary'>Text Color</label>
<div
className='w-100'
>
<BlockPicker 
triangle="hide"
width="100%"
color={styleSelected.fill}
onChange={(color, event) => handleStyle('color', color.hex)}
/>
</div>
</div>
    </div>
  )
}
