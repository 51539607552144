import React, { useEffect, useRef, useState } from "react";
import '../../../css/PDFModal.css'; // Import your CSS file
import { Button, Input } from "reactstrap";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import styles from 'css/styles.module.css';

import { uploadDocxLetter } from "../../../redux/actions/data.action";
import { fileUpload, getFile } from "redux/actions/file.action";
import documentService from "Utils/document.service";
import { baseToFormData } from "Utils/helpers";
import { env_vars } from "Utils/config";
import CustomModal from "component/Modals/CustomModal";
import CircularProgress from '@mui/material/CircularProgress';



const LetterTemplate = ({ title }) => {
	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch();
	let { template } = location.state ? location.state : {};
	// const [rnd, setRnd] = useState(0);
	const [fileModal, setFileModal] = useState(false);
	const [urlContent, setUrlContent] = useState(`${env_vars.editor_api}/editor?fileExt=docx`);
	const [activeFile, setActiveFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);
	
	const fileInputRef = useRef(null);

	const handleButtonClick = () => {
		// Trigger file input click
		fileInputRef.current.click();
	};

	const handleLoad = (e) => {

		let file = e.target.files[0]
		setUrlContent('')
		if (activeFile) {
			documentService.deleteFile(activeFile)
		}


		if (file) {


			const formData = new FormData();
			formData.append('uploadedFile', file, activeFile);

			dispatch(uploadDocxLetter(formData))
				.then((response) => {
					let { filename } = response.data;
					//   handleGetTemplate(template)
					setUrlContent(`${env_vars.editor_api}/editor?fileName=${filename}&directUrl=true`)
					// setRnd(Math.random())
					// Handle success if needed
				})
				.catch((error) => {
					console.error('Error uploading file:', error);
					// Handle error if needed
				});
		} else {
			console.warn('No file selected for upload.');
			// Handle no file selected if needed
		}
	};




	const handleSave = async (val) => {
		// setUrlContent(null);
		setLoadingSave(true)
		let file = await baseToFormData(`${env_vars.editor_api}/download?fileName=${activeFile}`, activeFile);
		const formData = new FormData();
		formData.append('file', file, activeFile);
		dispatch(fileUpload(formData, '/Templates'))
			.then((res) => {
				console.log(res, 'res')
				setLoadingSave(false)
				// getItems(e.destinationDirectory)
			})
			.catch(err => {
				setLoadingSave(false)
				console.log(err)
			})
	}




	const handleActiveFile = async (val) => {
		setLoading(true)
		let actv = await dispatch(getFile('filePath', `/Templates/${val}`))
		let file = null;


		await documentService.deleteFile(val)
		if (!actv) {
			file = await baseToFormData(`${env_vars.editor_api}/download?fileName=new.docx`, 'new.docx');
		} else {
			file = await baseToFormData(`${env_vars.api_url}/files/download/filePath?filePath=/Templates/${val}`, val);
		}
		const formData = new FormData();
		formData.append('uploadedFile', file, val);
		dispatch(uploadDocxLetter(formData))
			.then((response) => {
				let { filename } = response.data;
				// setActiveFile(filename)
				setLoading(false)
				setUrlContent(`${env_vars.editor_api}/editor?fileName=${filename}&directUrl=true`)
			})
			.catch(err => {
				setLoading(false)
				console.log(err)
			})


	}


	const handleExit = () => {
		documentService.deleteFile(activeFile)
		setActiveFile(null)
		navigate(-1)
	}

	useEffect(() => {
		if (activeFile) {
			handleActiveFile(activeFile);
		}

		return () => {
			if (activeFile) {
				documentService.deleteFile(activeFile)
			}
		}
	}, [activeFile])


	useEffect(() => {

		if (template) {
			setActiveFile(`${String(template).trim().replace(/[ &><%\/\\|\[\]{}()*]/g, "_")}.docx`)
		}


		return () => {
			setFileModal(false);
			setActiveFile(null)
		}
	}, [])

	// useEffect(() => {
	// 	console.log('trerender')
	// 	componentRef.current = componentRef.current
	// }, [rnd])



	return (
		<>
			<CustomModal


			>
				<div className="form-group center pl-3">
					<label className="control-label col-md-5 text-left">Hospital Number</label>
					<input type="text" id="HospitalNumber" className="col-md-7 form-control" placeholder="Enter Hospital Number"
						// style={{ width: '300px' }}
						value={activeFile}
						onChange={(e) => setActiveFile(e.target.value)}

					/>
				</div>
			</CustomModal>
			<div className="card-body">
			<div className="wrapper">
			{loading ? 
				<CircularProgress />
		  : urlContent &&
					<iframe
						title="Embedded Website"
						src={urlContent}
						width="85%"
						height="550px"
						maxHeight="100%"
						frameBorder="0"
						allowFullScreen
					></iframe>
				}
				</div>

				<div className="d-flex w-100 justify-content-end pdf-actions">
					<input
						type="file"
						ref={fileInputRef}
						onChange={handleLoad}
						style={{ display: 'none' }}
					/>
					<Button className="btn btnIri mr-3" onClick={handleButtonClick}>
						Load
					</Button>
					<Button className="btn  mr-3" disabled={loadingSave} onClick={() => handleSave(urlContent)}>Save</Button>
					<Button className="btn btnIri mr-3" onClick={handleExit}>Exit</Button>
				</div>
			</div>
		</>

	);
};

export default LetterTemplate;
