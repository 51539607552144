import React, { useEffect, useRef, useState } from "react";
import '../../../../css/PDFModal.css'; // Import your CSS file
import { Button, Input } from "reactstrap";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { generateLetterTemplate, parseFileTemplate, uploadDocxLetter } from "../../../../redux/actions/data.action";
import { fileUpload, getFile } from "redux/actions/file.action";
import documentService from "Utils/document.service";
import { baseToFormData, formatReportData } from "Utils/helpers";
import { env_vars } from "Utils/config";
import CustomModal from "component/Modals/CustomModal";
import CircularProgress from '@mui/material/CircularProgress';


const QarcGenerateLetter = ({ title }) => {
	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const { user } = useSelector(({ auth }) => auth);


	// const [rnd, setRnd] = useState(0);
	const [fileModal, setFileModal] = useState(false);
	const [urlContent, setUrlContent] = useState(null);
	const [activeFile, setActiveFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingPrint, setLoadingPrint] = useState(false);





	const handlePrint = async () => {
		// setUrlContent(null);
		setLoadingPrint(true)
		let tmpFile = `Individual_Colposcopy_Request`;
		let upFile = `${tmpFile}_${moment().format('MMDDYYYY')}.xlsx`;



		let file = await baseToFormData(`${env_vars.editor_api}/download?fileName=${upFile}`, upFile);
		const formData = new FormData();
		formData.append('file', file, upFile);
		//  let response = await dispatch(parseFileTemplate(formData, { format: 'xlsx', download: true}))
		 	// console.log(response, 'RESPE')
		 	// let { fileUrl} = response.data;
			 window.open(`${env_vars.editor_api}/download?fileName=${upFile}`, '_blank')


		 dispatch(fileUpload(formData, `/Letters/Qarc`))
		 	.then((res) => {
				 setLoadingPrint(false)
		 	})
			.catch(err => {
		 		setLoadingPrint(false)
		 		console.log(err)
		 	})
	}




	const handleActiveFile = async () => {
		setLoading(true)
		let tmpFile = `Individual_Colposcopy_Request`;
		let upFile = `${tmpFile}_${moment().format('MMDDYYYY')}.xlsx`;
		let val = `${tmpFile}.xlsx`;

		let actv = await dispatch(getFile('filePath', `/Templates/${val}`))
		let file = null;

		await documentService.deleteFile(val)
		await documentService.deleteFile(upFile)

		if (!actv) {
			file = await baseToFormData(`${env_vars.editor_api}/download?fileName=new.xlsx`, 'new.xlsx');
		} else {
			file = await baseToFormData(`${env_vars.api_url}/files/download/filePath?filePath=/Templates/${val}`, val);
		}

		const formData = new FormData();
		formData.append('uploadedFile', file, upFile);

		// let file2 = await dispatch(parseFileTemplate(formData, { parse: true }))
		// let { letterTemplate } = file2.data;
	


		dispatch(uploadDocxLetter(formData))
			.then((response) => {
				let { filename } = response.data;
				// setActiveFile(filename)
				setUrlContent(`${env_vars.editor_api}/editor?fileName=${filename}&directUrl=true`)
				setLoading(false)
			})
			.catch(err => {
			setLoading(false)
				console.log(err)
			}) 




	}


	const handleExit = () => {
		navigate(-1)
	}

	useEffect(() => {
		if (activeFile) {
			handleActiveFile(activeFile);
		}

		return () => {
			documentService.deleteFile(activeFile)
			setFileModal(false)
		}
	}, [activeFile])


	useEffect(() => {
		let tmpFile = `Individual_Colposcopy_Request`;
		let upFile = `${tmpFile}_${moment().format('MMDDYYYY')}.xlsx`;
			setActiveFile(upFile)
		return () => {
			documentService.deleteFile(activeFile)
			setFileModal(false)
		}
	}, [])

	// useEffect(() => {
	// 	console.log('trerender')
	// 	componentRef.current = componentRef.current
	// }, [rnd])



	return (
		<>
		

			<div className="card-body">
				<div className="wrapper">
				{loading ? 
				      <CircularProgress />
				: urlContent &&
					<iframe
						title="Embedded Website"
						src={urlContent}
						width="95%"
						height="550px"
						maxHeight="100%"
						frameBorder="0"
						allowFullScreen
					></iframe>
				}
				</div>
				<div className="d-flex w-100 justify-content-end pdf-actions">
					<Button className="btn btnIri mr-3" disabled={loadingPrint || loading} onClick={handlePrint}>
						Download
					</Button>
					<Button className="btn btnIri mr-3" onClick={handleExit}>Exit</Button>
				</div>
			</div>
		</>

	);
};

export default QarcGenerateLetter;
