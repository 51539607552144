import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { searchPatientDetails } from 'redux/actions/data.action';
import { CLOSE_MODAL, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS } from 'redux/actions/types';

// Utility function to create data structure for failsafe data
const createData = (title, data) => ({ title, data });

// Styled components for the table layout
const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  backgroundColor: theme.palette.grey[300],
  borderWidth: 'thin',
  overflow: 'auto',
  maxHeight: '20vh',
  width: '100%'
}));

const GridItemHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.black,
  fontWeight: 'bold',
  fontSize: 14,
  padding: '2px',
  textAlign: 'center',
  border: '1px solid #000',
}));

const GridItem = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  color: theme.palette.common.black,
  fontSize: 14,
  padding: '5px',
  textAlign: 'center',
  border: '1px solid #000',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.selected': {
    backgroundColor: '#c46977',
    color: '#fff'
  },
}));

const GridContentContainer = styled('div')(() => ({
  display: 'contents', // Allows children to take up grid cell space without introducing a wrapper element
}));

// Main FailSafeTable component
const FailSafeTable = ({ failSafeData, setSelectedRow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedFailsafe, setSelectedFailsafe] = useState(null);
  const [clickTimeout, setClickTimeout] = useState(null);

  // Handle click on a row to select or deselect
  const handleRowClick = (data) => {
    setSelectedFailsafe(data);
  };

  // Handle submission of patient details for search
  const handleSubmit = (vals) => {
    if (!vals) {
      return;
    }
    dispatch(searchPatientDetails(vals)).then((res) => {
      if (res && res.length !== 0) {
        if (res.length === 1) {
          dispatch({ type: SET_PATIENT_DETAILS, payload: res[0] });
          if (res[0].tbl_PersonalReferralDetails) {
            dispatch({
              type: SET_PERSONAL_REFERRAL_DETAILS,
              payload: res[0].tbl_PersonalReferralDetails[res[0].tbl_PersonalReferralDetails.length - 1],
            });
          }
          navigate('/dashboard/viewPatient');
        }
        dispatch({ type: CLOSE_MODAL });
      }
    });
  };

  // Handle click event with delay for distinguishing between single and double click
  const handleClick = (data) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleSubmit(data);
    } else {
      setClickTimeout(
        setTimeout(() => {
          setClickTimeout(null);
          if (selectedFailsafe === data) {
            setSelectedRow(null);
            setSelectedFailsafe(null);
          } else {
            setSelectedRow(data);
            handleRowClick(data);
          }
        }, 250)
      );
    }
  };

  // Render the failsafe data in a grid layout
  const renderFailSafeData = (list, data) => {
    const filteredData = data.filter((subItem) => subItem.Failsafe);

    return (
      <Col key={list.title} xs={12} md={6} className="mb-4">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <h5 className="text-primary">{list.title}</h5>
        </div>
        <GridContainer>
          <GridItemHeader>Patient Name</GridItemHeader>
          <GridItemHeader>Hospital Number</GridItemHeader>
          <GridItemHeader>Fail Safe</GridItemHeader>
          <GridItemHeader>Treatment Date</GridItemHeader>
          {filteredData.length > 0 ? (
            filteredData.map((subItem, subIndex) => (
              <GridContentContainer key={subIndex}>
                <GridItem
                  className={selectedFailsafe === subItem ? 'selected' : ''}
                  onClick={() => { console.log("Clicked row:", subItem); handleClick(subItem) }}
                >
                  {`${subItem.Forename} ${subItem.Surname}`}
                </GridItem>
                <GridItem
                  className={selectedFailsafe === subItem ? 'selected' : ''}
                  onClick={() => handleClick(subItem)}
                >
                  {subItem.HospitalNumber}
                </GridItem>
                <GridItem
                  className={selectedFailsafe === subItem ? 'selected' : ''}
                  onClick={() => handleClick(subItem)}
                >
                  {subItem.Failsafe}
                </GridItem>
                <GridItem
                  className={selectedFailsafe === subItem ? 'selected' : ''}
                  onClick={() => handleClick(subItem)}
                >
                  {subItem.TreatmentDate}
                </GridItem>
              </GridContentContainer>
            ))
          ) : (
            <GridItem style={{ gridColumn: 'span 4', height: '11vh', width: '100%' }}>
              No available data for {list.title}
            </GridItem>
          )}
        </GridContainer>
      </Col>
    );
  };

  return (
    <Row className="rows">
      {renderFailSafeData(createData('No Biopsy result after 14 days', failSafeData.failsafeData1), failSafeData.failsafeData1)}
      {renderFailSafeData(createData('No treatment 6 weeks after CIN biopsy', failSafeData.failsafeData4), failSafeData.failsafeData4)}
      {renderFailSafeData(createData('No smear result after 4 weeks', failSafeData.failsafeData2), failSafeData.failsafeData2)}
      {renderFailSafeData(createData('Late GA Treatment', failSafeData.failsafeData5), failSafeData.failsafeData5)}
      {renderFailSafeData(createData('No swab results after 14 days', failSafeData.failsafeData3), failSafeData.failsafeData3)}
      {renderFailSafeData(createData('DNA not rescheduled', failSafeData.failsafeData6), failSafeData.failsafeData6)}
    </Row>
  );
};

export default FailSafeTable;
