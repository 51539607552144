
import { CLEAR_PATIENT_DETAILS, FETCH_FAILSAFE_DATA_FAILURE, FETCH_PERSONAL_DETAILS_FAILURE, LOGOUT_USER, SESSION_EXPIRED, SET_DATA, SET_FAILSAFE_DATA, SET_PERSONAL_DETAILS, SET_PERSONAL_REFERRAL_DETAILS, SET_SMEAR_RESULTS, SET_TREATMENT_DETAILS, SET_FAILSAFE_DATA_1, SET_FAILSAFE_DATA_2, SET_FAILSAFE_DATA_3, SET_FAILSAFE_DATA_4, SET_FAILSAFE_DATA_5, SET_FAILSAFE_DATA_6 } from "./types";

import axios from "axios";
import { env_vars } from '../../Utils/config';
import { checkAuth, removeUserSession } from "../../Utils/commonData";
import documentService from "Utils/document.service";

export const searchPatientDetails = (props) => dispatch => {
  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })

  dispatch({ type: CLEAR_PATIENT_DETAILS })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_PersonalDetails?${str}`)
    .then(res => {
      if (res.data.length !== 0) {
        dispatch({ type: SET_DATA, payload: res.data })
      } else {
        dispatch({ type: SET_DATA, payload: [] })
      }
      return res.data;
    }).catch(err => {
      if (!checkAuth(err)) {
        removeUserSession()
        dispatch({ type: LOGOUT_USER })
        window.location = '/login?session=expired'
      };
      console.log(err)
      return null
    })
};

export const createPatientDetails = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_PersonalDetails`, props)
};


export const createExaminationDetails = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_CervixExamination`, props)
};


export const createTreatmentDetails = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_CervixTreatment?ExaminationID=${props.ExaminationID}`, props)
};

export const createSmearResultDetails = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_CervixExaminationResults?ExaminationID=${props.ExaminationID}`, props)
};


export const getSmearResultDetailsById = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_CervixExaminationResults/${props}`)
};

export const getPatientDetailsById = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_PersonalDetails/${props}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      let { SystemID } = res.data;
      dispatch(getPersonalReferralDetailsById(SystemID))
      .then(a => {  
        dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: a.data })
      }).catch(err => {
        console.log(err)
      })
      return res.data;
    }).catch(err => {
      /*   if (!checkAuth(err)) {
          removeUserSession()
          dispatch({ type: LOGOUT_USER })
          window.location = '/login?session=expired'
        }; */
      dispatch({ type: SET_DATA, payload: [] })
      return null
    })
};


export const getCervixExaminations = (props) => dispatch => {

  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_CervixExamination?${str}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      // dispatch({ type: SET_EXAMINATIONS, payload: res.data })

      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getCervixExaminationById = (id) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_CervixExamination/${id}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};



export const getUserDetails = (props = {}) => dispatch => {

  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_UserDetails?${str}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};


export const getQarcUsers = (props = {}) => dispatch => {


  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_UserDetails/qarcusers`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};


export const getStatisticsColposcopists = (siteID, clinic) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/statistics/colposcopists?sCurrentSiteID=${siteID}&sSystemClinic=${clinic}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getStatisticsColposcopistsCases = (siteID, start, end) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/statistics/getCountColposcopistCases?iSiteID=${siteID}&dtStartDate=${start}&dtEndDate=${end}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};


export const getCervixExaminationDetailsById = (id) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_CervixExaminationDetails/${id}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getCervixTreatmentDetailsById = (id) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_CervixExaminationDetails/${id}`)
    .then(res => {
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getPersonalReferralDetailsById = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_PersonalReferralDetails/${props}`).then(a => {
    return a
  })
};



//Referral Details
export const updatePatientDetails = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.put(`${env_vars.api_url}/tbl_PersonalDetails/${props.SystemID}`, props)
};


export const updatePersonalReferralDetailsById = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.put(`${env_vars.api_url}/tbl_PersonalReferralDetails/${props.ReferralID}`, props)
};

//Update inUseBy
export const updateInUseBy = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_PersonalDetails/InUseBy/${props}`)
    .catch(err => {
      if (!checkAuth(err)) {
        removeUserSession()
        dispatch({ type: LOGOUT_USER })
        window.location = '/login?session=expired'
      };
    })
};


export const uploadFile = (data) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_CervixExamination/file-upload`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

export const uploadFiles = (data, ExaminationID) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_CervixExamination/files-upload?examination=${ExaminationID}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};

export const uploadImages = (data, ExaminationID) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_CervixExamination/images-upload?examination=${ExaminationID}`, data)
};



export const getRefHpvSmearTest = (data) => dispatch => {
  //  dispatch(fetchStart());
  let str = '';
  Object.entries(data).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })


  return axios.post(`${env_vars.api_url}/tbl_RefHPVSmearTest`, data)
};


export const generateLetterTemplate = (data) => dispatch => {
  //  dispatch(fetchStart());

  return axios.post(`${env_vars.api_url}/document/generate`, data)
};

export const uploadLetterTemplate = (template, data) => dispatch => {
  //  dispatch(fetchStart());

  return axios.post(`${env_vars.editor_api}/upload`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadDocxLetter = (data) => dispatch => {
  //  dispatch(fetchStart());

  return documentService.uploadFile(data);
};

export const getLetterTemplate = (template) => dispatch => {
  //  dispatch(fetchStart());

  return axios.get(`${env_vars.api_url}/document/template?letterTemplate=${template}`);
};

export const parseFileTemplate = (formData, data) => async dispatch => {
  let str = '';
  Object.entries(data).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })

  let option = data.download ? { responseType: 'blob' } : {}

  return await axios
    .post(`${env_vars.api_url}/document/template-parser?${str}`, formData, option)


}


export const getQarcStats = (props = {}) => dispatch => {
  //  dispatch(fetchStart());
  
  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/statistics/qarc?${str}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getAllPersonalDetails = (data) => async dispatch => {
  console.log('Fetching all personal details...');

  axios.get(`${env_vars.api_url}/tbl_PersonalDetails`, data) // Replace tbl_PersonalDetails with your API endpoint
    .then(res => {
      console.log('Fetched personal details:', res.data);
      dispatch({
        type: SET_PERSONAL_DETAILS,
        payload: res.data
      });
    })
    .catch(err => {
      console.error('Failed to fetch personal details:', err);
      dispatch({
        type: FETCH_PERSONAL_DETAILS_FAILURE,
        payload: err.message || 'Failed to fetch personal details'
      });
    });
};

export const getLocalNetwork = () => dispatch => {

  return axios.get(`${env_vars.api_url}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};


export const getFailSafeDetails = (siteID, start, end, colposcopist) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailSafeDetails?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};

export const getFailSafeDetails_1 = (siteID, start, end, colposcopist) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailsafeDetails_1?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA_1, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};

export const getFailSafeDetails_2 = (siteID, start, end, colposcopist) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailsafeDetails_2?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA_2, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};

export const getFailSafeDetails_3 = (siteID, start, end, colposcopist) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailsafeDetails_3?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA_3, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};

export const getFailSafeDetails_4 = (siteID, start, end, colposcopist) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailsafeDetails_4?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA_4, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};

export const getFailSafeDetails_5 = (siteID, start, end, ) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailsafeDetails_5?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA_5, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};
 
export const getFailSafeDetails_6 = (siteID, start, end, colposcopist) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/failsafe/getFailsafeDetails_6?iSiteID=1&dtStartDate=2000-01-01&dtEndDate=2024-01-01&colposcopist=Ann%20Brown`)
    .then(res => {
      dispatch({ type: SET_FAILSAFE_DATA_6, payload: res.data });
      return res.data;
    }).catch(err => {
      console.log(err);
      return null;
    });
};

