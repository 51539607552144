import moment from "moment";
import { CLEAR_EXAMINATION,  CLEAR_EXAMINATIONS, CLEAR_EXAMINATION_DETAILS, CLEAR_PATIENT_DETAILS, SET_EXAMINATION, SET_EXAMINATIONS, SET_EXAMINATION_DETAILS,  SET_LAST_EXAMINATION } from "../actions/types";


const INIT_EXAMINATION_DETAILS = {
  ExaminationDate: moment().format('YYYY-MM-DD'),
  DetailsLetterSummary: '...Colposcopy examination revealed no abnormality.',
  Contraception: 'As before',
  AcetoWhite: false,
  CervixPresent: false,
  OpinionCervical: '',
  OpinionVaginal: 'No Opinion',
  tbl_UserDetails: {}
}

const INIT_STATE = {
   examination: {},
   examinations: [],
   examinationDetails: INIT_EXAMINATION_DETAILS,
   lastExamination: {}
};

export default (state = INIT_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case CLEAR_PATIENT_DETAILS: {
      return {
        ...state,
        examinationDetails: INIT_EXAMINATION_DETAILS,
        examination: {},
        examinations: [],
        lastExamination: {}
      };
    }
    case SET_EXAMINATION_DETAILS: {
      return {
        ...state,
        examinationDetails: payload,
      };
    }

    case CLEAR_EXAMINATION_DETAILS: {
      return {
        ...state,
        examinationDetails: INIT_EXAMINATION_DETAILS,
        examination: {},
        examinations: []
      };
    }

    case SET_EXAMINATION: {
      return {
        ...state,
        examination: payload,
      };
    }

    case SET_LAST_EXAMINATION: {
      return {
        ...state,
        lastExamination: payload,
      };
    }

    case CLEAR_EXAMINATION: {
      return {
        ...state,
        examination: {},
        lastExamination: {},
        examinationDetails: {}
      };
    }

    case SET_EXAMINATIONS: {
      return {
        ...state,
        examinations: payload
      };
    }

    case CLEAR_EXAMINATIONS: {
      return {
        ...state,
        examinations: [],
        lastExamination: {}
      };
    }
    
    default:
        return state;
};
}
