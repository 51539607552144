import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import FailsafeListModal from 'component/FailSafePrintModal';
import { getFailSafeDetails_1, getFailSafeDetails_2, getFailSafeDetails_3, getFailSafeDetails_4, getFailSafeDetails_5, getFailSafeDetails_6 } from 'redux/actions/data.action';
import FailSafeTable from 'component/FailSafeList/FailSafeTable';
import { searchPatientDetails } from 'redux/actions/data.action';
import { CLOSE_MODAL, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS } from 'redux/actions/types';

const Failsafe = () => {
  const navigate = useNavigate();
  const [selectedFailsafeItems, setSelectedFailsafeItems] = useState([]); // State to track selected Failsafe items
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedRow, setSelectedRow] = useState(null); // State to track the selected row
  const dispatch = useDispatch();
  const failSafeData = useSelector(({ data }) => data);

  // Handle review button click to submit selected row data
  const handleReview = () => {
    if (selectedRow) {
      handleSubmit(selectedRow);
    } else {
      console.log(selectedRow, "No row selected");
    }
  };

  // Handle submission of patient details for search
  const handleSubmit = (vals) => {
    if (!vals) {
      return;
    }
    dispatch(searchPatientDetails(vals)).then((res) => {
      if (res && res.length !== 0) {
        if (res.length === 1) {
          dispatch({ type: SET_PATIENT_DETAILS, payload: res[0] });
          if (res[0].tbl_PersonalReferralDetails) {
            dispatch({
              type: SET_PERSONAL_REFERRAL_DETAILS,
              payload: res[0].tbl_PersonalReferralDetails[res[0].tbl_PersonalReferralDetails.length - 1],
            });
          }
          navigate('/dashboard/viewPatient');
        }
        dispatch({ type: CLOSE_MODAL });
      }
    });
  };

  // Handle print button click to show print modal
  const handlePrint = () => {
    setShowModal(true); // Show modal when Print button is clicked
  };

  // Handle exit button click to navigate to home dashboard
  const handleExit = () => {
    navigate('/dashboard/home');
  };

  // Fetch all failsafe lists when component mounts
  const handleGetFailSafeLists = () => {
    dispatch(getFailSafeDetails_1());
    dispatch(getFailSafeDetails_2());
    dispatch(getFailSafeDetails_3());
    dispatch(getFailSafeDetails_4());
    dispatch(getFailSafeDetails_5());
    dispatch(getFailSafeDetails_6());
  };

  // useEffect to fetch failsafe lists on component mount
  useEffect(() => {
    handleGetFailSafeLists();
  }, []);

  return (
    <>
      <div className="card-header bg-iri w-100">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <h4 className="text-white">FailSafe Details</h4>
        </div>
      </div>
      <div className="card-body"
        style={{ backgroundColor: '#E0E0E0', display: 'flex', flexDirection: 'column' }}
      >
        <FailSafeTable failSafeData={failSafeData} setSelectedRow={setSelectedRow} />
        <div className="text-end mt-3">
          <Button className="btn btnIri marRight10"
            onClick={handleReview}
          >
            Review
          </Button>
          <Button className="btn btnIri marRight10"
            onClick={handlePrint}
          >
            Print
          </Button>
          <Button className="btn btnIri marRight10"
            onClick={handleExit}
          >
            Exit
          </Button>
        </div>
      </div>

      <FailsafeListModal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        selectedItems={selectedFailsafeItems}
        setSelectedItems={setSelectedFailsafeItems}
      />
    </>
  );
};

export default Failsafe;
