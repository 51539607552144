import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import ImageViewer from "react-simple-image-viewer";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useEffect } from 'react';
import { Typography } from '@mui/material';

const AutoPlaySwipeableViews = SwipeableViews;

// const images = [
//   {
//     label: 'San Francisco – Oakland Bay Bridge, United States',
//     imgPath:
//       'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
//   },
//   {
//     label: 'Bird',
//     imgPath:
//       'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
//   },
//   {
//     label: 'Bali, Indonesia',
//     imgPath:
//       'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
//   },
//   {
//     label: 'Goč, Serbia',
//     imgPath:
//       'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
//   },
// ];

function SwipeableTextMobileStepper({images}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(images.length - 1);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const maxSteps = images.length;
  console.log(images, 'images')
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    setActiveStep(images.length - 1)
    
  }, [images])
console.log(images, "IMAGES")
  return (
    <Box sx={{ flexGrow: 1 }}>
    
    {images.length !== 0 &&
    <>
    {isViewerOpen && (
      <ImageViewer
        src={images.map(img => { return  img.imgPath })}
        currentIndex={activeStep}
        onClose={closeImageViewer}
        disableScroll={false}
        backgroundStyle={{
          backgroundColor: "rgba(0,0,0,0.9)"
        }}
        closeOnClickOutside={true}
      />
    )}
    <div className='fullscrn-container' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoPlay={false}
      >

        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: '100%',
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                  bgcolor: 'white'
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Button 
      onClick={() => setIsViewerOpen(true)}
      className='btnIri w-50 p-2 clickable fullscrn'
      style={{position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}
      
    >
   <Typography> Full Screen</Typography> <FullscreenIcon/>
    </Button>
      </div>

      <MobileStepper
        steps={maxSteps}
        position="static"
        sx={{
          bgcolor: 'white'
        }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </>
      
    }
    </Box>
  );
}

export default SwipeableTextMobileStepper;
