
import io from 'socket.io-client';
import {env_vars} from './config';



  let socket;
  export const initiateSocketConnection = (token) => {
  console.log('CONSOLE', token)
    socket = io(env_vars.host, {
      auth: {
        token
      },
      transports: [ "websocket" ]
    });
    

    

  }



  export const disconnectSocket = () => {

    if(socket) socket.disconnect();
   
  }


  // Handle message receive event
  export const subscribeToStream = (cb) => {
    if (!socket) return(true);
    socket.on('set-stream', msg => {
      return cb(null, msg);
    });
  }



































//   useEffect(() => {

  
//     // if(isAuthUser){




  
//     socket.on('betting', (betting) => {
//       dispatch({type: SET_BETS, payload: betting})
//     });
  
//     socket.on('timelapse', (val) => {
//       dispatch({type: SET_TIMELAPSE, payload: val})
//     });
    
    
//     socket.on('event-update', (val) => {
//       let { fightNo, fightStatus, results, win} = val; 

//       dispatch({type: SET_FIGHT, payload: {...currentFight, fightStatus: fightStatus, fightNo, win }})
//       dispatch({type: SET_RESULTS, payload: results})

//       // window.location.href = '/';
//   });

  
//     socket.on('event-end', () => {
//         window.location.href = '/';
//     });
  
  
  
//     socket.on('stop-streaming', (streaming) => {
//       dispatch({type: STOP_STREAMING})
//       // props.history.push('/home')
//       // window.location.href = '/home';
//     });
  
//     socket.on('disconnect', () => {
//       dispatch({type: STOP_STREAMING})
//     });
    
//   // }

//     return () => {
    
//       // socket.disconnect();
//       // socket.off('connect');
//       // socket.off('disconnect');
//       // socket.off('streaming');
//       // socket.off('betting');
//       // socket.off('get-balance');
//       // socket.off('event-end');
//       // socket.off('stop-streaming');

//     };
    
//   }, [])
  