import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATIONS, CLEAR_LAST_EXAMINATION, CLEAR_PATIENT_DETAILS, CLEAR_PERSONAL_REFERRAL_DETAILS, CLEAR_REFERRAL_REASONS, OPEN_MODAL, SET_EXAMINATION, SET_EXAMINATIONS, SET_GRID_VIEW, SET_LAST_EXAMINATION, SET_PERSONAL_REFERRAL_DETAILS, SET_PERSONAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from '../../../redux/actions/types';
import Table from 'react-bootstrap/Table';
import { getCervixExaminations, getPersonalReferralDetailsById } from '../../../redux/actions/data.action';
import moment from 'moment';
import Logo from '../../../assets/logo.png';
import { Grid } from '@mui/material';


const StatisticsOptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { patientDetails, personalReferralDetails } = useSelector(({ data }) => data)
    const { viewOption } = useSelector(({ ui }) => ui)

    const [exam, setExam] = useState(null);
    const [examDates, setExamDates] = useState([])

    const handleExit = () => {
        dispatch({ type: CLEAR_PATIENT_DETAILS });
        dispatch({ type: CLEAR_PERSONAL_REFERRAL_DETAILS });
        dispatch({ type: CLEAR_REFERRAL_REASONS });

        localStorage.removeItem("formDetails")
        navigate('/dashboard/home')
    }


    const handleEnterExamination = () => {
        dispatch({ type: CLEAR_EXAMINATIONS });
        dispatch({type: CLEAR_LAST_EXAMINATION})

        if (!personalReferralDetails) {
            return dispatch({ type: OPEN_MODAL, payload: 'noAppointmentModal' })
        }

        if (examDates.length !== 0 && examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID) > 0) {
            let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)

            dispatch({ type: SET_EXAMINATIONS, payload: examDates })
            dispatch({ type: SET_EXAMINATION, payload: exam })
            dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1] })
            navigate('/dashboard/viewPatient/casereview')
        } else {
            if (exam) {
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
            }
            navigate('/dashboard/viewPatient/examination')
        }
    }


    const handleEnterManagement = () => {
        dispatch({ type: CLEAR_EXAMINATIONS });
        if (examDates.length !== 0 && examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID) > 0) {
            let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)
            dispatch({ type: SET_EXAMINATION, payload: exam })
            dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1] })
            navigate('/dashboard/viewPatient/management')
        } else {
            if (exam) {
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
            }
            navigate('/dashboard/viewPatient/management')
        }
    }



    const handleImagingReview = () => {
        dispatch({ type: CLEAR_EXAMINATIONS });



        if (examDates.length !== 0 && examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID) > 0) {
            let ind = examDates.map(a => { return a.ExaminationID }).indexOf(exam.ExaminationID)

            dispatch({ type: SET_EXAMINATIONS, payload: examDates })
            dispatch({ type: SET_EXAMINATION, payload: exam })
            dispatch({ type: SET_LAST_EXAMINATION, payload: examDates[ind - 1] })
            navigate('/dashboard/viewPatient/colposcopicImagingReview')
        } else {
            if (exam) {
                dispatch({ type: SET_EXAMINATIONS, payload: examDates })
                dispatch({ type: SET_EXAMINATION, payload: exam })
            }
            navigate('/dashboard/viewPatient/colposcopicImagingReview')
        }
    }







    return (
        <>
            <div className="card-header bg-iri w-100">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                    }}
                >
                    <h4 className="text-white">Statistics Options</h4>
                </div>
            </div>
            <div className="card-body pt-5">
                        <Grid
                            container
                            spacing={2}
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Grid item lg="3"  sm="6" > <Button
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => {
                                dispatch({type: SET_GRID_VIEW, payload: false})
                                navigate('/dashboard/statistics/qarc')
                                }}
                            >QARC Audit</Button></Grid> 
                            <Grid item lg="9" xs="6"  sm="6" className='text-left'><h5 className='text-primary'>Annual Individual Colp Data</h5></Grid>
                        </Grid>
                        <br/>
                        <Grid
                        container
                        spacing={2}
                        style={{
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid item lg="3" sm="6" > <Button
                            className='btnIri'
                            style={{
                                height: 50,
                                width: 150
                            }}
                            onClick={() => {
                            dispatch({type: SET_GRID_VIEW, payload: false})
                            navigate('/dashboard/statistics/bsccp')}
                            }
                        >BSCCP</Button></Grid> <Grid item lg="9" xs="6"  sm="6" className='text-left'><h5 className='text-primary'>Colposcopist cases</h5></Grid>
                    </Grid>
                    <br/>
                    <Grid
                            container
                            spacing={2}
                            style={{
                                alignItems: 'flex-end',
                                justifyContent: 'center'
                            }}
                        >
                            <Grid item lg="3" xs="6"  sm="6" > <Button
                                className='btnIri'
                                style={{
                                    height: 50,
                                    width: 150
                                }}
                                onClick={() => {
                                dispatch({type: SET_GRID_VIEW, payload: false})
                                navigate('/dashboard/statistics/automatic')
                                }}
                            >Automatic</Button></Grid> <Grid item lg="9" xs="6"  sm="6" className='text-left'><h5 className='text-primary'>Automatic K65 Generation</h5></Grid>
                        </Grid>
                        <br/>

                        <Grid
                        container
                        spacing={2}
                        style={{
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid item lg="3" xs="6"  sm="6" > <Button
                            className='btnIri'
                            style={{
                                height: 50,
                                width: 150
                            }}
                            onClick={() => {
                            dispatch({type: SET_GRID_VIEW, payload: false})
                                navigate('/dashboard/statistics/manual')
                            }}
                        >Manual</Button></Grid> <Grid item lg="9" xs="6"  sm="6" className='text-left'><h5 className='text-primary'>Manual K65 Generation</h5></Grid>
                    </Grid>
                    <br/><br/>
                    <Grid
                    container
                    spacing={2}
                    style={{
                        alignItems: 'flex-end',
                        justifyContent: 'center'
                    }}
                >
                    <Grid item lg="3" xs="6"  sm="6" > <Button
                        style={{
                            height: 50,
                            width: 150
                        }}
                        onClick={() => handleExit()}
                    >Exit</Button></Grid> <Grid item lg="9" xs="6"  sm="6" className='text-left'><h5 className='text-primary'>Exit Statistics</h5></Grid>
                </Grid>
                <br/><br/>

            </div>
        </>
    )
}

export default StatisticsOptions;
