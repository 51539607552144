import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, getPersonalReferralDetailsById } from "../../../redux/actions/data.action";
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PERSONAL_REFERRAL_DETAILS, SET_PERSONAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from "../../../redux/actions/types";
import { Form, Row, Col, Input, Label, Button } from "reactstrap"




const ExaminationDetails = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let { examType } = useParams();
  const { viewOption, edited } = useSelector(({ ui }) => ui)
  const { patientDetails, personalReferralDetails } = useSelector(({ data }) => data)
  const { examination, examinationDetails } = useSelector(({ examination }) => examination)


  const handleChanges = prop => event => {
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }



    let newVal = { ...examinationDetails, [prop]: event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value }

    dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
    localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
    dispatch({ type: SET_EDITED, payload: 'examination' })
  }




  let handleReferralDetails = (id) => {
    dispatch(getPersonalReferralDetailsById(id))
      .then((res) => {
        let { tbl_PersonalReferralReason, tbl_PersonalAssociatedSymptom, tbl_personalrelevantdetail } = res.data;
        dispatch({ type: SET_PERSONAL_REFERRAL_DETAILS, payload: res.data })
        dispatch({ type: SET_REFERRAL_REASONS, payload: tbl_PersonalReferralReason })
        dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: tbl_PersonalAssociatedSymptom })
        dispatch({ type: SET_PERSONAL_RELEVANT_DETAILS, payload: tbl_personalrelevantdetail })

      })
      .catch(err => {
        console.log(err)
      })
  }






  const handleGetExamination = (id) => {
    dispatch(getCervixExaminationDetailsById(id))
      .then(res => {
        dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } })
      })
  }



  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('CLICKED!!')
    let { tbl_CervixManagement } = examination;
    // props.handleNext()
    console.log(examinationDetails.OpinionCervical, examType, 'CLICKKKED')
    if (!examinationDetails.OpinionCervical && examType !== 'NurseLedClinic' && examType !== 'CytologyOnly') {
      return
    }

    if (tbl_CervixManagement && tbl_CervixManagement.ManagementPlan === 'Treatment') {
      navigate(`${viewOption === "enter" ? "/dashboard/patient/results" : "/dashboard/viewPatient/results"}`)
      return
    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient/management" : "/dashboard/viewPatient/management"}`)

  };


  const handleBack = () => {
    console.log("BACKING")
    navigate(-1)
  };



  const handleExit = (id) => {
    if (edited.find(a => a === 'examination')) {
      dispatch({ type: OPEN_MODAL, payload: "save_details" })
      // return

    } else {
      dispatch({ type: CLEAR_EXAMINATION })
      dispatch({ type: CLEAR_EXAMINATION_DETAILS })
    }
    navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)
  }






  useEffect(() => {
    if (patientDetails && (patientDetails.NHSnumber || patientDetails.HospitalNumber)) {
      //   setPatient(patientDetails)
      if (personalReferralDetails && personalReferralDetails.ReferralID) {
        handleReferralDetails(personalReferralDetails.ReferralID)
      }
      if (examination && examination.ExaminationID) {
        handleGetExamination(examination.ExaminationID)
      }
    } else {
      navigate("/dashboard/home")
    }
  }, [patientDetails, examination])

  const NurseLedClinic = (
    <Row className="row" >
      <Col sm={2} className="mb-3"></Col>
      <Col sm={8} style={{ display: "flex", flexDirection: "column", justifyContent: "center", flexGrow: 1 }} >
        <div className="row">
          <div className="col-md-12">
            <div className="bordered">
              <h3 className="card-title bordered-title" >Cervix</h3>
              <div className="d-flex pl-2 pr-2 mt-2">
                <div className="col-md-6 text-center  mb-3">
                  <Input
                    type="radio" name="CervixPresent" className=""
                    id="CervixPresent"
                    value={true}
                    checked={examinationDetails.CervixPresent}
                    onChange={handleChanges('CervixPresent')}
                  />
                  {" "}
                  <Label htmlFor="CervixPresent">
                    Present
                  </Label>
                </div>
                <div className="col-md-6 text-center mb-3">
                  <Input
                    type="radio" name="CervixPresent" className=""
                    id="notpresent"
                    value={false}
                    checked={!examinationDetails.CervixPresent}
                    onChange={handleChanges('CervixPresent')}
                  />
                  {" "}
                  <Label htmlFor="notpresent">
                    Not Present
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row" >
          <div className="col-md-6 mb-3" >
            <div className="bordered pl-2 pr-2">
              <h3 className="card-title txtLeft bordered-title">Cytology</h3>
              <div className="custom-radio text-left pl-4 mb-1">
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ECTOSmearTaken" className=""
                    id="ECTOSmearTaken"
                    value={examinationDetails.ECTOSmearTaken ? false : true}
                    checked={examinationDetails.ECTOSmearTaken}
                    onChange={handleChanges('ECTOSmearTaken')}
                  />
                  {" "}
                  <Label htmlFor="ECTOSmearTaken">
                    Ecto Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="VaultSmearTaken" className=""
                    id="VaultSmearTaken"
                    value={examinationDetails.VaultSmearTaken ? false : true}
                    checked={examinationDetails.VaultSmearTaken}
                    onChange={handleChanges('VaultSmearTaken')}
                  />
                  {" "}
                  <Label htmlFor="VaultSmearTaken">
                    Vault Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ENDOSmearTaken" className=""
                    id="ENDOSmearTaken"
                    value={examinationDetails.ENDOSmearTaken ? false : true}
                    checked={examinationDetails.ENDOSmearTaken}
                    onChange={handleChanges('ENDOSmearTaken')}
                  />
                  {" "}
                  <Label htmlFor="ENDOSmearTaken">
                    Endo Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="HRHPVTyping" className=""
                    id="HRHPVTyping"
                    value={examinationDetails.HRHPVTyping ? false : true}
                    checked={examinationDetails.HRHPVTyping}
                    onChange={handleChanges('HRHPVTyping')}
                  />
                  {" "}
                  <Label htmlFor="HRHPVTyping">
                    HPV Testing
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3" >
            <div className="bordered pl-2 pr-2">
              <h3 className="card-title  bordered-title">Swab</h3>
              <div className="custom-radio text-left pl-4">
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="HVSSwabTaken" className=""
                    id="HVSSwabTaken"
                    value={examinationDetails.HVSSwabTaken ? false : true}
                    checked={examinationDetails.HVSSwabTaken}
                    onChange={handleChanges('HVSSwabTaken')}
                  />
                  {" "}
                  <Label htmlFor="HVSSwabTaken">
                    HVS Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ECSTaken" className=""
                    id="ECSTaken"
                    value={examinationDetails.ECSTaken ? false : true}
                    checked={examinationDetails.ECSTaken}
                    onChange={handleChanges('ECSTaken')}
                  />
                  {" "}
                  <Label htmlFor="ECSTaken">
                    ECS Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ChlamydiaU25" className=""
                    id="ChlamydiaU25"
                    value={examinationDetails.ChlamydiaU25 ? false : true}
                    checked={examinationDetails.ChlamydiaU25}
                    onChange={handleChanges('ChlamydiaU25')}
                  />
                  {" "}
                  <Label htmlFor="ChlamydiaU25">
                    Chlamydia U25
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ChlamydiaSwabTaken" className=""
                    id="ChlamydiaSwabTaken"
                    value={examinationDetails.ChlamydiaSwabTaken ? false : true}
                    checked={examinationDetails.ChlamydiaSwabTaken}
                    onChange={handleChanges('ChlamydiaSwabTaken')}
                  />
                  {" "}
                  <Label htmlFor="ChlamydiaSwabTaken">
                    Chlamydia Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="GUScreenSwabTaken" className=""
                    id="GUScreenSwabTaken"
                    value={examinationDetails.GUScreenSwabTaken ? false : true}
                    checked={examinationDetails.GUScreenSwabTaken}
                    onChange={handleChanges('GUScreenSwabTaken')}
                  />
                  {" "}
                  <Label htmlFor="GUScreenSwabTaken">
                    GUM Screen
                  </Label>
                  <div className="clearfix"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <br />
      </Col>
      <Col sm={2} className="mb-3"></Col>
    </Row >
  )


  const formDetails = (
    <>
      <Row className="row">
        <Col sm={4} className="mb-3">
          {examType !== "CytologyOnly" &&
            <div className="bordered pr-2 pl-2">
              <h3 className="card-title txtLeft bordered-title">Diagnostic Biopsies</h3>
              <Col xs={6} lg={6} className="bordered border-bottom-0 pl-2">
                <h3 className="card-title bordered-title ml-3  text-14">None</h3>
                <div className="text-left pl-5">
                  <Input type="checkbox" name="none-check" id="none-check" />
                  {" "}
                  <Label htmlFor="none-check">
                    None
                  </Label>
                </div>
              </Col>
              <div className="mt-2 bordered">
                <h3 className="card-title txtLeft bordered-title  text-14">Directed (punch) Biopsy</h3>
                <div className="d-flex pl-2 pr-2">
                  <div className="col-md-6 pl-5 text-left">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 pl-5 text-left">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Multiple Direct (punch) Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">

                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5 ">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
                <div className="d-flex pl-2 pr-2">
                  <div className="col-md-12 text-left pl-3 mb-3 mt-2">
                    <Label className="col-md-5 text-left" >Sites</Label>
                    <Input type="text" id="firstName" className="form-control col-md-7" placeholder="" required />
                  </div>
                </div>
              </div>
              <div className="mt-3 bordered">
                <h3 className="card-title bordered-title text-14 " >Wedge Biopsy (diagnostic loop) Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Excessional Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Endometrial Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Endometrial
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Polypectomy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">ECC</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      ECC
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <br />
            </div>
          }
        </Col>


        <Col sm={8} style={{ display: "flex", flexDirection: "column" }} >
          <div className="row" >
            <div className="col-md-6 mb-3" >
              <div className="bordered pl-2 pr-2">
                <h3 className="card-title txtLeft bordered-title">Cytology</h3>
                <div className="custom-radio text-left pl-4 mb-1">
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ECTOSmearTaken" className=""
                      id="ECTOSmearTaken"
                      value={examinationDetails.ECTOSmearTaken ? false : true}
                      checked={examinationDetails.ECTOSmearTaken}
                      onChange={handleChanges('ECTOSmearTaken')}
                    />
                    {" "}
                    <Label htmlFor="ECTOSmearTaken">
                      Ecto Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="VaultSmearTaken" className=""
                      id="VaultSmearTaken"
                      value={examinationDetails.VaultSmearTaken ? false : true}
                      checked={examinationDetails.VaultSmearTaken}
                      onChange={handleChanges('VaultSmearTaken')}
                    />
                    {" "}
                    <Label htmlFor="VaultSmearTaken">
                      Vault Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ENDOSmearTaken" className=""
                      id="ENDOSmearTaken"
                      value={examinationDetails.ENDOSmearTaken ? false : true}
                      checked={examinationDetails.ENDOSmearTaken}
                      onChange={handleChanges('ENDOSmearTaken')}
                    />
                    {" "}
                    <Label htmlFor="ENDOSmearTaken">
                      Endo Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="HRHPVTyping" className=""
                      id="HRHPVTyping"
                      value={examinationDetails.HRHPVTyping ? false : true}
                      checked={examinationDetails.HRHPVTyping}
                      onChange={handleChanges('HRHPVTyping')}
                    />
                    {" "}
                    <Label htmlFor="HRHPVTyping">
                      HPV Testing
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3" >
              <div className="bordered pl-2 pr-2">
                <h3 className="card-title  bordered-title">Swab</h3>
                <div className="custom-radio text-left pl-4">
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="HVSSwabTaken" className=""
                      id="HVSSwabTaken"
                      value={examinationDetails.HVSSwabTaken ? false : true}
                      checked={examinationDetails.HVSSwabTaken}
                      onChange={handleChanges('HVSSwabTaken')}
                    />
                    {" "}
                    <Label htmlFor="HVSSwabTaken">
                      HVS Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ECSTaken" className=""
                      id="ECSTaken"
                      value={examinationDetails.ECSTaken ? false : true}
                      checked={examinationDetails.ECSTaken}
                      onChange={handleChanges('ECSTaken')}
                    />
                    {" "}
                    <Label htmlFor="ECSTaken">
                      ECS Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ChlamydiaU25" className=""
                      id="ChlamydiaU25"
                      value={examinationDetails.ChlamydiaU25 ? false : true}
                      checked={examinationDetails.ChlamydiaU25}
                      onChange={handleChanges('ChlamydiaU25')}
                    />
                    {" "}
                    <Label htmlFor="ChlamydiaU25">
                      Chlamydia U25
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ChlamydiaSwabTaken" className=""
                      id="ChlamydiaSwabTaken"
                      value={examinationDetails.ChlamydiaSwabTaken ? false : true}
                      checked={examinationDetails.ChlamydiaSwabTaken}
                      onChange={handleChanges('ChlamydiaSwabTaken')}
                    />
                    {" "}
                    <Label htmlFor="ChlamydiaSwabTaken">
                      Chlamydia Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="GUScreenSwabTaken" className=""
                      id="GUScreenSwabTaken"
                      value={examinationDetails.GUScreenSwabTaken ? false : true}
                      checked={examinationDetails.GUScreenSwabTaken}
                      onChange={handleChanges('GUScreenSwabTaken')}
                    />
                    {" "}
                    <Label htmlFor="GUScreenSwabTaken">
                      GUM Screen
                    </Label>
                    <div className="clearfix"></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <br />
          {examType !== "CytologyOnly" ?
            <>
              <div className="bordered" style={{ flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center" }}  >
                <h3 className="card-title txtLeft bordered-title">Colposcopic Opinion</h3>
                <br />
                <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                  <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                    <div className="bordered">
                      <h3 className="card-title txtLeft bordered-title text-14">Cervical</h3>
                      <div className="pl-3 pr-3">
                        <div className="row text-center">
                          <div className="custom-radio text-left">
                            <div className="row mb-2">
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Normal"
                                  value={examinationDetails.OpinionCervical !== 'Normal' ? 'Normal' : null}
                                  checked={examinationDetails.OpinionCervical === 'Normal'}
                                  className="" onChange={handleChanges('OpinionCervical')}
                                />
                                <label className="text-blue" htmlFor="Normal">Normal</label>
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Invasion"
                                  value={examinationDetails.OpinionCervical !== 'Invasion' ? 'Invasion' : null}
                                  checked={examinationDetails.OpinionCervical === 'Invasion'}
                                  className="" onChange={handleChanges('OpinionCervical')}
                                />
                                <label className="text-blue" htmlFor="Invasion">Invasion</label>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Unsatisfactory"
                                  value={examinationDetails.OpinionCervical !== 'Unsatisfactory' ? 'Unsatisfactory' : null}
                                  checked={examinationDetails.OpinionCervical === 'Unsatisfactory'}
                                  className="" onChange={handleChanges('OpinionCervical')}
                                />
                                <label className="text-blue" htmlFor="Unsatisfactory">Unsatisfactory</label>
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="CGIN"
                                  value={examinationDetails.OpinionCervical !== 'CGIN' ? 'CGIN' : null}
                                  checked={examinationDetails.OpinionCervical === 'CGIN'}
                                  className="" onChange={handleChanges('OpinionCervical')}
                                />
                                <label className="text-blue" htmlFor="CGIN">CGIN</label>
                              </div>
                            </div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="HPV/inflammatory/benign"
                              value={examinationDetails.OpinionCervical !== 'HPV/inflammatory/benign' ? 'HPV/inflammatory/benign' : null}
                              checked={examinationDetails.OpinionCervical === 'HPV/inflammatory/benign'}
                              className="" onChange={handleChanges('OpinionCervical')}
                            />
                            <label className="text-blue" htmlFor="HPV/inflammatory/benign">HPV/inflammatory/benign</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="CIN - Low Grade"
                              value={examinationDetails.OpinionCervical !== 'CIN - Low Grade' ? 'CIN - Low Grade' : null}
                              checked={examinationDetails.OpinionCervical === 'CIN - Low Grade'}
                              className="" onChange={handleChanges('OpinionCervical')}
                            />

                            <label className="text-blue" htmlFor="CIN - Low Grade">CIN - Low Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="CIN - High Grade"
                              value={examinationDetails.OpinionCervical !== 'CIN - High Grade' ? 'CIN - High Grade' : null}
                              checked={examinationDetails.OpinionCervical === 'CIN - High Grade'}
                              className="" onChange={handleChanges('OpinionCervical')}
                            />
                            <label className="text-blue" htmlFor="CIN - High Grade">CIN - High Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="No Cervix"
                              value={examinationDetails.OpinionCervical !== 'No Cervix' ? 'No Cervix' : null}
                              checked={examinationDetails.OpinionCervical === 'No Cervix'}
                              className="" onChange={handleChanges('OpinionCervical')}
                            />
                            <label className="text-blue" htmlFor="No Cervix">No Cervix</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Not Performed"
                              value={examinationDetails.OpinionCervical !== 'Not Performed' ? 'Not Performed' : null}
                              checked={examinationDetails.OpinionCervical === 'Not Performed'}
                              className="" onChange={handleChanges('OpinionCervical')}
                            />
                            <label className="text-blue" htmlFor="Not Performed">Not Performed</label>
                            <div className="clearfix mb-2"></div>
                            <div className="row">
                              <div className="col-md-6 pt-1">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Other"
                                  value={examinationDetails.OpinionCervical !== 'Other' ? 'Other' : null}
                                  checked={examinationDetails.OpinionCervical === 'Other'}
                                  className="" onChange={handleChanges('OpinionCervical')}
                                />
                                <label className="text-blue" htmlFor="Other">Other</label>
                              </div>
                              <div className="col-md-6">
                                <input type="text" disabled={examinationDetails.OpinionCervical !== 'Other' ? true : false} className="form-control mb-3"
                                  value={examinationDetails.OpinionCervicalOtherText}
                                  onChange={handleChanges('OpinionCervicalOtherText')}
                                  placeholder="" style={{ marginBottom: 20 }} />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                    <div className="bordered">
                      <h3 className="card-title txtLeft bordered-title text-14">Vaginal</h3>
                      <div className="pl-3 pr-3">
                        <div className="row text-center">
                          <div className="custom-radio text-left">

                            <input
                              type="radio"
                              id="NormalVaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'Normal' ? 'Normal' : null}
                              checked={examinationDetails.OpinionVaginal === 'Normal'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />
                            <label className="text-blue" htmlFor="NormalVaginal">Normal</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="No Opinion"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'No Opinion' ? 'No Opinion' : null}
                              checked={examinationDetails.OpinionVaginal === 'No Opinion'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />
                            <label className="text-blue" htmlFor="No Opinion">No Opinion</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="HPV/inflammatory/benignVaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'HPV/inflammatory/benign' ? 'HPV/inflammatory/benign' : null}
                              checked={examinationDetails.OpinionVaginal === 'HPV/inflammatory/benign'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />
                            <label className="text-blue" htmlFor="HPV/inflammatory/benignVaginal">HPV/inflammatory/benign</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="VaIN - Low Grade"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'VaIN - Low Grade' ? 'VaIN - Low Grade' : null}
                              checked={examinationDetails.OpinionVaginal === 'VaIN - Low Grade'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />

                            <label className="text-blue" htmlFor="VaIN - Low Grade">VaIN - Low Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="VaIN - High Grade"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'VaIN - High Grade' ? 'VaIN - High Grade' : null}
                              checked={examinationDetails.OpinionVaginal === 'VaIN - High Grade'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />
                            <label className="text-blue" htmlFor="VaIN - High Grade">VaIN - High Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="InvasionVaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'Invasion' ? 'Invasion' : null}
                              checked={examinationDetails.OpinionVaginal === 'Invasion'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />
                            <label className="text-blue" htmlFor="InvasionVaginal">Invasion</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="Not Performed Vaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.OpinionVaginal !== 'Not Performed' ? 'Not Performed' : null}
                              checked={examinationDetails.OpinionVaginal === 'Not Performed'}
                              className="" onChange={handleChanges('OpinionVaginal')}
                            />
                            <label className="text-blue" htmlFor="Not Performed Vaginal">Not Performed</label>
                            <div className="clearfix mb-2"></div>
                            <div className="row">
                              <div className="col-md-6 pt-1">
                                <input
                                  type="radio"
                                  id="OtherVaginal"
                                  name="vaginaloption_radio"
                                  value={examinationDetails.OpinionVaginal !== 'Other' ? 'Other' : null}
                                  checked={examinationDetails.OpinionVaginal === 'Other'}
                                  className="" onChange={handleChanges('OpinionVaginal')}
                                />
                                <label className="text-blue" htmlFor="OtherVaginal">Other</label>
                              </div>
                              <div className="col-md-6">
                                <input type="text" id="firstName" className="form-control mb-3" placeholder=""
                                  disabled={examinationDetails.OpinionVaginal !== 'Other' ? true : false}
                                  style={{ marginBottom: 20 }}
                                  value={examinationDetails.OpinionVaginalOtherText}
                                  onChange={handleChanges('OpinionVaginalOtherText')}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </> : <div style={{ height: "100%", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center" }} > </div>}
        </Col>
      </Row>
    </>
  )


  return (
    <>
      <div className="card-header bg-iri txtLeft">
        <h4 className="m-b-0 text-white">Examination Details</h4>
      </div>
      <div className="card-body">
        <Form noValidate={true} onSubmit={handleSubmit} onReset={handleExit} >
          <div style={{ minHeight: "350px", display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: examType === 'CytologyOnly' ? "flex-start" : "center" }} >
            {examType === "NurseLedClinic" ? NurseLedClinic : formDetails}
          </div>
          <br />
          <div >
            <Button className="btn fL btn-secondary" disabled>Add Notes</Button>

            <Button className="btn btnIri mr-2 fR" onClick={handleExit} >Exit</Button>
            <Button className="btn btnIri mr-2 fR" type="submit">Next</Button>
            <Button className="btn btnIri mr-2 fR" onClick={() => handleBack()}>Previous</Button>
          </div>
        </Form>
      </div>
      <div className="clearfix"></div>
    </>
  )
}

export default ExaminationDetails;
