import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, uploadFiles, uploadImages } from 'redux/actions/data.action';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_LOADING, STOP_LOADING, SET_DATASHEET_PICTURES, SET_EXAMINATION_DETAILS, SET_NETWORK, SET_STREAM } from 'redux/actions/types';
import ReactPlayer from 'react-player';
import { baseToFormData } from 'Utils/helpers';
import { env_vars } from 'Utils/config';
import { FaCog } from 'react-icons/fa'; // Import the settings icon
import SwipeableTextMobileStepper from 'component/ImageViewer';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import { IconButton } from '@mui/material';
import { uploadFilesToBlob } from 'Utils/azureBlob';
import { getStreamData, getUserNetWork, postStreamAction } from 'redux/actions/stream.actions';
import StreamSwitch from 'component/StreamSwitch';
import StreamModal from 'component/Modals/StreamModal';
import { subscribeToStream } from 'Utils/socket.service';

let { azure_storage_url} = env_vars;








export default function ColposcopicImaging() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { network, stream } = useSelector(({ auth }) => auth)
    const { patientDetails, datasheetpictures } = useSelector(({ data }) => data);
    const { examination } = useSelector(({ examination }) => examination);
    const { viewOption, loading, edited } = useSelector(({ ui }) => ui);
    const [rnd, setRnd] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [savedImages, setSavedImages] = useState([]);
    const [isLive, setIsLive] = useState(false);
    const [capturedImages, setCapturedImages] = useState([]);
    const [isView, setIsView] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [selected, setSelected] = useState(null); 
    const [isSettings, setIsSettings] = useState(false); // State to manage settings view
    const [formData, setFormData] = useState({
        name: '',
        url: '',
        streamId: '',
        value: 'cloud'
    });
    
    const playerLocalRef = useRef(null);
    
    const handlePlayPause = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    const handleCapture = () => {
        if (playerLocalRef.current) {
            const playerElement =  playerLocalRef.current.getInternalPlayer();
            if (playerElement) {
                const captureOptions = {
                    scale: 5,
                };

                html2canvas(playerElement, captureOptions).then((canvas) => {
                    const dataUri = canvas.toDataURL();
                    setCapturedImage(dataUri);
                    capturedImages.push(dataUri);
                    setCapturedImages(capturedImages)
                    return baseToFormData(dataUri, `examination-picture-${datasheetpictures.length + 1}.png`);
                }).then((imgFile) => {
                    savedImages.push(imgFile);
                    setSavedImages(savedImages);
                });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        handleCapture();
    };

    const handleGetExamination = (id) => {
        dispatch(getCervixExaminationDetailsById(id)).then((res) => {
            let { tbl_DatasheetPictureNumbers } = res;
            dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } });
            dispatch({ type: SET_DATASHEET_PICTURES, payload: tbl_DatasheetPictureNumbers });
        
        });
    };

    const handleNext = () => {
        navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicFindings' : '/dashboard/viewPatient/colposcopicFindings'}`);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleExit = (id) => {
        if (edited.find(a => a === 'examination')) {
            dispatch({ type: OPEN_MODAL, payload: "save_details" });
        } else {
            dispatch({ type: CLEAR_EXAMINATION });
            dispatch({ type: CLEAR_EXAMINATION_DETAILS });
        }
        navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`);
    };

    const handleSave = () => {
        setIsPlaying(true)
        setIsView(true)
        setIsSettings(false)    
    }
    
    const handleCancel = () => {
        setIsPlaying(true)
        setIsSettings(false)
    }
    
    const handleWriteToStorage = async () => {
        const formData = new FormData();
        for (const file of savedImages) {
            formData.append('files', file);
        }
  
        await uploadFilesToBlob(savedImages);

        // dispatch(uploadImages({files: uploadedFiles}, examination.ExaminationID)).then((res) => {
        //     handleGetExamination(examination.ExaminationID);
        //     setSavedImages([])
        // }).catch(err => {
        //     console.log(err);
        // });
    };

    const toggleSettings = () => {
        dispatch({type: OPEN_MODAL, payload: 'StreamModal'})
        // setIsSettings((prevIsSettings) => !prevIsSettings);
    };

    const handleChange = (e) => {
        setIsView(false)
        setFormData({
            ...formData, [e.target.name]: e.target.value  
            });
    };
    


      const handleStream = async () => {
        dispatch({type: STOP_LOADING})

        dispatch(getStreamData())
        .then(streamVal => {
            console.log(streamVal, 'RES STREAM')
            if(streamVal){
                // dispatch({type: OPEN_MODAL, payload: 'StreamModal'})
                let validLive = streamVal.status['live-status']?.result == 22 ? true : false

                    
                        setIsView(validLive)
                    if(selected && streamVal.settings['stream-server']){
                        let strm = streamVal.settings['stream-server'].find(a => a.id == selected.id);
                        console.log(strm, 'STREAM SELECTED')
                        dispatch({type: SET_NETWORK, payload: strm ? strm : {id: null}})
                    }  
                    
                    if(!selected && streamVal.settings && streamVal.settings['stream-server']){

                        setSelected(streamVal.settings['stream-server'][0])
                         setRnd(Math.random())
                    } else if(streamVal && streamVal.status){
                        let validLive = streamVal.status['live-status']?.result == 22 ? true : false;
                        setIsLive(validLive)
                    } 
                    
                    if(streamVal && streamVal.settings && !streamVal.settings['stream-server'][0]){
                        setIsView(false)
                        setIsLive(false)
                        setSelected(null)
                        dispatch({type: SET_NETWORK, payload: {id: null}})
                    }
                    dispatch({type: SET_STREAM, payload: streamVal})
                    dispatch({type: STOP_LOADING})

            //   dispatch({type: SET_BETS, payload: val})
            }

        })
        .catch(err => {
            console.log(err)
        })
  
      }
      
      const handleLive = async (e) => {
        dispatch({type: SET_LOADING})

            if(!stream.isAuth){
                dispatch({type: OPEN_MODAL, payload: 'StreamModal'})
                dispatch({type: STOP_LOADING})
                return
            }
            
            if(stream.settings["stream-server"] && stream.settings["stream-server"].length == 0){
                dispatch({type: OPEN_MODAL, payload: 'StreamModal'})
                dispatch({type: STOP_LOADING})
                return
            }
      
      
      
        let validLive = stream.status['live-status']?.result == 22 ? true : false
            
            dispatch({type: SET_NETWORK, payload: {id: null}})
            setIsView(false)

            console.log(validLive, 'IS LIVE');
            setRnd(Math.random())
             dispatch(postStreamAction(`method=${validLive ? 'stop-live' : 'start-live'}`))
 
            
      }
      
      const handleSelect = (row) => {
        setSelected(row)
        // setIsView(false)
   
        
        
    }          

    useEffect(() => {
        if (patientDetails && (patientDetails.NHSnumber || patientDetails.HospitalNumber)) {
            if (examination && examination.ExaminationID) {
                handleGetExamination(examination.ExaminationID);
            }
        }
    }, [patientDetails, examination]);
    
    

        



    useEffect(() => {
        // handleNetwork();
        if(selected && selected.name){

            let validLive = stream.status['live-status']?.result == 22 ? true : false;
            const secureUrl = selected.url.replace('rtmp://', '');
            const host =  secureUrl.split(":")[0];
                setFormData({
                    name: selected.name,
                    url: `https://${host}`,
                    streamUrl: `https://${host}/live/${selected.key}.flv`,
                    streamId: selected.key,
                    value: 'cloud'        
                })
                dispatch({type: SET_NETWORK, payload: selected})
                setIsView(true)
                setIsLive(validLive)
                setRnd(Math.random())
        } 
        
        return () => {
            setIsView(false)
            setRnd(Math.random())
        }
    }, [selected])    
    
    
    useEffect(() => {
    
        handleStream()
      subscribeToStream((err, streamVal) => {
        if(streamVal && streamVal.status){
            // dispatch({type: OPEN_MODAL, payload: 'StreamModal'})
            let validLive = streamVal.status['live-status']?.result == 22 ? true : false

                
                    setIsView(validLive)
                if(selected && streamVal.settings['stream-server']){
                    let strm = streamVal.settings['stream-server'].find(a => a.id == selected.id);
                    console.log(strm, 'STREAM SELECTED')
                    dispatch({type: SET_NETWORK, payload: strm ? strm : {id: null}})
                    setRnd(Math.random())

                }  else if(!selected && streamVal.settings && streamVal.settings['stream-server']){
                    
                    handleSelect(streamVal.settings['stream-server'][0])
                     setRnd(Math.random())
                } else if(streamVal && streamVal.settings && !streamVal.settings['stream-server']){
                    setIsView(false)
                    setIsLive(false)
                    setSelected(null)
                    dispatch({type: SET_NETWORK, payload: {id: null}})
                }
                
               if(streamVal && streamVal.status){
                    let validLive = streamVal.status['live-status']?.result == 22 ? true : false;
                    setIsLive(validLive)
                }
                dispatch({type: SET_STREAM, payload: streamVal})
                dispatch({type: STOP_LOADING})

        //   dispatch({type: SET_BETS, payload: val})
        }
      })
    

    }, [])
    

console.log(formData.streamUrl, 'streamurl')

    return (
        <>
       
        <StreamModal
            setSelected={handleSelect}
            selected={selected}
        />
        <div className="card">
            <div className="card-header bg-iri w-100">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                    }}
                >
                    <h4 className="text-white">Imaging / Streaming Feature</h4>
                    <div>
                    <StreamSwitch
                        loading={loading}
                        checked={(isLive && selected.name)}
                        setChecked={handleLive}
                    />
                    <IconButton onClick={toggleSettings} style={{ background: 'none', border: 'none', color: 'white' }}>
                        <VideoSettingsIcon/>
                    </IconButton>       
                    </div>
             
                </div>
            </div>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'row' }}>
              {(isLive && selected.name) ?  <div className='col-md-9 p-2 pt-3 pb-3'
                    style={{minHeight: '500px'}}
                >
             { isView && <ReactPlayer
                key={rnd}
                ref={playerLocalRef}
                url={formData.streamUrl}
                playing={isPlaying}
                controls
                width="100%"
                height="100%"
            />}
                </div> 
                : 
                <div className='col-md-9 p-2 pt-3 pb-3'
                style={{minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                    <h3>No Stream Available</h3>
                </div>
                }
                <div className='col-md-3 p-2 pt-3 pb-3' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='bordered pl-2 pr-2' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {isSettings ? (
                            <div className='bordered pb-3 mt-3'>
                                <h3 className='card-title bordered-title'>Edit Server</h3>
                        
                     
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='col-md-4' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <label className='mt-3 text-14'>Stream host</label>
                                    </div>
                                    <div className='pr-2 pl-2 col-md-8'>
                                        <input type="text" className='form-control' name="url" value={formData.url} onChange={handleChange} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='col-md-4' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <label className='mt-3 text-14'>Stream ID</label>
                                    </div>
                                    <div className='pr-2 pl-2 col-md-8'>
                                        <input type="text" className='form-control' name="streamId" value={formData.streamId} onChange={handleChange} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='col-md-4' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <label className='mt-3 text-14'>Stream URL</label>
                                </div>
                                <div className='pr-2 pl-2 col-md-8'>
                                    <input type="text" className='form-control' name="streamUrl" value={formData.streamUrl} onChange={handleChange} />
                                </div>
                            </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button className="btn mr-3 mt-2" onClick={() => handleSave()}>Save</Button>
                                    <Button className="btn mr-3 mt-2" onClick={() => handleCancel(false)}>Cancel</Button>
                                </div>
                                    
                            </div>
                           
                        ) : (
                        <>
                        <div className='bordered pb-3 mt-3'>
                        <h3 className='card-title bordered-title'>IMAGE</h3>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='col-md-6' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <label className='mt-3 text-14'>Take Image</label>
                            </div>
                            <div className='pr-2 pl-2 col-md-6'>
                                {!isPlaying ?
                                    <Button className="btn mr-3" onClick={() => handlePlayPause()}>Live</Button>
                                    :
                                    <Button className="btn mr-3" onClick={() => handlePlayPause()}>Freeze</Button>
                                }
                                <Button className="btn mr-3" disabled={isPlaying} onClick={handleSubmit}>Save</Button>
                            </div>
                        </div>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='col-md-6' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <label className='mt-2 text-13'>Number of saved images</label>
                            </div>
                            <div className='col-md-6 pr-2 pl-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <input type="number" className='form-control mr-4 text-center' style={{ width: '70px' }} value={capturedImages.length} readOnly />
                            </div>
                        </div>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button className="btn btnIri marRight20 mr-3" style={{ width: '150px' }} onClick={() => handleWriteToStorage()}>Write to disk</Button>
                    </div>
                    </div>
                    <br/>
                {(capturedImages.length !== 0 || capturedImage) && <SwipeableTextMobileStepper 
                        images={capturedImages.map(a => { return { imgPath: a.path ? azure_storage_url + a.path : a }})}
                    >
                    </SwipeableTextMobileStepper>}
                        </>
                        )}
                  
                    </div>
                </div>
   
            
            </div>
        </div>
        </>
        
    );
}
