import React, { useEffect, useRef, useState } from "react";
import '../../../css/PDFModal.css'; // Import your CSS file
import { Button, Input } from "reactstrap";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { generateLetterTemplate, parseFileTemplate, uploadDocxLetter } from "../../../redux/actions/data.action";
import { fileUpload, getFile } from "redux/actions/file.action";
import documentService from "Utils/document.service";
import { baseToFormData, formatReportData } from "Utils/helpers";
import { env_vars } from "Utils/config";
import CustomModal from "component/Modals/CustomModal";
import CircularProgress from '@mui/material/CircularProgress';


const GenerateLetter = ({ title }) => {
	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch();
	let { template } = location.state ? location.state : {};
	const { user } = useSelector(({ auth }) => auth);
	const { patientDetails: patient, patientDetails, personalReferralDetails, referralReasons, treatmentDetails, managementDetails, patientAssociatedSymptoms } = useSelector(({ data }) => data)
	const {  examination, examinationDetails} = useSelector(({ examination }) => examination)

	// const [rnd, setRnd] = useState(0);
	const [fileModal, setFileModal] = useState(false);
	const [urlContent, setUrlContent] = useState(null);
	const [activeFile, setActiveFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingPrint, setLoadingPrint] = useState(false);





	const handlePrint = async () => {
		// setUrlContent(null);
		setLoadingPrint(true)
		let tmpFile = `${String(template).trim().replace(/[ &><%\/\\|\[\]{}()*]/g, "_")}`;
		let upFile = `${tmpFile}_${moment().format('MMDDYYYY')}_${patientDetails.SystemID}.docx`;



		let file = await baseToFormData(`${env_vars.editor_api}/download?fileName=${upFile}`, upFile);
		console.log(file, 'GENERATE')
		const formData = new FormData();
		formData.append('file', file, upFile);
		let response = await dispatch(parseFileTemplate(formData, { format: 'pdf'}))
			console.log(response)
			let { fileUrl} = response.data;
		
		window.open(fileUrl, '_blank')

		dispatch(fileUpload(formData, `/Letters/Colposcopy/${patientDetails.HospitalNumber}/Patient`))
			.then((res) => {
				console.log(res, 'res')
				setLoadingPrint(false)

				// getItems(e.destinationDirectory)
			})
			.catch(err => {
				setLoadingPrint(false)
				console.log(err)
			})
	}




	const handleActiveFile = async () => {
		setLoading(true)
		let tmpFile = `${String(template).trim().replace(/[ &><%\/\\|\[\]{}()*]/g, "_")}`;
		let upFile = `${tmpFile}_${moment().format('MMDDYYYY')}_${patientDetails.SystemID}.docx`;
		let val = `${tmpFile}.docx`;
		let data = formatReportData({ patientDetails, personalReferralDetails, examination, examinationDetails, referralReasons, treatmentDetails, managementDetails, patientAssociatedSymptoms })

		let actv = await dispatch(getFile('filePath', `/Templates/${val}`))
		let file = null;

		await documentService.deleteFile(val)
		await documentService.deleteFile(upFile)

		if (!actv) {
			file = await baseToFormData(`${env_vars.editor_api}/download?fileName=new.docx`, 'new.docx');
		} else {
			file = await baseToFormData(`${env_vars.api_url}/files/download/filePath?filePath=/Templates/${val}`, val);
		}

		console.log(file, 'FILE FIRSTsss', upFile, val, tmpFile)
		const formData = new FormData();
		formData.append('file', file, upFile);

		let file2 = await dispatch(parseFileTemplate(formData, { parse: true }))
		let { letterTemplate } = file2.data;

		let file3 = await dispatch(generateLetterTemplate({ letterTemplate, data, extend: 'docx' }))
		let { fileUrl } = file3.data;


		let file1 = await baseToFormData(fileUrl, upFile);
		const formData1 = new FormData();

		formData1.append('uploadedFile', file1, upFile);


		dispatch(uploadDocxLetter(formData1))
			.then((response) => {
				let { filename } = response.data;
				// setActiveFile(filename)
				setUrlContent(`${env_vars.editor_api}/editor?fileName=${filename}&directUrl=true`)
				setLoading(false)
			})
			.catch(err => {
			setLoading(false)
				console.log(err)
			})




	}


	const handleExit = () => {
		navigate(-1)
	}

	useEffect(() => {
		if (activeFile && patientDetails.SystemID) {
			handleActiveFile(activeFile);
		}

		return () => {
			documentService.deleteFile(activeFile)
			setFileModal(false)
		}
	}, [activeFile])


	useEffect(() => {
		if (template) {
			console.log('LETTER TEMP', template)
			setActiveFile(`${String(template).trim().replace(/[ &><%\/\\|\[\]{}()*]/g, "_")}.docx`)
		}


		return () => {
			documentService.deleteFile(activeFile)
			setFileModal(false)
		}
	}, [])

	// useEffect(() => {
	// 	console.log('trerender')
	// 	componentRef.current = componentRef.current
	// }, [rnd])



	console.log(location, user, urlContent, 'location', template)
	return (
		<>
			<CustomModal


			>
				<div className="form-group center pl-3">
					<label className="control-label col-md-5 text-left">Hospital Number</label>
					<input type="text" id="HospitalNumber" className="col-md-7 form-control" placeholder="Enter Hospital Number"
						// style={{ width: '300px' }}
						value={activeFile}
						onChange={(e) => setActiveFile(e.target.value)}

					/>
				</div>
			</CustomModal>

			<div className="card-body">
				<div className="wrapper">
				{loading ? 
				      <CircularProgress />
				: urlContent &&
					<iframe
						title="Embedded Website"
						src={urlContent}
						width="85%"
						height="550px"
						maxHeight="100%"
						frameBorder="0"
						allowFullScreen
					></iframe>
				}
				</div>
				<div className="d-flex w-100 justify-content-end pdf-actions">
					<Button className="btn btnIri mr-3" disabled={loadingPrint || loading} onClick={handlePrint}>
						Print
					</Button>
					<Button className="btn btnIri mr-3" onClick={handleExit}>Exit</Button>
				</div>
			</div>
		</>

	);
};

export default GenerateLetter;
