import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab"
import { Button, Input, Label } from 'reactstrap';
import { Checkbox, FormControlLabel, FormGroup, Grid} from '@mui/material';
import moment from 'moment';

// import Sonnet from '../../components/Sonnet';
import "../../../css/pages/tab-page.css";
import { useDispatch, useSelector } from 'react-redux';
import { baseToFormData, createQuarterDateRanges, getDateRange, getInitials, getMonthName } from 'Utils/helpers';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    PieChart, Pie, Cell,
    ResponsiveContainer
} from "recharts";
import { useNavigate } from "react-router-dom";
import { getStatisticsColposcopists, getStatisticsColposcopistsCases } from "redux/actions/data.action";
import { BSCCPHeaders } from 'Utils/commonData';
import { CLOSE_MODAL, OPEN_MODAL, SET_ERRORS, SET_GRID_VIEW, SET_PATIENT_DETAILS, SET_PERSONAL_REFERRAL_DETAILS, SET_SELECTED_ROW, SET_VIEW_OPTION } from 'redux/actions/types';
import { countColposcipistsCases } from 'Utils/utils';
import { useTheme, useMediaQuery } from '@mui/material';    

const headers = BSCCPHeaders;

const objectWithKeys = {};
headers.forEach(str => {
  objectWithKeys[str.field] = "-";
});


const Manual = () => {
const [rows, setRows] = useState(new Array(10).fill(objectWithKeys));
const [isGridView, setIsGridView] = useState(false); 
const [colposcopists, setColposcopists] = useState([]);
const [selectedColp, setSelectedColp] = useState([]);
const {user } = useSelector(({auth}) => auth)
const [rnd, setRnd] = useState(0);
const [cases, setCases] = useState([]);
const [isRange, setIsRange] = useState(false);
const theme = useTheme();

const casesData = [
    {
      key: "categoryCount1",
      label: 'First Exams',
      backgroundColor: 'rgba(255, 0, 0, 1)',
      borderColor: 'rgba(255, 0, 0, 1)',
      borderWidth: 1,
      data: [65, 59, 80, 81, 56, 55],
    },
    {
      key: "categoryCount2",
      label: 'Follow-up Visits',
      backgroundColor: 'rgba(0, 128, 0, 1)',
      borderColor: 'rgba(0, 128, 0, 1)',
      borderWidth: 1,
      data: [28, 48, 40, 19, 86, 27],
    },
    {
      key: "categoryCount3",
      label: 'Treatment',
      backgroundColor: 'rgba(0, 0, 255, 1)',
      borderColor: 'rgba(0, 0, 255, 1)',
      borderWidth: 1,
      data: [38, 88, 97, 68, 45, 30],
    },
    {
      key: "categoryCount4",
      label: 'Trainings',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderColor: 'rgba(255, 255, 0, 1)',
      borderWidth: 1,
      data: [15, 40, 35, 20, 60, 45],
    }
  ];
  const [range, setRange] = useState({
    start: new Date('2020-01-01'),
    end: new Date('2022-01-01')
    
  })
const [graphData, setGraphData] = useState({
    rnd: 0,
    labels: [],
    datasets: casesData,
  })
  

const [values, setValues] = useState({
    period: null,
    colposcopist: "All",
});
const dispatch = useDispatch();


const data = [
    {
        date: "2022-01",
        uv: 4000,
        pv: 2400,
        amt: 2400,
        legend1: 30,
        legend2: 5,
        legend3: 50
    },
    {
        date: "2022-02",
        uv: 3000,
        pv: 1398,
        amt: 2210,
        legend1: 40,
        legend2: 11,
        legend3: 80
    },
    {
        date: "2022-03",
        uv: 2000,
        pv: 9800,
        amt: 2290,
        legend1: 60,
        legend2: 23,
        legend3: 60
    },
    {
        date: "2022-04",
        uv: 2780,
        pv: 3908,
        amt: 2000,
        legend1: 20,
        legend2: 2,
        legend3: 26
    },
    {
        date: "2022-05",
        uv: 1890,
        pv: 4800,
        amt: 2181,
        legend1: 58,
        legend2: 33,
        legend3: 80
    },
    {
        date: "2022-06",
        uv: 2390,
        pv: 3800,
        amt: 2500,
        legend1: 62,
        legend2: 10,
        legend3: 89
    },
    {
        date: "2022-07",
        uv: 3490,
        pv: 4300,
        amt: 2100,
        legend1: 24,
        legend2: 13,
        legend3: 40
    },
    {
        date: "2022-08",
        uv: 4000,
        pv: 2400,
        amt: 2400,
        legend1: 72,
        legend2: 34,
        legend3: 80
    },
    {
        date: "2022-09",
        uv: 3000,
        pv: 1398,
        amt: 2210,
        legend1: 18,
        legend2: 1,
        legend3: 30
    },
    {
        date: "2022-10",
        uv: 2000,
        pv: 9800,
        amt: 2290,
        legend1: 10,
        legend2: 2
    },
    {
        date: "2022-11",
        uv: 2780,
        pv: 3908,
        amt: 2000,
        legend1: 79,
        legend2: 33,
        legend3: 120

    },
    {
        date: "2022-12",
        uv: 1890,
        pv: 4800,
        amt: 2181,
        legend1: 101,
        legend2: 34,
        legend3: 120
    },
];

const data2 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

const getGraphData = async (selCases, selColp) => {
    let dataCases = await countColposcipistsCases(selCases, selColp);
    let datasets = [];
   //  if(selColp.length === 1){
    datasets = casesData.map(a => {
           let newObj = a;
           if(dataCases[a.key]){
           
             if(selColp.length == 1){
               let ind = colposcopists.map(a => Number(a.iUserID)).indexOf(Number(selColp[0].iUserID));
               newObj.data = [dataCases[a.key][ind]]  
             } else {
               newObj.data = dataCases[a.key]  
             }
           }
           return newObj
       })
       
    
    console.log(dataCases, 'DATA CASES')
    
    setGraphData({
       labels: selColp.map(a => getInitials(a.Name)),
       datasets: datasets,
       rnd: Math.random()
    })
    
    
   
    setRows(dataCases.colposcopistCases)
    setRnd(Math.random())
   }


   

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const monthTickFormatter = (tick) => {
    const date = new Date(tick);

    return date.getMonth() + 1;
};

const renderQuarterTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    if (month % 3 === 1) {
        return (
            <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>
        );
    }

    const isLast = month === 11;

    if (month % 3 === 0 || isLast) {
        const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    }
    return null;
};

const handlePlot = () => {

    console.log("Plot button clicked!");
    // Logic to handle plotting of the chart
    // You can define your data and other chart properties here
    // Example data for BarChart (replace with your actual data)
 
};

const handlePrint = () => {
    console.log("Print button clicked");
}

const handleSave = () => {
    console.log("Saved button clicked");
}



const handleSelectPeriod = (e) => {
    console.log('HANDLE PERIOD')
    let ind = createQuarterDateRanges().indexOf(e);
    let startDate = range.start;
    let endDate = range.end;
    if(!e) {
      startDate = "01/01/2020"
      endDate = "01/01/2022"
      setRange({...range, start: startDate, end: endDate})  

    } else { 
    if(ind !== -1){
      let ranges = getDateRange(ind);
      setValues({...values, period: e})
      setRange({start: ranges[0], end: ranges[1]});
      startDate = ranges[0];
      endDate = ranges[1];
    } else {
      if(e.field == 'start'){
        startDate = e.value;
      }
      if(e.field == 'end'){
        endDate = e.value
      }
      
      setRange({...range, [e.field]: e.value})  
    }
    }
    handleGetColposcopistsCases(startDate, endDate)
  }

const handleGetColposcopists = () => {
    dispatch(getStatisticsColposcopists(user.SiteID, 'Colposcopy'))
    .then(res => {
      if(res){
          setColposcopists(res)
          setSelectedColp(res)
          setValues({...values, colposcopist: "All"})
      }
    })
}

const handleGetColposcopistsCases = (start, end) => {
    console.log(start, end, 'dd')
      dispatch(getStatisticsColposcopistsCases(user.SiteID, start, end))
      .then(res => {
      if(res){
        setCases(res)
        console.log(res.length, 'countt')
        getGraphData(res, selectedColp)
      }
      })
  }
const handleSelectColposcopist = (e) => {
    dispatch({type: SET_SELECTED_ROW, payload: null})
  console.log('HANDLE COLP')
   let colps = e == 'All' ? colposcopists : colposcopists.filter(a => a.iUserID == e);
   setSelectedColp(colps)
    getGraphData(cases, colps)
    setValues({...values, colposcopist: e})
    setRnd(Math.random())
  }


const navigate = useNavigate();
    const handleExit = () => {
        localStorage.removeItem("formDetails")
        navigate('/dashboard/statistics')
    }
    
    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">K65 OPTIONS</h4>
                <div className="clr"></div>
            </div>
            <div className="row txtLeft">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body vtabs">

                            <br />
                            <br />
                            <div style={{ width: '100%' }}>
                                <ResponsiveContainer>
                                    <Tab.Container
                                        id="left-tabs-example"
                                        defaultActiveKey="first"
                                    >
                                        <Row>
                                            <Col sm={2}>
                                                <Nav
                                                    className="flex-column nav nav-tabs tabs-vertical"
                                                >
                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="first"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KC65-A</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="second"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KC65-B</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="third"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KC65-C Part 1</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="fourth"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KC65-C Part 2</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="fifth"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KC65-D</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="sixth"
                                                            className="nav-link"
                                                        >
                                                             <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KC65-E</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="seventh"
                                                            className="nav-link"
                                                        >
                                                             <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> KPI DATA</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="eighth"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            className='btnIri'
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }}> COVER</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="eighth"
                                                            className="nav-link"
                                                        >
                                                            <Button
                                                            style={{
                                                                height: 50,
                                                                width: 150
                                                            }} onClick={() => handleExit()}> Exit</Button>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col sm={10}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <div className="">
                                                            <h5 className='text-primary'>Referral - First appointment
                                                                waiting times & results</h5>
                                                            <br /><br />
                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="pv"
                                                                    fill="#8884d8"
                                                                />
                                                                <Bar
                                                                    dataKey="uv"
                                                                    fill="#82ca9d"
                                                                />
                                                            </BarChart>
                                                        </div>
                                                          {/* Button Grid */}
                                                              <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                         
                                                            
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <div className="">
                                                            <h5 className='text-primary'>
                                                                Appointment for Colposcopy
                                                            </h5>
                                                            <br /><br />
                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="legend1"
                                                                    fill="#c46977"
                                                                    name="New"
                                                                />
                                                                <Bar
                                                                    dataKey="legend2"
                                                                    fill="#93c47d"
                                                                    name="Return for Treatment"
                                                                />
                                                                <Bar
                                                                    dataKey="legend3"
                                                                    fill="#2986cc"
                                                                    name="Follow Up"
                                                                />
                                                            </BarChart>
                                                        </div>
                                                        <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="third">
                                                        <div className="">
                                                            <h5 className='text-primary'>
                                                                First attend referral result & procedure
                                                            </h5>

                                                            <br /><br />

                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data2}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />

                                                                <Bar
                                                                    dataKey="legend2"
                                                                    fill="#fb9678"
                                                                />
                                                            </BarChart>
                                                        </div>
                                                        <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fourth">
                                                        <div className="">
                                                            <h5 cla ssName='text-primary'>
                                                                All Attendance Procedure Type and Referral
                                                            </h5>
                                                            <br /><br />
                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="legend1"
                                                                    fill="#c46977"
                                                                    name="01 Jan 21 - 31 Dec 22"
                                                                />

                                                            </BarChart>
                                                        </div>
                                                        <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fifth">
                                                        <div className="">
                                                            <h5 className='text-primary'>
                                                                Biopsy result waiting times
                                                            </h5>
                                                            <br /><br />

                                                            <PieChart width={700} height={400}>
                                                                <Pie
                                                                    data={data2}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={180}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>

                                                        </div>
                                                        <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="sixth">
                                                        <div className="">
                                                            <h5 className='text-primary'>
                                                                Biopsy types and outcomes
                                                            </h5>
                                                            <br /><br />
                                                            <PieChart width={700} height={400}>
                                                                <Pie
                                                                    data={data2}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={180}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>
                                                        </div>
                                                        <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="seventh">
                                                        <div className="">
                                                            <h4>
                                                                KPI Data
                                                            </h4>
                                                            <br /><br />
                                                            <PieChart width={700} height={400}>
                                                                <Pie
                                                                    data={data2}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={180}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>
                                                        </div>
                                                        <Grid 
                                                              container
                                                              direction="row"
                                                              justifyContent="flex-start"
                                                              alignItems="center"
                                                              spacing={2}
                                                              
                                                              >
                                                               <Grid item lg={2} xs={3}
                                                              >
                                                          
                                                              <div
                                                              style={{width: '100%', display: 'flex',flexDirection: 'row',  alignItems: 'center', justifyContent: 'flex-end'}}
                                                              >
                                                              <FormControlLabel
                                                              control={<Checkbox color='primary'
                                                              sx={{
                                                                color: theme.palette.grey[400],
                                                                '&.Mui-checked': {
                                                                  color: theme.palette.primary.main,
                                                                },
                                                              }}
                                                              checked={isRange}
                                                              value={!isRange}
                                                              onChange={(e) => {
                                                                setIsRange(e.target.value == 'true' ? true : false)
                                                                if(isRange){
                                                                  handleSelectPeriod(createQuarterDateRanges()[0])
                                                                } else {
                                                                  handleSelectPeriod(false)
                                                                }
                                                                setRnd(Math.random())
                                                                }}
                                                              className='text-blue' style={{fontWeight: 'bold'}}
                                                              />} label="Date Range" />
                                                              </div>
                                                              </Grid>
                                                              <Grid item lg={2} xs={2} 
                                                                  style={{textAlign: 'right'}}
                                                                  display="flex"
                                                                  height="100%"
                                                                  justifyContent="flex-end"
                                                                  alignItems="flex-end"
                                                              >
                                                              
                                                              <label className="control-label text-blue" style={{fontWeight: 'bold'}}>Period</label>
                                                        
                                                              </Grid>
   
                                                              <Grid item lg={2} xs={10}>
                                                                {isRange ? 
                                                                  <FormGroup 
                                                                  style={{display: 'flex'}}
                                                                  >
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  Start
                                                                </Label>
                                                             <Input
                                                                    type="date"
                                                                    id="startdate" name="startdate" className="form-control" placeholder=""
                                                                    value={range.start ? moment(range.start).format('YYYY-MM-DD') : ''}
                                                                    onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'start'})}
                                                                  />
                                                                  </div>
                                                                  
                                                                  &nbsp;&nbsp;
                                                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                                                  <Label>
                                                                  End
                                                                </Label>
                                                                  <Input
                                                                  type="date"
                                                                  id="endDate" name="endDate" className="form-control" placeholder=""
                                                                  value={range.end ? moment(range.end).format('YYYY-MM-DD') : ''}
                                                                  onChange={(e) => handleSelectPeriod({value: e.target.value, field: 'end'})}
                                        
                                                                />
                                                                  </div>
                                                                
                                                                </FormGroup>
                                                                : 
                                                                <>
                                                                <select name="marital-Status" id="marital-Status" className="form-control"
                                                                value={values.period}
                                                                onChange={(e) => handleSelectPeriod(e.target.value)}
                                                                
                                                                >
                                                                <option value={null}>Select</option>
                                                                {createQuarterDateRanges().length !== 0 && createQuarterDateRanges().map((a, index) => {
                                                                  return (
                                                                    <option value={a}>{a}</option>
                                                                  )
                                                                })}
                                                                </select>
                                                                </>
                                                                
                                                                }
                                                        </Grid>
                                                         <Grid item lg={6} xs={12}
                                                          direction="row"
                                                          justifyContent="flex-end"
                                                          alignItems="flex-end"
                                                          >
                                                          <Grid 
                                                          container
                                                          spacing={2}
                                                          >
                                                         <Grid item lg={3}>
                                                            <button
                                                                className="btn btnIri"
                                                                onClick={() => {
                                                                    setIsGridView(!isGridView); // Example toggle grid view mode
                                                                    console.log(`Grid view mode toggled. New state: ${!isGridView}`);
                                                                }}
                                                                style={{ minWidth: '75px' }}
                                                            >
                                                                {isGridView ? 'View Chart' : 'View Grid'}
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePlot} style={{ minWidth: '75px' }}>
                                                                Plot
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handlePrint} style={{ minWidth: '75px' }}>
                                                                Print
                                                            </button>
                                                        </Grid>
                                                        <Grid item lg={3}>
                                                            <button className="btn btnIri" onClick={handleExit} style={{ minWidth: '75px' }}>
                                                                Exit
                                                            </button>
                                                        </Grid>
                                                        </Grid>
                                                        </Grid>
                                                        
                                                            </Grid>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="eighth">
                                                        <div className="">
                                                            <h5 className='text-primary'>
                                                                ICSP Cover
                                                            </h5>
                                                            <br /><br />
                                                            <div className="card-body">
                                                                <h4 className="card-title" id="1">1. Title will be here</h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <h4 className="card-title m-t-40" id="22">2. Title will be here</h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <h4 className="card-title m-t-40" id="3">3. Title will be here</h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                    
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Manual;
