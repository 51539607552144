const env_vars = {
    host: "https://compuscope.cloud",
    // host: "http://localhost:4000",

    storageAccountName: "compuscopestoragedev",
    sasToken: "sp=racwdli&st=2024-06-05T23:21:26Z&se=2024-07-06T07:21:26Z&sv=2022-11-02&sr=c&sig=o5PXF43d%2B8FNaIzagUVCc6hu3NfweHGHyQfXfZFlyUw%3D",
    containerName: "colposcopy",
    editor_api: 'https://docs.bugtech.online/example',
    rtmp_url: 'https://sharewin.pro',
    stream_server_url: 'http://127.0.0.1:4001'

}

env_vars["api_url"] = `${env_vars.host}/api`;
env_vars["azure_storage_url"] = `https://${env_vars.storageAccountName}.blob.core.windows.net`;


export { env_vars}