import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useDispatch, useSelector } from 'react-redux';
 import { fileUploadData, fileUploadTmp } from 'redux/actions/file.action';
import {uploadFileToBlob, getBlobsFileInContainer   } from 'Utils/azureBlob';
import { useState } from 'react';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function UploadButton({ setImageFile }) {
    const dispatch = useDispatch();
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const { user } = useSelector(({ auth }) => auth)

    const handleUpload = async (e) => {
        let file = e.target.files[0];
        
        let fileSplit = String(file.name).split('.');
        let ext = fileSplit[fileSplit.length - 1];
        
        let filename = user.iUserID ? user.iUserID : file.name; 
        
      
        let renamedFile = new File([file], filename);
      
        
                console.log(file)
        const blobsInContainer = await uploadFileToBlob(renamedFile);
            console.log(blobsInContainer, 'blocb ')
                setImageFile(blobsInContainer, renamedFile)
                setInputKey(Math.random().toString(36));

    }



    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<ImageOutlinedIcon />}
        >
            Change Image
            <VisuallyHiddenInput 
            key={inputKey || ''}
            type="file" accept="image/png, image/gif, image/jpeg" onChange={handleUpload} />
        </Button>
    );
}
