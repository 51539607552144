import {SET_SELECTED_ROW, CLEAR_SORT, CLEAR_VIEW_OPTION, CLOSE_MODAL, OPEN_MODAL, SET_EDITED, SET_LOADING, SET_SORT, SET_VIEW_OPTION, STOP_LOADING, SET_ERRORS, SET_GRID_VIEW, CLEAR_ERRORS, CLEAR_EDITED, SESSION_EXPIRED } from "../actions/types";

const INIT_SORT = {
  SystemID: 'ASC',
  SiteID: 'ASC',
  HospitalNumber: 'ASC',
  NHSnumber: 'ASC',
  Forename: 'ASC',
  MaidenName: 'ASC',
  Surname: 'ASC',
  DateOfBirth: 'ASC'
}

const INIT_STATE = {
  loading: false,
  sort: INIT_SORT,
  modal: null,
  viewOption: 'enter',
  edited: [],
  errors: {},
  isGridView: false,
  selectedRow: {}
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case STOP_LOADING: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_GRID_VIEW: {
        return {
        ...state, 
         isGridView: payload
        }
    }
    
    case SET_SELECTED_ROW: {
      return {
        ...state, 
        selectedRow: payload
      }
    }
    case SET_ERRORS: {
      return {
        ...state,
        errors: payload
      };
    }

    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: {}
      };
    }
    case SESSION_EXPIRED: {
      return {
        ...state,
        errors: { session: 'Session Expired!' }
      };
    }


    case SET_EDITED: {
      let edited = state.edited;
      let ind = edited.find(a => a === payload);
      if (!ind) {
        edited.push(payload);
      }

      localStorage.setItem('edited', JSON.stringify(edited))
      return {
        ...state,
        edited: payload ? edited : []
      };
    }

    case CLEAR_EDITED: {
      return {
        ...state,
        edited: []
      };
    }

    case SET_SORT: {
      return {
        ...state,
        sort: { ...state.sort, ...payload }
      };
    }

    case CLEAR_SORT: {
      return {
        ...state,
        sort: INIT_SORT
      };
    }

    case OPEN_MODAL: {
      return {
        ...state,
        modal: payload
      };
    }


    case CLOSE_MODAL: {
      return {
        ...state,
        modal: null
      };
    }


    case SET_VIEW_OPTION: {
      return {
        ...state,
        viewOption: payload
      };
    }


    case CLEAR_VIEW_OPTION: {
      return {
        ...state,
        viewOption: 'review'
      };
    }

    default:
      return state;
  }
};
