// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient} from '@azure/storage-blob';
import { env_vars } from 'Utils/config';


let {storageAccountName,  sasToken, containerName, azure_storage_url} = env_vars;

// Fill string with your Storage resource name

// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !((!storageAccountName || !sasToken));
};

// return list of blobs in container to display
const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `${azure_storage_url}/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
};


const getBlobsFileInContainer = async (container = containerName, filename) => {
  const returnedBlobUrls = [];
  


  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `${azure_storage_url}/?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(container);


  // get list of blobs in container
  // eslint-disable-next-line
  // let files = await containerClient.listBlobsFlat();
    // console.log(files, 'FILESS')
    for await (const blob of containerClient.listBlobsFlat()) {
      // if image is public, just construct URL
      if(filename == blob.name){
        returnedBlobUrls.push(
          `/${container}/${blob.name}`
        );
      }
    }
 
 

// let file = files.find(a => a.name == filename);
 
  // console.log(file, 'FILLEE')
 
  return returnedBlobUrls[0];
};

const createBlobInContainer = async (containerClient, file) => {
  
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadBrowserData(file, options);
  await blobClient.setMetadata({UserName : 'irisoft'});
};

const uploadFileToBlob = async (file) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `${azure_storage_url}/?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);



  // upload file
  await createBlobInContainer(containerClient, file);

  // get list of blobs in container
  return await getBlobsFileInContainer(containerName, file.name);
};

const uploadFilesToBlob = async (files) => {
  if (!files) return [];
  let blobFiles = [];
  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `${azure_storage_url}/?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

blobFiles = Promise.all(files.map(async file => {
  await createBlobInContainer(containerClient, file);
  let filename = await getBlobsFileInContainer(containerName, file.name); 
        return {filename}
}))

  // upload file
  

  // get list of blobs in container
  return blobFiles
};
// </snippet_uploadFileToBlob>

export { uploadFileToBlob, getBlobsFileInContainer, uploadFilesToBlob};

