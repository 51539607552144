import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import Topbar from './scenes/global/TopBar';
import { Route, Routes, Navigate } from "react-router-dom";

import './css/dashboard.css';

import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import ColposcopicImaging from './scenes/global/Streaming';


const StreamOnly = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientDetails } = useSelector(({ data }) => data)
  const { isAuthenticated } = useSelector(({ auth }) => auth)
  const { viewOption, modal, errors } = useSelector(({ ui }) => ui)
  const [isOpen, setIsOpen] = useState(false);

  


  // useEffect(() => {
  //   document.title = 'CUMPOSCOPE Digital Imaging (Colposcopy)';
  //   const token = getToken();
  //   if (!token) {
  //     removeUserSession();
  //     navigate('/login')
  //   }

  //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  //   dispatch(getAuthUser())
  //     .then(res => {
  //       if (res && res.token) {
  //         dispatch(getFilePath({
  //           filePath: `/Templates`,
  //           isDirectory: true,
  //           name: 'Patient'
  //         }))
  //         dispatch(getFilePath({
  //           filePath: ``,
  //           isDirectory: true,
  //           name: 'Profiles'
  //         }))
  //         dispatch(getFilePath({
  //           filePath: `/Letters`,
  //           isDirectory: true,
  //           name: 'Qarc'
  //         }))
  //         // setAuthLoading(false);
  //         // navigate('/dashboard/home')
  //       } else {
  //         removeUserSession();
  //         navigate('/login')
  //         // setAuthLoading(false);
  //       }
  //     })

  // }, [])


  // useEffect(() => {   
  // const token = getToken();
  //     if(isAuthenticated){
  //     console.log('CONNECT SOCKET', token)
  //       initiateSocketConnection(token);
  //     }
  // }, [isAuthenticated])


  return (
    <>
      <div id="main-wrapper">

        {/* TOP BAR COMPONENT */}
        <Topbar isOpen={isOpen} setIsOpen={setIsOpen} />
        {/* TOP BAR COMPONENT */}


          {/* <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== --> */}
          <div className="container-fluid" style={{ minHeight: '80vh' }}>
            {/* <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== --> */}

            <div className="page-titles">
                <Row className='pl-3 pr-3'>
              {patientDetails.SystemID &&
                <Col className='text-left'
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', lineHeight: 1 }}
                  >
                    <span className='text-primary text-15 text-left'>{patientDetails.Forename} {patientDetails.Surname}</span>
                    <span className='text-primary text-15 mt-1'>{moment(patientDetails.DateOfBirth).format('DD/MM/YYYY')}</span>
                  </Col>
              }
                  
                  <Col className='text-right'
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start', lineHeight: 1 }}
                  >
                    {patientDetails.HospitalNumber && <span className='text-primary text-15'>
                      {/* Hospital Number: &nbsp;&nbsp; */}
                      {patientDetails.HospitalNumber}</span>}
                    {patientDetails.NHSnumber && <span className='text-primary text-15 mt-1 '>
                      {/* NHS No:&nbsp;&nbsp; */}
                      {patientDetails.NHSnumber}</span>}
                  </Col>
                </Row>
              {/* //BREADCRUMBS COMPONENT START*/}
            </div>
            {/* <Outlet/> */}
            <div className="row">
              <div className="card pr-0 pl-0">
                <Routes>
                  <Route path={`/`} element={<ColposcopicImaging />} />
                </Routes>
              </div>
            </div>
            {/* <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== --> */}
          </div>
          {/* <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== --> */}
        <footer className="footer">
        Copyright 2023 © Irisoft Ltd.
      </footer>
      </div>

    </>
    
  )
}

export default StreamOnly;

