import React, { useEffect, useState } from 'react'
import { Button, Input } from 'reactstrap';
import '../../css/SystemManagement.css'; // Import your CSS file
import { ManagementOptionsLeft, removeUserSession, InitUserData } from '../../Utils/commonData';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CLOSE_MODAL, LOGOUT_USER, OPEN_MODAL } from '../../redux/actions/types';
import CustomModal from '../../component/Modals/CustomModal';
import { getUserDetails } from '../../redux/actions/data.action';
import { createUpdateUser, getUserAccessTypes } from '../../redux/actions/auth.action';







export default function SystemManagement() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { modal } = useSelector(({ ui }) => ui);
    const [users, setUsers] = useState([])
    const [accesses, setAccesses] = useState([])
    const [values, setValues] = useState(InitUserData)

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(createUpdateUser(values))
            .then((res) => {
                dispatch({ type: CLOSE_MODAL })
            })
            .catch(err => {
                console.log(err)
            })

    }

    const handleChanges = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }


    const handleClick = (prop) => {
        if (prop.path) {
            return navigate(prop.path)
        }


        dispatch({ type: OPEN_MODAL, payload: prop.id })

    }



    const handleExit = () => {
        removeUserSession();
        dispatch({ type: LOGOUT_USER })
        navigate(-1)
    }

    const handleGetUsers = () => {

        dispatch(getUserDetails())
            .then(res => {

                if (res) {
                    setUsers(res)
                }
            })
    }

    const getAccessTypes = () => {

        dispatch(getUserAccessTypes())
            .then(res => {
                if (res) {
                    setAccesses(res.data)
                }
            })
    }

    useEffect(() => {
        handleGetUsers()
        getAccessTypes()
    }, [])


    return (
        <>
            <CustomModal
                open={modal === 1}
                title={ManagementOptionsLeft[0].title}
            // onClose={() => dispatch({ type: OPEN_MODAL })}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <form onSubmit={handleSubmit}>

                        <div className='bordered p-2'>
                            <div className='row centered pl-3 pr-3'>
                                <label className="control-label text-left">Name</label>
                                {values.isNewUser ?
                                    <Input
                                        value={values.Name}
                                        onChange={handleChanges('name')}
                                    />
                                    :
                                    <Input
                                        size="sm"
                                        type="select"
                                        className="form-control"
                                        value={values.iUserID}
                                        onChange={(e) => {
                                            let user = users.find(a => a.iUserID == e.target.value);
                                            if (user) {
                                                setValues({ ...user, isNewUser: true })
                                            } else {
                                                setValues({})
                                            }

                                        }
                                        }
                                    >
                                        <option className='form-control' value={null}>Select</option>
                                        {users.length !== 0 && users.map((a, index) => {
                                            return (
                                                <option className='form-control' value={a.iUserID}>{a.Name}</option>
                                            )
                                        })}
                                    </Input>
                                }
                            </div>
                            <div className='row centered mt-2 pl-2 pr-2'>
                                <div className='col-md-6'>
                                    <label className="control-label text-left ml-2">Title</label>
                                    <Input
                                        size="sm"
                                        className='form-control'
                                        value={values.Title}
                                        onChange={handleChanges('Title')}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label className="control-label text-left ml-2">ID</label>
                                    <Input
                                        size="sm"
                                        className='form-control'
                                        value={values.ID}
                                        onChange={handleChanges('ID')}
                                    />
                                </div>


                            </div>
                            <div className='row centered  pl-3 pr-3 mt-2'>
                                <label className="control-label text-left">Quals</label>
                                <Input
                                    size="sm"
                                    className='form-control'
                                    value={values.Qualifications}
                                    onChange={handleChanges('Qualifications')}
                                />
                            </div>
                            <div className='row centered  pl-3 pr-3 mt-2'>
                                <label className="control-label text-left">Post</label>
                                <Input
                                    size="sm"
                                    className='form-control'
                                    value={values.Post}
                                    onChange={handleChanges('Post')}
                                />
                            </div>
                        </div>
                        <div className='bordered p-2 mt-3'>
                            <div className='row centered  pl-3 pr-3 mt-2'>
                                <label className="control-label text-left">Password</label>
                                <Input
                                    size="sm"
                                    type="password"
                                    className='form-control'
                                    value={values.Password}
                                    placeholder='******'
                                    onChange={handleChanges('password')}
                                />
                            </div>
                            <div className='row centered pl-3 pr-3 mt-2'>
                                <label className="control-label text-left">Trainer</label>
                                <Input
                                    size="sm"
                                    type="select"
                                    className="form-control"
                                    value={values.iUserID}
                                    onChange={(e) => {
                                        let user = users.find(a => a.iUserID == e.target.value);
                                        if (user) {
                                            setValues(user)
                                        } else {
                                            setValues({})
                                        }

                                    }
                                    }
                                >
                                    <option className='form-control' value={null}>Select</option>

                                </Input>
                            </div>

                            <div className='row centered  pl-3 pr-3 mt-2'>
                                <label className="control-label text-left">Access</label>
                                <Input
                                    size="sm"
                                    type="select"
                                    className="form-control"
                                    value={values.Access}
                                    onChange={handleChanges('Access')}
                                >
                                    <option className='form-control' value={null}>Select</option>
                                    {accesses.length !== 0 && accesses.map((a, index) => {
                                        return (
                                            <option className='form-control' value={a.iRef}>{a.sUserAccessLevel}</option>
                                        )
                                    })}
                                </Input>
                            </div>
                            <div className='row centered  pl-3 pr-3 mt-2'>
                                <label className="control-label text-left">Site</label>
                                <Input
                                    size="sm"
                                    className='form-control'
                                    value={values.SiteID}
                                    onChange={handleChanges('SiteID')}
                                />
                            </div>

                        </div>

                        <div className='mt-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {values.isNewUser ? < Button className='btn btnIri' style={{ width: '75px' }} onClick={() => setValues({ ...InitUserData, isNewUser: false })}>Clear</Button> : < Button className='btn btnIri' style={{ width: '75px' }} onClick={() => setValues({ ...InitUserData, isNewUser: true })}>New</Button>}
                            <div className='d-flex'>
                                < Button onClick={() => dispatch({ type: OPEN_MODAL })} className='btn color-black mr-2' style={{ width: '75px' }}
                                    type="submit"
                                >Save</Button>
                                < Button onClick={() => dispatch({ type: OPEN_MODAL })} className='btn color-black' style={{ width: '75px' }} >Cancel</Button>

                            </div>
                        </div>
                    </form>

                </div>
            </CustomModal>




















            <div className="system-container">
                <div className="system-wrapper">
                    <div className="bordered">
                        <h3 className='card-title system-title'>Colposcopy System Management Options</h3>
                        <div className="system-viewer pl-3 pr-3 row">
                            <div className='col-md-6 pl-2' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                                {ManagementOptionsLeft.map((a, index) => {
                                    return (
                                        <div div key={a.id} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                                            <Button
                                                size='sm'
                                                className='btnoption text-11'
                                                onClick={() => handleClick(a)}
                                            // disabled={entry === 'reportwriter'}
                                            >{a.button}</Button>
                                            <h6 className='col-md-9 mt-3 text-12 text-left'>{a.title}</h6>
                                        </div>
                                    )
                                })}

                            </div>
                            <div className='col-md-6 flex-grow-1' style={{ flexGrow: 1 }}>
                                {ManagementOptionsLeft.map(a => {
                                    return (
                                        <div className='d-flex centered flex-column'>
                                            <div className='centered'>
                                                {/*        <Button
                                    size='sm'

                                // onClick={handleEntry} 
                                // disabled={entry === 'reportwriter'}
                                >Add/Update</Button> */}
                                                <label className='col-md-8 mt-3'></label>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>


                        </div>
                        <br />
                        <div className='row mb-3 w-100'>
                            <div className='col-md-6 d-flex centered flex-column '>

                            </div>
                            <div className='col-md-6 d-flex centered '>
                                <Button
                                    size='sm'
                                    className='btnoption'
                                    onClick={handleExit}

                                // disabled={entry === 'reportwriter'}
                                >Exit</Button>
                                <label className='col-md-8 text-left text-12 mt-3'>Exit System Management</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
