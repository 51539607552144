import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.stickyHeader}`]: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    borderColor: '#000',
    borderWidth: 'thin',
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[300],
    fontWeight: 'bold',
    borderColor: '#000',
    borderWidth: 'thin',
    fontSize: 16
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#000',
    borderColor: '#000',
    borderWidth: 'thin',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
    backgroundColor: theme.palette.action.hover,
    borderColor: '#000',
    borderWidth: 'thin',
    whiteSpace: 'nowrap',


  [`&.${tableCellClasses}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
    borderColor: '#000',
    borderWidth: 'thin'
   
  },
}));


const StyledTable = styled(TableBody)(({ theme }) => ({
  maxHeight: '100px',
  overflowY: 'auto',
  [`&.${tableCellClasses.body}`]: {
    maxHeight: '50vh',
    backgroundColor: ' #fff', 
    overflowY: 'auto'
  },
}));


export default function CustomizedTables({rows, headers, selectedRow, onClick, onDblClick}) {
  const theme = useTheme();



  return (
    <TableContainer component={Paper}
    sx={{  width: '95%', alignSelf: 'center', justifySelf: 'center', justifyContent: 'center', maxHeight: '70vh', overflowY: 'auto' }}
    >
      <Table stickyHeader size="small" aria-label="customized table"
      >
        <TableHead
        // style={{backgroundColor: theme.palette.grey[300]}}
        >
          <TableRow>
          {headers.map(a => {
            return (
              <StyledTableCell style={a.style}  align={a.align}>{a.title}</StyledTableCell>
            )
          })}
           
          </TableRow>
        </TableHead>
        
        <TableBody
        style={{  backgroundColor: theme.palette.common.white, color: theme.palette.common.black }}
        className="trace-table p-0"
        >
          {rows.map((row, index) => (
            <StyledTableRow key={index}
              className={`trace-table-item ${selectedRow == index ? "trace-selected-item" :""}`}
              onClick={() => onClick(index)}
              onDoubleClick={() => onDblClick(index)}
            >
            {headers.map(a => {
            return (
              <StyledTableCell 
                
              align={a.align} style={a.style} component={a.component} scope={a.scope}>
              {String(a.field).toLowerCase().includes('date') ? moment(row[a.field]).format('DD/MM/YYYY')  : row[a.field]}
            </StyledTableCell>
            )
            })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      
    </TableContainer>
  );
}
