import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import Topbar from './scenes/global/TopBar';
import SideBar from './scenes/global/SideBar';
import Home from './Home';
import EnterPatient from './scenes/dashboard/patient/EnterPatient';
import TracePatient from './scenes/dashboard/patient/TracePatient';
import { Route, Routes, Navigate } from "react-router-dom";

import './css/dashboard.css';

import ExportBiopsy from './scenes/dashboard/ExportBiopsy';
import Examination from './scenes/dashboard/examination/Examination';
import ExaminationDetails from './scenes/dashboard/examination/ExaminationDetails';
import Management from './scenes/dashboard/managementDetails/Management';

import ReferralDetails from './scenes/dashboard/patient/ReferralDetails';
import TreatmentDetails from './scenes/dashboard/TreatmentDetails';
import Failsafe from './scenes/dashboard/Failsafe';
import Appointment from './scenes/dashboard/Appointment';
import Treatment from './scenes/dashboard/Treatment';
import Letters from './scenes/dashboard/Letters';
import UserProfile from './scenes/dashboard/UserProfile';
import LetterTemplate from './scenes/dashboard/letters/LetterTemplate';
import GenerateLetter from './scenes/dashboard/letters/GenerateLetter';
import Page404 from './scenes/dashboard/Page404';
import Bsccp from './scenes/dashboard/statistics/Bsccp/Bsccp';
import Automatic from './scenes/dashboard/statistics/Automatic';
import { useDispatch, useSelector } from 'react-redux';
import EnterOptions from './scenes/dashboard/EnterOptions';
import CaseReview from './scenes/dashboard/patient/CaseReview';
import ReviewOptions from './scenes/dashboard/ReviewOptions';
import Results from './scenes/dashboard/results/Results';
import Appointments from './scenes/dashboard/Appointments';
import SavePatientModal from './component/Modals/SavePatientModal';
import HospitalNumberModal from './component/Modals/HospitalNumberModal';
import NoAppointmentModal from './component/Modals/NoAppointmentModal';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import ColposcopicFindings from './scenes/dashboard/examination/ColposcopicFindings';
import ColposcopicImaging from './scenes/dashboard/examination/ColposcopicImaging';
import ColposcopicImagingReview from './scenes/dashboard/examination/ColposcopicImagingReview';
import CustomModal from './component/Modals/CustomModal';
import { CLEAR_ERRORS, CLOSE_MODAL } from './redux/actions/types';
import { getToken, removeUserSession } from "./Utils/commonData";
import axios from 'axios';
import { getAuthUser } from './redux/actions/auth.action';
import ReportLetters from './scenes/dashboard/letters/ReportLetters';
import StatisticsOptions from './scenes/dashboard/statistics/StatisticsOptions';
import QarcAudit from './scenes/dashboard/statistics/Qarc/QarcAudit';
import QarcGridView from './scenes/dashboard/statistics/Qarc/QarcGridView'
import QarcGenerateLetter from './scenes/dashboard/statistics/Qarc/QarcGenerateLetter'
import Manual from './scenes/dashboard/statistics/Manual';
import SystemManagement from './scenes/administrator/SystemManagement';
import { getFilePath } from 'redux/actions/file.action';
import { initiateSocketConnection } from 'Utils/socket.service';


const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientDetails } = useSelector(({ data }) => data)
  const { isAuthenticated } = useSelector(({ auth }) => auth)
  const { viewOption, modal, errors } = useSelector(({ ui }) => ui)
  const [isOpen, setIsOpen] = useState(false);

  
  const WithPatientRoute = ({ children, redirect, type }) => {
    // const authed = !getToken(); // isauth() returns true or false based on localStorage
    return (patientDetails.SystemID || ((patientDetails.HospitalNumber || patientDetails.NHSnumber) && type === 'enterPatient')) ? children : <Navigate to={redirect} />;
  }


  useEffect(() => {
    document.title = 'CUMPOSCOPE Digital Imaging (Colposcopy)';
    const token = getToken();
    if (!token) {
      removeUserSession();
      navigate('/login')
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    dispatch(getAuthUser())
      .then(res => {
        if (res && res.token) {
          dispatch(getFilePath({
            filePath: `/Templates`,
            isDirectory: true,
            name: 'Patient'
          }))
          dispatch(getFilePath({
            filePath: ``,
            isDirectory: true,
            name: 'Profiles'
          }))
          dispatch(getFilePath({
            filePath: `/Letters`,
            isDirectory: true,
            name: 'Qarc'
          }))
          // setAuthLoading(false);
          // navigate('/dashboard/home')
        } else {
          removeUserSession();
          navigate('/login')
          // setAuthLoading(false);
        }
      })

  }, [])


  useEffect(() => {   
  const token = getToken();
      if(isAuthenticated){
      console.log('CONNECT SOCKET', token)
        initiateSocketConnection(token);
      }
  }, [isAuthenticated])


  return (
    <>
      <div id="main-wrapper">
        <SavePatientModal />
        <HospitalNumberModal />
        <NoAppointmentModal />

        <CustomModal
          size="lg"
          open={modal === 'enteringpatient'}
          title="Entering Patient Details"
          onOkay={() => {
            dispatch({ type: CLOSE_MODAL })
            dispatch({ type: CLEAR_ERRORS })
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <i className="ti-alert  text-warning mr-3 fa-2x"></i>
              <div className='text-center'>
                <span style={{ fontSize: '13px' }}>This patient's details are currently being updated by {errors.Name}. Please try again later</span>
              </div>
            </div>
          </div>

        </CustomModal>

        {/* TOP BAR COMPONENT */}
        <Topbar isOpen={isOpen} setIsOpen={setIsOpen} />
        {/* TOP BAR COMPONENT */}

        <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />


        <div className="page-wrapper" onClick={() => setIsOpen(false)}>
          {/* <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== --> */}
          <div className="container-fluid" style={{ minHeight: '80vh' }}>
            {/* <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== --> */}

            <div className="page-titles">
                <Row className='pl-3 pr-3'>
              {patientDetails.SystemID &&
                <Col className='text-left'
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', lineHeight: 1 }}
                  >
                    <span className='text-primary text-15 text-left'>{patientDetails.Forename} {patientDetails.Surname}</span>
                    <span className='text-primary text-15 mt-1'>{moment(patientDetails.DateOfBirth).format('DD/MM/YYYY')}</span>
                  </Col>
              }
                  
                  <Col className='text-right'
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start', lineHeight: 1 }}
                  >
                    {patientDetails.HospitalNumber && <span className='text-primary text-15'>
                      {/* Hospital Number: &nbsp;&nbsp; */}
                      {patientDetails.HospitalNumber}</span>}
                    {patientDetails.NHSnumber && <span className='text-primary text-15 mt-1 '>
                      {/* NHS No:&nbsp;&nbsp; */}
                      {patientDetails.NHSnumber}</span>}
                  </Col>
                </Row>
              {/* //BREADCRUMBS COMPONENT START*/}
            </div>
            {/* <Outlet/> */}
            <div className="row">
              <div className="card pr-0 pl-0">
                <Routes>
                  <Route path="/" element={
                    <Navigate to="/dashboard/home" />
                  } />
                  <Route path="/home" element={<Home />} />
                  <Route path="/trace" element={<TracePatient />} />

                  <Route path="/viewPatient" element={
                    <WithPatientRoute
                      redirect="/dashboard"
                      type="viewPatient"
                    >
                      <ReviewOptions />
                    </WithPatientRoute>
                  } />

                  <Route path="/patient" element={

                    <EnterOptions />
                  } />


                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/referralDetails`} element={<ReferralDetails />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/exportBiopsy`} element={<ExportBiopsy />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/enterPatient`} element={<EnterPatient />} />

                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/examination/:examType`} element={<ExaminationDetails />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/examination`} element={<Examination />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/colposcopicFindings`} element={<ColposcopicFindings />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/colposcopicImaging`} element={<ColposcopicImaging />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/colposcopicImagingReview`} element={<ColposcopicImagingReview />} />

                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/treatment`} element={<Treatment />} />

                  {/* TEST SCREENS */}
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/casereview`} element={<CaseReview />} />


                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/management`} element={<Management />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/management/datasheet`} element={<Management />} />


                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/treatmentDetails`} element={<TreatmentDetails />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/results`} element={<Results />} />
                  <Route path={`${viewOption === 'enter' ? '/patient' : '/viewPatient'}/appointment`} element={<Appointment />} />
                  <Route path="/letters" element={<Letters />} />
                  <Route path="/user-profile" element={<UserProfile />} />
                  <Route path="/letter-template" element={<LetterTemplate />} />
                  <Route path="/generate-letter" element={<GenerateLetter />} />
                  <Route path="/letter-reports/:actionType" element={<ReportLetters />} />
                  <Route path="/treatment" element={<Treatment />} />
                  <Route path="/appointments" element={<Appointments />} />
                  <Route path="/failsafe" element={<Failsafe />} />
                  <Route path="/statistics" element={<StatisticsOptions />} />
                  <Route path="/statistics/qarc" element={<QarcAudit />} />
                  <Route path="/statistics/qarc/export" element={<QarcGenerateLetter />} />
                  <Route path="/statistics/qarc/:code" element={<QarcGridView />} />
                  <Route path="/statistics/bsccp" element={<Bsccp />} />
                  <Route path="/statistics/automatic" element={<Automatic />} />
                  <Route path="/statistics/manual" element={<Manual />} />
                  <Route path="/documentation" element={<Home />} />
                  <Route path="/faqs" element={<Home />} />
                  <Route path="/testPage" element={<ColposcopicImaging />} />
                  <Route path="/systemmanagement" element={<SystemManagement />} />
                  <Route path='/404' exact={true} element={<Page404 />} />
                  <Route path='*' exact={true} element={<Page404 />} />
                </Routes>
              </div>
            </div>
            {/* <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== --> */}
          </div>
          {/* <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== --> */}
        </div>
        <footer className="footer">
        Copyright 2023 © Irisoft Ltd.
      </footer>
      </div>

    </>
    
  )
}

export default Dashboard;

