import React, { useEffect, useState } from 'react';
import './css/login.css';
import { useNavigate, NavLink, useSearchParams } from "react-router-dom";
import { useDispatch} from 'react-redux';
import {  loginUser } from './redux/actions/auth.action';
import { getUserDetails } from './redux/actions/data.action';
import ForgotPasswordModal from './component/Modals/ForgotPasswordModal';


const Login = (props) => {
  const dispatch = useDispatch()
  const [queryParameters] = useSearchParams()
  const navigate = useNavigate();
  let [Username, setUsername] = useState('');
  let [Password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([])
  const [count, setCount] = useState(0)

  const handleSetup = () => {
    if(count > 5) {
      setCount(0)
      localStorage.removeItem('isSetup')
       navigate('/setup');
    } else {
      setCount(count + 1);
    }
  }

  const handleLSubmit = (e) => {
    e.preventDefault()
    setError(null);
    setLoading(false);
    // this.props.history.push('/dashboard');
    // let history = useHistory();
    dispatch(loginUser({
      Username: Username,
      Password: Password
    }))
      .then((res) => {
        if (res && res.token) {
          setLoading(false);
          return navigate('/dashboard/home');
        }
        if (res && res.message) {
          setError(res.message)
        }
      })
  }

  const handleGetUsers = () => {

    dispatch(getUserDetails())
      .then(res => {
        if (res) {
        console.log(res, 'ress')
          setUsers(res)
        }
      })
  }

  useEffect(() => {
    // document.title = 'CUMPOSCOPE Digital Imaging (Colposcopy)';
    handleGetUsers()
    if(queryParameters.get("session") === 'expired'){
      setError('Session Expired!')
    }
  }, [])
  
  

console.log(users, 'userss')
  return (
    <>
    <ForgotPasswordModal/>
      <div className='login-register'>
        <div className='login-box'>
          <div className='card-body'>
            <img src={require('./img/logo_03.png')} className='img-fluid appLogo' alt='Logo' onClick={() => handleSetup()} />
            <div className='clearfix'></div>
            <form className="form-horizontal form-material form-horizontal-appBoxShadow" id="loginform" onSubmit={handleLSubmit} >

              <div className="form-group ">
                <div className="col-xs-12">
                  <select
                    className="form-control"
                    type="text"
                    required=""
                    placeholder="Username"
                    value={Username}
                    onChange={e => setUsername(e.target.value)}
                  >
                    <option value='' disabled >
                      Username
                    </option>
                    {users.length !== 0 && users.map(a => {
                      return (
                        <option key={a.Name}  value={a.Name}>
                          {a.Name}
                        </option>
                      )

                    })}

                  </select> </div>
              </div>
                <div className="form-group">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="password"
                      required=""
                      placeholder="Password"
                      value={Password}
                      onChange={e => setPassword(e.target.value)}
                    /> </div>
                </div>
              <div className="form-group row">
                <div className="col-md-12 d-flex ">
                    {/* <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                        <label className="custom-control-label" for="customCheck1">Remember me</label> */}
                    <NavLink 
                    // onClick={() => dispatch({type: OPEN_MODAL, payload:  'forgotPassword'})}
                    id="to-recover" className="text-dark text-left" style={{fontSize: '13px'}}>
                    <i className="fa fa-lock pt-1 mr-2"></i>
                    Forgot password - <a className='link text-info'><em>Contact your Administrator</em></a>
                    </NavLink>
                </div>
              </div>
              <div className="form-group text-center">
                <div className="col-xs-12 p-b-20">
                  {/* <button 
                    className="btn btn-block btn-lg btn-appColor btn-rounded"
                    type="submit" 
                    disabled={loading} 
                    onClick={handleLogin}
                    >
                      {loading ? "Loading...": "Login"}
                    </button> */}
                  <input
                    type="submit"
                    className="btn btn-block btn-lg btn-appColor btn-rounded btnIri"
                    disabled={loading}
                    value={loading ? "Loading..." : "Login"}

                    onClick={handleLSubmit}
                  />
                </div>
              </div>
              {error && <p className='alert  alert-danger'>{error}</p>}
              {/* <div className="form-group m-b-0">
                <div className="col-sm-12 text-center">
                  Don't have an account? <NavLink to="/" className="text-appColor m-l-5">Sign Up</NavLink>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  )

}

export default Login;
