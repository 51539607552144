import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_LOADING, OPEN_MODAL, STOP_LOADING, SET_NETWORK } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormFeedback, Row, Col, Card, CardHeader, CardTitle, CardBody, CardText, CardFooter } from 'reactstrap';
import { useState } from 'react';
import { getStreamData, postStreamAction, streamLogin } from 'redux/actions/stream.actions';
import { Box } from '@mui/material';
import Switch from '@mui/material/Switch';



export default function StreamModal({ type, selected, setSelected }) {
    const dispatch = useDispatch()
    // const navigate = useNavigate();
    const { modal, errors, loading } = useSelector(({ ui }) => ui)
    const {network, stream } = useSelector(({ auth }) => auth)
    const [isForm, setIsForm] = useState(false);
    const [values, setValues] = useState({})
  
    const handleChanges = prop => event => {
    
        setValues({...values, [prop]: event.target.value})
    }


  const handleStreamData = () => {
    dispatch(getStreamData())
  }
  
  const handleDelete = (row) => {
    dispatch({type: SET_LOADING})
      dispatch(postStreamAction(`method=del-server&id=${row.id}`))
      setTimeout(() => {
        handleStreamData()
        dispatch({type: STOP_LOADING})
    }, 7000)
  }



    const handleLogin = (e) => {
        e.preventDefault();
        dispatch({type: SET_LOADING})

        dispatch(streamLogin(values))
        .then(() => {
        setTimeout(() => {
          handleStreamData()
          dispatch({type: STOP_LOADING})
        }, 7000)
        })

    }
    
    const handleEnable = async (row) => {
      let isUse = row['is-use'] ? 0 : 1;
      await dispatch(postStreamAction(`method=enable-server&id=${row.id}&is-use=${isUse}`))
      setTimeout(() => {
        handleStreamData();
        setIsForm(false)
    }, 7000)
    
  }

    const handleEdit = (val) => {
        
        if(val){
            setValues(val);
        } else {
          setValues({
            id: null
          })
        }
        
        setIsForm(true)
    
    }


    const handleSave = async () => {
       dispatch({type: SET_LOADING}) 
       if(values.id){
      await  dispatch(postStreamAction(`method=set-server&id=${values.id}&type=0&name=${values.name}&url=${values.url}&key=${values.key}&user=${values.user}&passwd=${values.passwd}&is-auth=1&token=?&event-data=?&net-mode=1`))
       } else {
      await  dispatch(postStreamAction(`method=add-server&type=0&name=${values.name}&url=${values.url}&key=${values.key}&user=${values.user}&passwd=${values.passwd}&is-auth=1&token=?&event-data=?&net-mode=1`))
       }
           
      setTimeout(() => {
          handleStreamData();
          setIsForm(false)
          dispatch({type: STOP_LOADING})

      }, 7000)
    }
  


    const toggle = () => {
        dispatch({ type: OPEN_MODAL, payload: null });
        dispatch({type: STOP_LOADING})

    }
    
    
    useEffect(() => {
      handleStreamData()
    }, [modal, isForm]);
    
    useEffect(() => {
    if(network){
      setSelected(network)
    }
    }, [network])
    
    
    const renderServerList = (arr) => {
    
    
      return (
        <div>
        {arr.length !== 0 ? arr.map( (row, index) => {
          return (
            <Card
              key={index}
              
            // className="my-2"
            style={{
              // height: '200px',
              borderWidth: '1px',
            }}
          >
            <CardHeader style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            className={`clickable ${selected?.id == row.id ? "btnIri" : ""}`}
            onClick={() => {!row["is-use"] && handleEnable(row); setSelected(row)}}
            >
          <div>
           <strong>Name:</strong> {row.name}
          </div>
                <div>
            RTMP
            </div>
            </CardHeader>
            <Box className="clickable" onClick={() => {!row["is-use"] && handleEnable(row); setSelected(row)}}  p="5 " style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', minHeight: '100px'}}>
              <div style={{m: 1, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <CardTitle style={{width: '30%', display: 'flex', justifyContent: 'flex-end'}} >
               Stream Url:
              </CardTitle>
              <CardText style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
                {row.url}
              </CardText>           
              </div>
           <div style={{m: 1, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <CardTitle style={{width: '30%', display: 'flex', justifyContent: 'flex-end'}} >
               key:
              </CardTitle>
              <CardText style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
                {row.key}
              </CardText>           
              </div>
              <div style={{m: 1, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <CardTitle style={{width: '30%', display: 'flex', justifyContent: 'flex-end'}} >
               Username:
              </CardTitle>
              <CardText style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
                {row.user}
              </CardText>           
              </div>
              <div style={{m: 1, width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <CardTitle style={{width: '30%', display: 'flex', justifyContent: 'flex-end'}} >
               Password:
              </CardTitle>
              <CardText style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
                {row.passwd}
              </CardText>           
              </div>
            </Box>
            <CardFooter style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div> <strong>{row["is-use"] ? 'Enabled' : 'Disabled'}</strong> <Switch
                checked={row["is-use"]}
                onChange={() => handleEnable(row)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              </div>
           
            <div>
            <Button
              onClick={() => handleDelete(row)}
              disabled={loading}
            >
            Delete
        </Button>&nbsp;&nbsp;&nbsp;
            <Button className="btnIri"
            onClick={() => { handleEdit(row); setIsForm(true)}}
            >
              Edit
          </Button>
          </div>
            </CardFooter>
          </Card>
          
          )
        })
        :
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h5>No Server Here</h5>
        </div>
        }
       
        </div>
      )
    }
     
    
    

    return (
    <>
        <Modal
            isOpen={modal === 'StreamModal'}
        toggle={toggle}
        >
        {!stream.isAuth ? 
        <form onSubmit={handleLogin}>
        <ModalHeader className='btnIri'>Streaming Server</ModalHeader>
        <ModalBody>
        <div style={{ display: 'flex', width: '100%' }}>
        <div className='pl-3 pr-3 pb-3 bordered w-100'>
          <h3 className="card-title txtLeft bordered-title">Stream Box Login</h3>
          <div className="form-input">
            <Label for="firstName" >
              Username <span className="text-danger">*</span>
            </Label>
            <Input
              type="text" id="username" className="form-control" placeholder="" required
              value={values.username}
              onChange={handleChanges('username')}
              invalid={errors.username}
            />
            <FormFeedback>
              {errors.username}
            </FormFeedback>
          </div>
          <div className="form-input">
            <label className="control-label text-blue">Password</label>
            <input type="text" id="password" className="form-control" placeholder=""
              value={values.password}
              onChange={handleChanges('password')}
            />
          </div>
        
          <div className="form-input">
            <label className="control-label text-blue">Stream Device IP</label>
            <input type="text" id="boxIp" className="form-control" placeholder=""
              value={values.boxIp}
              onChange={handleChanges('boxIp')}
            />
          </div>
        </div>
      </div>
          </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle} disabled={loading}>Close</Button>{' '}
                <Button color="primary" className="btnIri" type='submit' disabled={loading}>Login</Button>
            </ModalFooter>
        </form> : isForm ? 
        <>
            <ModalHeader className='btnIri'>Streaming Server</ModalHeader>
            <ModalBody>
            <div  style={{ display: 'flex', width: '100%' }}>
            <div className='pl-3 pr-3 pb-3  bordered'>
              <h3 className="card-title txtLeft bordered-title">Edit Server</h3>
            
              <div className="form-input">
                <Label for="firstName" >
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text" id="name" className="form-control" placeholder="" required
                  value={values.name}
                  onChange={handleChanges('name')}
                  invalid={errors.name}
                />
                <FormFeedback>
                  {errors.name}
                </FormFeedback>
              </div>
              <div className="form-input">
                <label className="control-label text-blue">URL</label>
                <input type="text" id="url" className="form-control" placeholder=""
                  value={values.url}
                  onChange={handleChanges('url')}
                />
              </div>
              <div className="form-input">
                <label className="control-label text-blue">Stream Key</label>
                <input type="text" id="key" className="form-control" placeholder=""
                  value={values.key}
                  onChange={handleChanges('key')}
                />
              </div>
              <br/><br/>
              <h6>Authentication</h6>
           
             
              <Row>
                <Col>
                  <div className="form-input">
                    <label className="control-label  text-blue">Username</label>
                    <input type="text" id="Occupation" className="form-control" placeholder=""
                      value={values.user}
                      onChange={handleChanges('user')}
                    />
                  </div>
                </Col>
                <Col>

                  <div className="form-input">
                    <label className="control-label  text-blue">Password</label>
                    <input type="text" id="firstName" className="form-control" placeholder=""
                      value={values.passwd}
                      onChange={handleChanges('passwd')}
                    />
                  </div>
                </Col>
              </Row>
   
            </div>
          </div>
            </ModalBody>
            <ModalFooter>
            
                <Button color="secondary" onClick={() => {setIsForm(false); setValues({})}} disabled={loading}>Cancel</Button>  {' '}
                <Button color="primary" className="btnIri" onClick={() => handleSave('')} disabled={loading}>Save</Button>
            </ModalFooter>
            </> : <>
                   <ModalHeader className='btnIri'>Streaming Server</ModalHeader>
            <ModalBody>
              {renderServerList((stream.settings && stream.settings["stream-server"]) ? stream.settings["stream-server"] : [])}
            </ModalBody>
               <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button color="primary" className="btnIri" onClick={() => handleEdit(null)} disabled={loading}>Add Server</Button>{' '}
                <Button color="secondary" onClick={toggle} disabled={loading}>Close</Button>
            </ModalFooter>
            </>
                  }
        </Modal>
        </>
    )
}
