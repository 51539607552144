import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTheme, useMediaQuery } from '@mui/material';
import '../../../css/statistics.css';

const BarGraph = ({data}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const options = {
    scales: {
      x: {
        type: 'category', // Specify the scale type as 'category' for the x-axis
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div
    className={`${isSmallScreen ? "graph-view-m graph-view" : "graph-view"}`}
    style={{ width: isSmallScreen ? '200%' : '90%'}}>
      <Bar data={data} options={options} 
      // width="200px"
      />
  </div>
    
  );
};

export default BarGraph;
