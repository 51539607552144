import moment from 'moment';
import { referralReasonLetterData } from "./commonData";

export const reasonLetterSummary = (key, HPV) => {
    let str = null;
    let hasVal = referralReasonLetterData.find(a => a.key === key);
    if (!hasVal) {
        str = `...referred with no smear ${HPV ? 'with high risk HPV' : ''}`;
    } else {
        str = `...referred ${hasVal.value}  ${HPV ? 'with high risk HPV' : ''}`;
    }

    return str;
}

export function manipulateSentence(originalSentence, stringProp, removeString) {



    // Remove any trailing period, comma, and extra space
    let cleanedSentence = originalSentence.replace(/[.,\s]+$/, '');

    if (removeString) {
        // Remove the string and add a period at the end
        cleanedSentence = cleanedSentence.replace(new RegExp(stringProp + ',? '), '').replace(` ${stringProp}`, '');

    } else {
        // Add the string and a comma with extra space at the end
        cleanedSentence += (cleanedSentence.length > 0 ? ', ' : '') + stringProp;
    }

    // Add a period at the end
    cleanedSentence += '.';

    if (cleanedSentence.includes('no abnormality') && !removeString) {
        cleanedSentence = manipulateSentence(cleanedSentence, 'no abnormality', true);
    }

    if (cleanedSentence == '...Colposcopy examination revealed.') {
        cleanedSentence = '...Colposcopy examination revealed no abnormality.';
    }





    return cleanedSentence;
}

export function generateCode() {
    const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const generateRandomLetter = () => String.fromCharCode(getRandomInt(65, 90)); // ASCII codes for A to Z

    const generateRandomDigit = () => getRandomInt(0, 9);

    let code = '';

    // Generate 9 random digits
    for (let i = 0; i < 9; i++) {
        code += generateRandomDigit();
    }

    // Add 1 random capital letter
    code += generateRandomLetter();

    return code;
}

export function generateMotivationalPassword() {
    const motivationalWords1 = [
        "inspire", "dream", "believe", "achieve", "persevere", "succeed", "thrive", "victory", "overcome", "conquer",
        "ambition", "dedication", "optimism", "persistence", "commitment", "potential", "challenge", "focus", "resilience", "passion",
        "innovation", "empower", "imagine", "aspire", "transform", "excellence", "growth", "courage", "strength", "determination",
        "goals", "success", "hustle", "positivity", "grit", "drive", "inspiration", "belief", "uplift", "faith", "vibrant",
        "pursue", "motivate", "rise", "thrive", "vibrant", "endurance", "triumph", "vision"
    ];

    const motivationalWords2 = [
        "focus", "challenge", "strength", "passion", "courage", "resilience", "commitment", "purpose", "perseverance", "achievement",
        "dedication", "innovation", "empowerment", "optimism", "persistence", "potential", "drive", "inspiration", "belief", "uplift",
        "faith", "vibrancy", "excellence", "growth", "goals", "success", "hustle", "positivity", "grit", "transformation", "dream",
        "ambition", "determination", "perseverance", "triumph", "victory", "overcome", "conquer", "challenge", "focus", "resilience",
        "passion", "vision", "inspiration", "belief", "uplift", "thrive", "endurance", "motivation", "rise"
    ];

    const getRandomElement = (array) => array[Math.floor(Math.random() * array.length)];

    const password = getRandomElement(motivationalWords1) + getRandomElement(motivationalWords2);

    return password;
}

export function groupByField(arr, field) {
    let grped = arr.reduce((grouped, item) => {
        const value = item[field];

        if (!grouped[value]) {
            grouped[value] = {
                ...item,
                data: [],
                title: value
            }
        }

        grouped[value].data.push(item);

        return grouped;
    }, {});

    let newGrpd = []

    Object.entries(grped).map(([key, value]) => {
        newGrpd.push(value);
    })

    return newGrpd;
}


export function formatReportData({ patientDetails, personalReferralDetails, examination, examinationDetails, referralReasons, treatmentDetails, managementDetails, patientAssociatedSymptoms }) {
    const { tbl_UserDetail } = examinationDetails ? examinationDetails : {};

    let docData = {
        "TreatmentDate": moment(managementDetails.TreatmentDate).format('MM/DD/YYYY'),
        "HospitalNumber": patientDetails.HospitalNumber,
        "NHSNumber": patientDetails.NHSnumber,
        "TodayDate": moment().format('MM/DD/YYYY'),
        "GPNameandAddress": `${personalReferralDetails.GPName} ${personalReferralDetails.GPAddress}`,
        "PatientForename": patientDetails.Forename,
        "PatientSurname": patientDetails.Surname,
        "PatientDoB": moment(patientDetails.DateOfBirth).format('MM/DD/YYYY'),
        "TreatmentType": "Test Treatment Type",
        "ifTreatmentBiopsiesTaken": "true",
        "ColposcopistName": "Test Colposcopist Name",
        "ColposcopistPost": "Test Colposcopist Post",
        "ReferrerName": "Test Referrer Name",
        "ReferrerAddress": "Test Referer Address",
        "DNADate1": "12/11/2023",
        "UserName": tbl_UserDetail ? tbl_UserDetail.Name : "",
        "UserQualification": tbl_UserDetail ? tbl_UserDetail.Qualifications : "",
        "UserPost": tbl_UserDetail ? tbl_UserDetail.Post : "",
        "UserPost2": tbl_UserDetail ? tbl_UserDetail.Post2 : "",
        "ChartNumber": patientDetails.HospitalNumber,
        "TreatmentBiopsyResults": "Test Treatment Biopsy Results",
        "GPName": personalReferralDetails.GPName,
        "ExaminationDetails": "Test Examination Details",
        "ColposcopicOpinion": "Test Colposcopic Opinion",
        "PunchBiopsyResults": "Test PunchTreatmentBiopsy Results",
        "SmearResults": "Test Smear Results",
        "ClinicType": "Test Clinic Type",
        "ClinicMonths": "Test Clinic Months",
        "ReferralReasons": "Test Referral Reasons",
        "TreatmentStatus": "Test Treatment Status",
        "ComplicationsTextBox": "Test Complications TextBox",
        "SmearMonths": "'3','6','12' Smear Months 3/6/12",
        "AppointmentDate": "10/23/2023",
        "AppointmentTime": "12:45",
        "ContactName": "Test Contact Name",
        "PunchBiopsyType": "Test PunchBiopsy Type",
        "3MonthSmearResults": "Test 3 Month Smear Results",
        "6MonthSmearResults": "Test 6 Month Smear Results",
        "12MonthSmearResults": "Test 12 Month Smear Results",
        "SwabResults": "Test Swab Result",
        "TreatmentComplications": "Test Treatment Complications From Treatment Details screen",
        "TreatmentConsent": "Test Treatment consent",
        "TreatDate": "Treatment Date Short format",
        "TreatmentLenghtTime": "Test Treatment Time Displays length or 'Unknown",
        "TreatmentLesionSize": "Test Treatment Lesion Size",
        "TreatmentPain": "Test Treatment Pain",
        "TreatmentReason": "Test Treatment Method details",
        "PatientPPSNumber": "Test PPS Number as Displayed on Patient Personal Details",
        "PatientCSPID": "Test CSPID as displayed on Patient Personal details",
        "PatientPIDNumber": "Test PID Number as displayed",
        "DNANoOfTimes": "Test Displays more than on the letter if the DNA count is more than three",
        "FirstAppointmentClinic": "Test Displays the Patient's Clinic for their first appointment",
        "ifPunchBiopsiesAndSmearsTaken": "true",
        "ifPunchBiopsiesOnlyTaken": "true",
        "ifSmearsOnlyTaken": "true",
        "ifSwabsTaken": "True",
        "if6MonthSmearsTaken": "True",
        "if12MonthSmearsTaken": "True",
        "ifAnySamplesTaken": "True",
        "ifInfectionTrichomonas": "True",
        "ifInfectionCandida": "True",
        "ifInfectionChlamydia": "True",
        "ifInfectionBacterialVaginosis": "True",
        "ifClinicMonthsSet": "True",
        "ifClinicMonthsNotSet": "True",
        "ifDNANewAppointment": "True",
        "ifDNAFollow-Up": "True",
        "ifCompleteExcision": "True",
        "ifIncompleteEcto": "True",
        "ifIncompleteEndo": "True",
        "ifHPVPositive": "True",
        "ifHPVNegative": "True",
        "ifAnaestheticGeneral": "True",
        "ifAnaestheticLocal": "True",
        "ifClinicColposcopy": "true",
        "ifClinicNurseSmear": "true",
        "ifInfectionAny": "true",
        "ifInfectionNotCandida": "true",
        "ifInfectionNone": "true",
        "ifInfectionOther": "true",
        "ifPunchBiopsiesTaken": "true",
        "ifPunchBiopsiesNotTaken": "true",
        "ifSmearsTaken": "true",
        "ifTreatmentRequired": "true",
        "ifSmearAttendDate": "true",
        "ifColpAttendDate": "true",
        "ifCervixTreatmentNonAttendDate": "true",
        "ifCervixSmearNonAttendDate": "true",
        "ifDNADates": "true",
        "ifDNA": "true",
        "ifClinicNursesSmearNotDNA": "true",
        "ifClinicColposcopyNotDNA": "true",
        "ifNOTDNA": "true",
        "if2ConsecuitiveDNA's": "true",
        "FirstAppointmentName": "Test First Appointment Name",
        "ExamOrFollowUpDateAsHeading": "Test Exam Or Follow Up As Heading",
        "ExaminationsOrFollowUpDate": "Test Examinations Or Follow Up Date",
        "PatientAge": moment().diff(patientDetails.DateOfBirth, 'years'),
        "PatientChartNumber": "123123",
        "PatientOccupation": "Test Patient Occupation",
        "PatientReferredBy": "Test Patient Referred By",
        "ifReReferralAdditionalDetails": "true",
        "AbnormalBleeding": "Test Abnormal Bleeding",
        "VaginalDischarge": "Test Vaginal Discharge",
        "LMP": "Test Lmp",
        "Cycle": "Test Cycle",
        "Contraception": "Test Contraception",
        "Parity+": "Test Parity+",
        "RiskFactor": "Test Risk Factor",
        "GynaecologicalCondition": "Test Gynaecological Condition",
        "GynaecologicalSurgery": "Test Gynaecological Surgery",
        "FreeTextAdditionalDetails": "Test Free Text Additional Details",
        "NonGynaecologicalInformation": "Test NonGynaecological Information",
        "ifNotContinue": "true",
        "ifContinue": "true",
        "Cervix": "Test Cervix",
        "CervicalLesion": "Test Cervical Lesion",
        "VaginalLesion": "Test Vaginal",
        "GynaecologicalExamination": "Test Gynaecological Examination",
        "SCJ": "Test SCJ",
        "SpecificLesion": "Test Specific Lesion",
        "BiopsiesTaken": "Test Biopssies Taken",
        "SmearsTaken": "Test Smears Taken",
        "SwabTaken": "Test Swab Taken",
        "ColposcopicOpinionCervix": "Test ColposcopicOpinion Cervix",
        "ColposcopicOpinionVagina": "Test ColposcopicOpinion Vagina",
        "ManagementPlan": managementDetails.ManagementPlan,
        "GPAddress1": personalReferralDetails.GPAddress,
        "GPAddress2": "Test GPAddress 2",
        "GPAddress3": "Test GPAddress 3",
        "GPAddress4": "Test GPAddress 4",
        "PatientName": `${patientDetails.Forename} ${patientDetails.Surname}`,
        "PatientAddress": patientDetails.Address
    }

    return docData
}

export function baseToFormData(base64, filename) {

    return fetch(base64)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], filename);
            return file
        });

}


export function generateDateRanges() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0 indexed, so add 1

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const ranges = [];

    // Generate options for current year until current month
    for (let month = currentMonth - 1; month >= 0; month--) {
        ranges.push(`${currentYear} ${monthNames[month]}`);
    }

    // Generate options for quarters
    for (let quarter = Math.ceil(currentMonth / 3) - 1; quarter > 0; quarter--) {
        const startMonth = quarter * 3 - 1;
        const endMonth = startMonth - 2;
        ranges.push(`${currentYear} ${monthNames[startMonth]} - ${monthNames[endMonth]}`);
        ranges.push(`${currentYear} ${monthNames[startMonth].slice(0, 3)} - ${monthNames[endMonth]}`);
    }

    // Generate options for last year
    ranges.push("Last Year");
    for (let month = 11; month >= 0; month--) {
        ranges.push(`${currentYear - 1} ${monthNames[month]}`);
    }

    // Generate options for quarters in last year
    for (let quarter = 3; quarter > 0; quarter--) {
        const startMonth = quarter * 3 - 1;
        const endMonth = startMonth - 2;
        ranges.push(`${currentYear - 1} ${monthNames[startMonth]} - ${monthNames[endMonth]}`);
        ranges.push(`${currentYear - 1} ${monthNames[startMonth].slice(0, 3)} - ${monthNames[endMonth]}`);
    }

    // Generate options for previous years
    for (let year = 2022; year < currentYear - 1; year++) {
        ranges.push(year.toString());
    }

    return ranges;
}



export function createQuarterDateRanges() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are 0 indexed, so add 1

    const dateRanges = [];

    // Add options for the last two fiscal years
    dateRanges.push(`${year - 1} April - ${year} March`);
    dateRanges.push(`${year - 2} April - ${year - 1} March`);

    // Add options for each quarter
    for (let i = 1; i <= 4; i++) {
        const quarterStartMonth = i != 4 ? (i * 3) + 1 : 1; // Calculate start month of the quarter
        
        const quarterEndMonth = quarterStartMonth + 3; // Calculate end month of the quarter

        let quarterYear = year - Math.abs(month < quarterStartMonth); // Adjust year based on current month
        dateRanges.push(`${quarterYear} ${getQuarterName(i)} Qtr ${getMonthName(quarterStartMonth)} - ${getMonthName(quarterEndMonth - 1)}`);
    }

    // Add options for individual months
    for (let i = 1; i <= 12; i++) {
        dateRanges.push(`${year - Math.abs(month < i)} ${getMonthName(i)}`);
    }

    return dateRanges;
}

function getQuarterName(quarter) {
    switch (quarter) {
        case 1:
            return '1st';
        case 2:
            return '2nd';
        case 3:
            return '3rd';
        case 4:
            return '4th';
        default:
            return '';
    }
}

export function getMonthName(month) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[month - 1];
}


export function getInitials(name) {
    // Split the name into words
    const words = name.split(' ');

    // Initialize an empty string to store the initials
    let initials = '';

    // Iterate over each word
    for (const word of words) {
        // Add the first character of each word to the initials string
        initials += word.charAt(0).toUpperCase();
    }

    return initials;
}

function getDaysInMonth(monthName) {
    // Create a date object with the provided month name and the 1st day of the month
    const date = new Date(`${monthName} 1, 2000`);
    // Move to the next month to get the first day of the next month
    date.setMonth(date.getMonth() + 1);
    // Subtract one day to get the last day of the provided month
    date.setDate(date.getDate() - 1);
    // Return the day of the month (number of days)
    return date.getDate();
}

export function getDateRange(index) {
    const dateRanges = createQuarterDateRanges();
    const dateRange = dateRanges[index];
    const yearRegex = /\d{4}/g; // Regular expression to match year (four digits)
    const years = dateRange.match(yearRegex);
    const dateSplt = String(dateRange).split('-');
    if (years.length === 2) {
            
        const startDate = years[0] + '-' + getMonthNumber(dateSplt[0].match(/[A-Za-z]+/)[0]) + '-01';
        const endDate = years[1] + '-' + getMonthNumber(dateSplt[1].match(/[A-Za-z]+$/)[0]) + '-' + getDaysInMonth(dateSplt[1].match(/[A-Za-z]+$/)[0]);
        return [startDate, endDate];
    } else {
        // Handle individual months
        let month1 = dateSplt[0].split(' '); 
        let month2 = dateSplt[1];
        
        const year = years[0];
        const month = month2 ? month1[3] : dateSplt[0];

        const startDate = year + '-' + getMonthNumber(month.match(/[A-Za-z]+/)[0]) + '-01';
        const endDate = year + '-' + getMonthNumber(month2 ? month2.match(/[A-Za-z]+/)[0] :  month.match(/[A-Za-z]+/)[0]) + '-' + getDaysInMonth(month2 ? month2.match(/[A-Za-z]+/)[0] :  month.match(/[A-Za-z]+/)[0]);
        return [startDate, endDate];
    }
}


function getMonthNumber(monthName) {
    const months = {
        January: '01', February: '02', March: '03', April: '04', May: '05', June: '06',
        July: '07', August: '08', September: '09', October: '10', November: '11', December: '12'
    };
    // Convert the month name to title case to match the keys in the months object
    const formattedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();
    return months[formattedMonthName];
}

