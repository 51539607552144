import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { env_vars } from 'Utils/config';
import Profile_Img from 'assets/default.png';
let { azure_storage_url} = env_vars;



const Topbar = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(({ auth }) => auth);
    const navigate = useNavigate();
    const { searchString } = useSelector(({ data }) => data);
    const [searchStr, setSearchStr] = useState('');


    const addClassSidebar = () => {
        console.log('addClassSidebar')
        // document.body.classList.toggle('mini-sidebar', isOpen);
        if (document.body.classList.contains('mini-sidebar')) {
            document.body.classList.remove('mini-sidebar');
        } else {
            document.body.classList.add('mini-sidebar');
        }
    }

    const addClassSidebar2 = () => {
        console.log('addClassSidebar2');

    }


    useEffect(() => {
        setSearchStr(searchString)
    }, [searchString])
    
    
    console.log(user, 'USER')
    return (
        <>
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-lg navbar-dark">
                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/dashboard">
                            <img src={require('../../img/dashboard_03.png')} alt="homepage" className="dark-logo img-fluid" />
                        </Link>
                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item"> <a className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" onClick={(e) => setIsOpen(!isOpen)}><i className="ti-menu"></i></a> </li>
                        </ul>

                        <div className="navbar-nav" style={{ alignItems: 'center' }}>
                            <div className="nav-item dropdown u-pro">
                                <Link to="/dashboard/user-profile" className="nav-link dropdown-toggle waves-effect waves-dark profile-pic" data-toggle="dropdown" >
                                    <img 
                                    src={user.imgUrl ? azure_storage_url + user.imgUrl : Profile_Img} alt={azure_storage_url + user.imgUrl}
                                    className="" />
                                    <span className="hidden-md-down"> {user.Name}
                                    </span>
                                </Link>
                            </div> &nbsp;
                            
                            <div className="nav-item dropdown u-pro">
                           {user.Access == 1 &&  <Link to="/dashboard/systemmanagement" className="nav-link  waves-effect waves-light center">
                            <i className="ti-settings"></i>
                            </Link>}
                            </div>
                        </div>
                    </div>

                </nav>
            </header>


        </>
    )
}

export default Topbar;