// import {fetchError, fetchStart, fetchSuccess} from "../actions";

import { env_vars } from 'Utils/config';
import axios from 'axios';
import { SET_STREAM } from './types';
let { stream_server_url  } = env_vars;




export const streamLogin = (data) => async dispatch => {
  return await axios
    .post(env_vars.api_url + `/v1/stream`, data)
    .then((response) => {
      return response.data;
    }).catch(err => {
      return null
    });
};


export const getStreamData = (data) => async dispatch => {
  return await axios
    .get(env_vars.api_url + `/v1/stream`)
    .then((response) => {
      dispatch({type: SET_STREAM, payload: response.data})
      return response.data;
    }).catch(err => {
      return null
    });
};


export const postStreamAction = (action) => async dispatch => {
  console.log(action, 'ACTION')
  return await axios
    .post(env_vars.api_url + `/v1/stream/action`, {action: action})
    .then((response) => {
      dispatch(getStreamData())
      return response.data;
    }).catch(err => {
      return null
    });
};

export const getUserNetWork = (props) => dispatch => {

    return axios.get(`${stream_server_url}/api`)
  };

