import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal,  ModalBody, ModalFooter, Button } from 'reactstrap';
import PatientDataModal from './PatientDataModal';
import { getFailSafeDetails_1, getFailSafeDetails_2, getFailSafeDetails_3, getFailSafeDetails_4, getFailSafeDetails_5, getFailSafeDetails_6 } from 'redux/actions/data.action'; // Adjust import based on your actions

const FailsafePrintModal = ({ isOpen, toggle }) => {
  const [selectedItems, setSelectedItems] = useState([]); // State to store selected fail-safe items
  const [patientData, setPatientData] = useState({}); // State to store patient data for selected items
  const [showPatientDataModal, setShowPatientDataModal] = useState(false); // State to control the display of PatientDataModal
  const [isLoading, setIsLoading] = useState(false); // State to track loading state while fetching data
  const failsafeItems = [
    'BIOPSY',
    'SMEAR',
    'SWAB',
    'TREATMENT',
    'GA TREATMENT',
    'DNA'
  ]; // Array of fail-safe items
  const dispatch = useDispatch(); // Redux dispatch function

  // Function to handle selection of fail-safe items
  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
      setPatientData(prevData => {
        const { [item]: omit, ...newPatientData } = prevData;
        return newPatientData;
      });
    } else {
      setSelectedItems([...selectedItems, item]);
      fetchDataForItem(item);
    }
  };

  // Function to handle checkbox change for fail-safe items
  const handleCheckboxChange = (item) => {
    handleSelectItem(item);
  };

  // Function to close the modal and reset states
  const handleClose = () => {
    setSelectedItems([]); // Reset selected items
    setPatientData({}); // Clear patient data
    setShowPatientDataModal(false); // Hide PatientDataModal
    toggle(); // Close the modal
  };

  // Function to handle the OK button click
  const handleItems = async () => {
    if (selectedItems.length === 0) {
      // Show error message or handle it according to your requirement
      return;
    }

    setShowPatientDataModal(true); // Show PatientDataModal
  };

  // Function to fetch data for a selected fail-safe item
  const fetchDataForItem = async (item) => {
    try {
      setIsLoading(true); // Set loading state to true
      let data;
      switch (item) {
        case 'BIOPSY':
          data = await dispatch(getFailSafeDetails_1());
          break;
        case 'SMEAR':
          data = await dispatch(getFailSafeDetails_2());
          break;
        case 'SWAB':
          data = await dispatch(getFailSafeDetails_3());
          break;
        case 'TREATMENT':
          data = await dispatch(getFailSafeDetails_4());
          break;
        case 'GA TREATMENT':
          data = await dispatch(getFailSafeDetails_5());
          break;
        case 'DNA':
          data = await dispatch(getFailSafeDetails_6());
          break;
        default:
          break;
      }
      setPatientData(prevData => ({ ...prevData, [item]: data })); // Update patientData state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching data
    }
  };

  return (
    <>
      {showPatientDataModal ? ( // Render PatientDataModal if showPatientDataModal is true
        <PatientDataModal
          isOpen={showPatientDataModal}
          toggle={() => setShowPatientDataModal(false)}
          patientData={patientData}
        />
      ) : (
        <Modal isOpen={isOpen} toggle={toggle} centered> {/* Render fail-safe item selection modal otherwise */}
          <div className="card-header bg-iri w-100">
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}
            >
              <h4 className="text-white">Which FailSafe List you want to Print?</h4>
            </div>
          </div>
          <ModalBody className="d-flex flex-column">
            {failsafeItems.map((item, index) => (
              <div key={index} className="checkbox-container">
                <input
                  type="checkbox"
                  id={`failsafe-${index}`}
                  checked={selectedItems.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                />
                <label htmlFor={`failsafe-${index}`} style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>{item}</label>

              </div>
            ))}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button className="btn btnIri marRight20" onClick={handleClose}>Close</Button>
            <Button
              className={`btn ${selectedItems.length > 0 ? 'btnIri' : ''} marRight20`}
              onClick={handleItems}
              style={{
                backgroundColor: selectedItems.length > 0 ? '#c46977' : '',
                color: selectedItems.length > 0 ? 'white' : '',
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default FailsafePrintModal;
